import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Content from 'styled-components/Content';
import Image from 'components/Image';

import { black, darkBlue, white } from 'styles/colors';
import Body from 'styled-components/Body';
import { ReactElement } from 'react';

const StackWrapper = styled.div`
  height: calc(100vh - 72px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Stack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  text-align: center;
`;

const ButtonWrapper = styled(Link)`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  text-align: center;

  a {
    margin-top: 12px;
  }
`;

const H1Text = styled.h1`
  margin-top: 24px;
  color: ${black};
  margin-bottom: 16px;
`;

const Button = styled.button`
  background: ${darkBlue};
  border: none;
  border-radius: 5px;
  padding: 12px 40px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */

  text-align: center;

  color: ${white};
  width: auto;
  cursor: pointer;
`;

const ImageWrapper = styled.div`
  margin-top: 80px;
  width: 222px;
  height: 222px;

  img {
    width: 100%;
    height: auto;
  }
`;

function LandingPage(): ReactElement {
  return (
    <Content backgroundColor={white}>
      <StackWrapper>
        <Stack>
          <Image path={'/logos/home_post_logo_dark'} />
          <ImageWrapper>
            <Image path={'/assets/people_animation'} />
          </ImageWrapper>
          <H1Text>{'Your social calendar'}</H1Text>
          <Body>{'Spend more time doing the things you like with people you like, in real life.'}</Body>
          <ButtonWrapper to={'/login'}>
            <Button>{'Sign Up or Log In'}</Button>
          </ButtonWrapper>
        </Stack>
      </StackWrapper>
    </Content>
  );
}

export default LandingPage;
