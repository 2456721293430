import { useState } from 'react';
import styled from 'styled-components';
import { GoogleMap, Marker } from '@react-google-maps/api';

import Avatar from 'components/Avatar';
import BackButton from 'components/BackButton';
import Content from 'styled-components/Content';
import Header from 'styled-components/Header';
import HeaderTitle from 'styled-components/HeaderTitle';
import BubbleSelectInput from 'components/BubbleSelectInput';

import { CurrentUser, useAdminDashboardQuery, useAdminDeleteUserMutation } from 'generated';
import SimplePopover from 'components/SimplePopover';
import { black, darkRed, gray, white } from 'styles/colors';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Card = styled.div`
  width: 100%;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.05);
  padding: 16px;
  margin-bottom: 8px;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 16px;
`;

const Line = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  margin-bottom: 8px;
`;

const Measure = styled.div``;

const Count = styled.div``;

const center = {
  lat: 32.742338,
  lng: -117.193345,
};

const List = styled.ul`
  list-style: none;
`;

const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid ${gray};
  background: ${white};
`;

const UserContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  color: ${black};
  margin-left: 8px;
  width: calc(100% - 72px);
  overflow: hidden;
`;

const UserName = styled.div``;

const UserStatus = styled.div`
  height: 40px;
  color: #798389;
`;

const DeleteButton = styled.button`
  text-decoration: none;
  color: #b0243c;
  background: transparent;
  border: 0;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  cursor: pointer;
`;

function AdminDashboard() {
  const [view, setView] = useState<string>('overall');
  const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null);
  const [isCurrentUserPopoverOpen, setIsCurrentUserPopoverOpen] = useState<boolean>(false);
  const [userIdToDelete, setUserIdToDelete] = useState<string>('');
  const [isDeleteConfirmationPopoverOpen, setIsDeleteConfirmationPopoverOpen] = useState<boolean>(false);

  const { loading, error, data } = useAdminDashboardQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [adminDeleteUser] = useAdminDeleteUserMutation({
    onCompleted: () => {
      setIsDeleteConfirmationPopoverOpen(false);
      setUserIdToDelete('');
    },
    refetchQueries: ['AdminDashboard'],
    variables: {
      input: {
        userId: userIdToDelete,
      },
    },
  });

  if (loading) return null;

  if (error || !data?.adminDashboard?.adminDashboard?.counts) return <p>Error :(</p>;

  const { counts, users = [] } = data.adminDashboard.adminDashboard;

  const viewOptions = [
    {
      text: 'Overall',
      value: 'overall',
    },
    {
      text: 'User Map',
      value: 'userMap',
    },
    {
      text: 'Users',
      value: 'users',
    },
  ];

  const showBigMap = window.innerWidth > 767;

  const handleCursorClick = (user: any) => {
    setCurrentUser(user);
    setIsCurrentUserPopoverOpen(true);
  };

  const handleDeleteIntentClick = (userId: string) => {
    setUserIdToDelete(userId);
    setIsDeleteConfirmationPopoverOpen(true);
  };

  const handleConfirmationPopoverRequestClose = () => {
    setIsDeleteConfirmationPopoverOpen(false);
  };

  const handleDeleteClick = () => {
    adminDeleteUser();
  };

  return (
    <Container>
      <Header>
        <BackButton />
        <HeaderTitle>{'Admin Dashboard'}</HeaderTitle>
      </Header>
      <Content fullHeight padding={16}>
        <BubbleSelectInput options={viewOptions} mode={'single'} onChange={(value: string) => setView(value)} value={view} />
        {view === 'overall' && (
          <CardContainer>
            <Card>
              <Title>{'Last 7 Days'}</Title>
              <Line>
                <Measure>{'New Users'}</Measure>
                <Count>{counts?.lastSeven?.userCount}</Count>
              </Line>
              <Line>
                <Measure>{'New Events'}</Measure>
                <Count>{counts?.lastSeven?.eventCount}</Count>
              </Line>
              <Line>
                <Measure>{'New Event Views'}</Measure>
                <Count>{counts?.lastSeven?.eventViewCount}</Count>
              </Line>
              <Line>
                <Measure>{'New Posts'}</Measure>
                <Count>{counts?.lastSeven?.postCount}</Count>
              </Line>
              <Line>
                <Measure>{'New Post Views'}</Measure>
                <Count>{counts?.lastSeven?.postViewCount}</Count>
              </Line>
              <Line>
                <Measure>{'Blocked Users'}</Measure>
                <Count>{counts?.lastSeven?.blockCount}</Count>
              </Line>
            </Card>

            <Card>
              <Title>{'Last 14 Days'}</Title>
              <Line>
                <Measure>{'New Users'}</Measure>
                <Count>{counts?.lastFourteen?.userCount}</Count>
              </Line>
              <Line>
                <Measure>{'New Events'}</Measure>
                <Count>{counts?.lastFourteen?.eventCount}</Count>
              </Line>
              <Line>
                <Measure>{'New Event Views'}</Measure>
                <Count>{counts?.lastFourteen?.eventViewCount}</Count>
              </Line>
              <Line>
                <Measure>{'New Posts'}</Measure>
                <Count>{counts?.lastFourteen?.postCount}</Count>
              </Line>
              <Line>
                <Measure>{'New Post Views'}</Measure>
                <Count>{counts?.lastFourteen?.postViewCount}</Count>
              </Line>
              <Line>
                <Measure>{'Blocked Users'}</Measure>
                <Count>{counts?.lastFourteen?.blockCount}</Count>
              </Line>
            </Card>

            <Card>
              <Title>{'Last 28 Days'}</Title>
              <Line>
                <Measure>{'New Users'}</Measure>
                <Count>{counts?.lastTwentyEight?.userCount}</Count>
              </Line>
              <Line>
                <Measure>{'New Events'}</Measure>
                <Count>{counts?.lastTwentyEight?.eventCount}</Count>
              </Line>
              <Line>
                <Measure>{'New Event Views'}</Measure>
                <Count>{counts?.lastTwentyEight?.eventViewCount}</Count>
              </Line>
              <Line>
                <Measure>{'New Posts'}</Measure>
                <Count>{counts?.lastTwentyEight?.postCount}</Count>
              </Line>
              <Line>
                <Measure>{'New Post Views'}</Measure>
                <Count>{counts?.lastTwentyEight?.postViewCount}</Count>
              </Line>
              <Line>
                <Measure>{'Blocked Users'}</Measure>
                <Count>{counts?.lastTwentyEight?.blockCount}</Count>
              </Line>
            </Card>

            <Card>
              <Title>{'Total'}</Title>
              <Line>
                <Measure>{'New Users'}</Measure>
                <Count>{counts?.total?.userCount}</Count>
              </Line>
              <Line>
                <Measure>{'New Events'}</Measure>
                <Count>{counts?.total?.eventCount}</Count>
              </Line>
              <Line>
                <Measure>{'New Event Views'}</Measure>
                <Count>{counts?.total?.eventViewCount}</Count>
              </Line>
              <Line>
                <Measure>{'New Posts'}</Measure>
                <Count>{counts?.total?.postCount}</Count>
              </Line>
              <Line>
                <Measure>{'New Post Views'}</Measure>
                <Count>{counts?.total?.postViewCount}</Count>
              </Line>
              <Line>
                <Measure>{'Blocked Users'}</Measure>
                <Count>{counts?.total?.blockCount}</Count>
              </Line>
            </Card>
          </CardContainer>
        )}

        {view === 'userMap' && (
          <GoogleMap
            center={center}
            mapContainerStyle={{
              width: showBigMap ? '40vw' : '100vw',
              height: showBigMap ? '40vw' : '100vw',
              margin: showBigMap ? '16px auto 0 auto' : '0 auto',
            }}
            options={{
              disableDefaultUI: true,
            }}
            zoom={12}
          >
            {users?.map((user) => (
              <Marker
                key={user?._id}
                onClick={() => handleCursorClick(user)}
                position={{
                  lat: user.location.coordinates[1],
                  lng: user.location.coordinates[0],
                }}
              />
            ))}
          </GoogleMap>
        )}

        {view === 'users' && (
          <List>
            {users?.map((user) => (
              <ListItem key={user?._id}>
                <Avatar path={user?.profileImage?.path} size={40} text={user?.firstName?.slice(0, 1)} />
                <UserContent>
                  <UserName>{`${user?.firstName} ${user?.lastName}`}</UserName>
                </UserContent>
                <UserStatus>
                  <DeleteButton onClick={() => handleDeleteIntentClick(user._id)}>{'Delete'}</DeleteButton>
                </UserStatus>
              </ListItem>
            ))}
          </List>
        )}
      </Content>

      <SimplePopover
        bodyText={`Joined: ${currentUser?.createdAt}`}
        buttonText={'Close'}
        contentLabel={`${currentUser?.firstName} ${currentUser?.lastName}`}
        headerText={`${currentUser?.firstName} ${currentUser?.lastName}`}
        isOpen={isCurrentUserPopoverOpen}
        onButtonClick={() => setIsCurrentUserPopoverOpen(false)}
        onRequestClose={() => setIsCurrentUserPopoverOpen(false)}
      />

      <SimplePopover
        bodyText={'This user, all of their posts, connections and related data will be removed. This action cannot be undone.'}
        buttonBackgroundColor={darkRed}
        buttonText={'Delete it'}
        contentLabel={'Delete this user?'}
        emoji={'🗑'}
        headerText={'Delete this user?'}
        isOpen={isDeleteConfirmationPopoverOpen}
        onButtonClick={handleDeleteClick}
        onRequestClose={handleConfirmationPopoverRequestClose}
      />
    </Container>
  );
}

export default AdminDashboard;
