import styled from 'styled-components';
import { white } from 'styles/colors';
import Avatar from 'components/Avatar';
import { ReactElement } from 'react';
import { PublicUser } from 'generated';

interface MultiAvatarProps {
  maxCount?: number;
  users?: PublicUser[];
}

interface AvatarWrapperProps {
  index: number;
  maxCount: number;
}

const AvatarWrapper = styled.div<AvatarWrapperProps>`
  > div {
    transform: translate(${(props) => (props.maxCount - props.index - 1) * 14}px, 0);
    border: 2px solid ${white};
    z-index: ${(props) => props.index * 10 + 1};
    position: relative;
  }
`;

const AvatarsWrapper = styled.div`
  display: flex;
`;

function MultiAvatar({ maxCount = 3, users = [] }: MultiAvatarProps): ReactElement {
  return (
    <AvatarsWrapper>
      {users.slice(0, 3).map((user, index) => (
        <AvatarWrapper maxCount={maxCount} index={index} key={user?._id}>
          <Avatar size={40} path={user?.profileImage?.path} text={user?.firstName || ''} />
        </AvatarWrapper>
      ))}
      {users.length > 3 && (
        <AvatarWrapper maxCount={maxCount} index={3} key={'count'}>
          <Avatar size={40} longText={`+${(users.length - 3).toString()}`} />
        </AvatarWrapper>
      )}
    </AvatarsWrapper>
  );
}

export default MultiAvatar;
