import styled from 'styled-components';
import { black, gray, lightBlue, white } from 'styles/colors';

export type BubbleSelectInputOption = {
  icon?: React.ReactNode;
  text: string;
  value: string;
};

export type BubbleSelectInputProps =
  | {
      onChange: (value: string) => void;
      options: BubbleSelectInputOption[];
      mode: 'single';
      value: string;
    }
  | {
      onChange: (value: string[]) => void;
      options: BubbleSelectInputOption[];
      mode: 'multi';
      value: string[];
    };

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;

export type BubbleProps = {
  active?: boolean;
};

const Bubble = styled.button<BubbleProps>`
  background: ${(props) => (props?.active ? lightBlue : gray)};
  color: ${(props) => (props?.active ? white : black)};

  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  padding: 8px 12px;
  border: 0;
  outline: 0;
  border-radius: 20px;
  margin-right: 8px;
  margin-bottom: 8px;
  cursor: pointer;
`;

function calculateActive(option: BubbleSelectInputOption, value: string | string[]) {
  if (typeof value === 'string') {
    return option.value === value;
  }

  if (typeof value === 'object') {
    return value.includes(option.value);
  }

  return false;
}

function BubbleSelectInput({ onChange, options, mode, value }: BubbleSelectInputProps) {
  const handleBubbleClick = (option: BubbleSelectInputOption) => {
    if (mode === 'single') {
      if (value === option.value) {
        onChange('');
      } else {
        onChange(option.value);
      }
    }

    if (mode === 'multi') {
      if (value?.includes(option.value)) {
        onChange(value.filter((val) => val !== option.value));
      } else if (value?.length) {
        onChange([...value, option.value]);
      } else {
        onChange([option.value]);
      }
    }
  };

  return (
    <Container>
      {options.map((option) => (
        <Bubble key={option.value} active={calculateActive(option, value)} onClick={() => handleBubbleClick(option)} type={'button'}>
          {option.text}
        </Bubble>
      ))}
    </Container>
  );
}

export default BubbleSelectInput;
