import Image from 'components/Image';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Content from 'styled-components/Content';
import Header from 'styled-components/Header';

const Text = styled.div`
  padding: 16px;

  * {
    margin-bottom: 16px;
  }
`;

function EULA(): ReactElement {
  return (
    <>
      <Header>
        <div />
        <Link style={{ lineHeight: 0 }} to={'/'}>
          <Image path={'/logos/home_post_logo_dark'} />
        </Link>
        <div />
      </Header>
      <Content>
        <Text>
          <h2>{'EULA'}</h2>
          <p>{'Last updated: February 12, 2024'}</p>

          <p>{'User-Generated Content License Agreement'}</p>

          <p>{'Acceptance of Terms'}</p>

          <p>{'1. General provisions'}</p>
          <p>
            {
              'This User-Generated Content License Agreement (“Agreement”) is by and between Homepost, LTD (“Homepost”, “we”, “our”, or “us”) and you or the organization you represent (“you”, “your” or “USER”).'
            }
          </p>
          <p>
            {
              'These terms and conditions of the Agreement apply to our use of any words, pictures, images, data, video, information or any other ‘user-generated content’ (the “User Generated Content” or “UGC”) that you upload, post, or share via Homepost’s website, mobile apps, social media pages, or other related services (the “Services”) or via third-party websites and platforms including but not limited to Instagram, Facebook, Pinterest, YouTube, Snapchat, and Twitter (the “Third Party Platforms”) and which we use on our website, platforms, apps, in other marketing channels or in any Homepost owned social media platforms (“Homepost Media”). Homepost reaches out to USERs of Services and Third Party Platforms to seek their permission to feature our favorite UGC on Third Party Platforms or Homepost Media. You are reading this because Homepost has requested your permission to use your UGC. It is not necessary to purchase anything from Homepost in order to contribute your UGC to Homepost Media. If you choose to allow us to use your UGC by providing us with the consent by various ways described in this Agreement, you shall be bound by this Agreement, Homepost’s Privacy Policy, as well as the terms of use of any relevant Third Party Platform (if applicable). If you do not agree to the terms of this Agreement, do not provide us with the consent to use your UGC. If you are executing this Agreement on behalf of an organization, you represent that you have the authority to do so.'
            }
          </p>

          <p>{'2. Ways of providing consent (permission) to our use of your UGC'}</p>

          <p>
            {
              'We will contact you on the relevant Third Party Platform, our Homepost platform, or another platform, which provides Services, to ask for your explicit consent to use your Content. We will ask you to reply to us whether you are happy that we will use your UGC. You could provide us with your explicit consent in any form of words or symbols (emoji), which confirms you agree to allow us to use your UGC in Homepost Media, for example, “yes”, “OK”, “Agree” and other similar words in any language, including emoji. You must respond directly to us within the same chat, thread or by private message in order to release the agreed UGC to us. If we do not receive your response within 7 days from the date we contacted you, we will assume that you do not consent to our use of your UGC and your UGC will not be used by us in any Homepost Media.'
            }
          </p>

          <p>
            {
              '3. By consenting to our use of your UGC, you agree to provide Homepost with the license on the following terms and conditions'
            }
          </p>

          <p>
            {
              '3.1. Licensed Parties. USERs retain ownership of USER’s UGC (but excluding any ownership related to Services). At the same time, USERs agree to grant a non-exclusive, worldwide license (as detailed below) to Homepost and its executives, employees, related companies, agents, licensees, sublicensees, contractors, successors, legal representatives, assigns, third-party service providers and their respective retail partners, marketing or public relations agencies and other affiliates, and all other users of the Services (the “Licensed Parties”).'
            }
          </p>

          <p>
            {
              '3.2. License of UGC. Once USER allows us to use USER’s User Generated Content by providing us with the consent, USER irrevocably grants to the Licensed Parties the non-exclusive, assignable, sub-licensable, worldwide, perpetual, royalty-free, no-cost license and right to use and otherwise exploit USER’s User Generated Content, in whole or in part, and all USER social profile information, code, content, content descriptions, and other materials depicted therein, in whole or in part, in any manner or medium now or hereafter known or devised, including on the Internet, in any commercial manner, and in advertising and promotion of Homepost and its products and services, throughout the world and in any and all languages without the requirement to make payment to you or to any third party or the need to seek any third party permission. The Licensed Parties will have the right to make unlimited derivative works therefrom, to assign or transfer any or all such rights and to grant unlimited, multiple-level sublicenses with no obligations to you, whether financial, credit, approval of changes or otherwise. USER hereby forever waives and relinquishes all so-called “moral rights” now or hereafter recognized (for example, to be named as the author or to protect the integrity of your UGC.). The Licensed Parties shall have and retain all right, title, and interest in and to the data resulting from such processing, including without limitation, encoding, compressing, formatting, and processing of audio and video data.'
            }
          </p>

          <p>
            {
              '3.3. The License of Name and Likeness. USER also irrevocably grants the Licensed Parties the perpetual, royalty-free, and worldwide right to use their name, voice, likeness, image, photograph, biographical material, logos, marks or trade names, or other information provided or obtained (“Name and Likeness”), including without limitation, the right to publicly display, publicly perform, distribute, and reproduce the UGC, name, voice, likeness, social profile information and biographical material, in connection with the Services, the User Generated Content, in derivative works thereof, and in any advertising, publicity and exploitation material for purposes of advertising or promoting Homepost, its products and/or services.'
            }
          </p>

          <p>
            {
              '3.4. No fees or obligations to return. USER agrees that no money will be owing to any party as a result of the posting of the UGC or its use as contemplated by the Services and this Agreement or for any use, distribution, or other exploitation of the UGC or any rights therein, whether as part of the Services or otherwise. USER also agrees that the Licensed Parties shall not be responsible for the return or preservation of UGC submitted or any elements thereof. This permission is given to the Licensed Parties with the knowledge that it will be relied thereon at substantial cost to the Licensed Parties, and USER agrees not to assert any claim against the Licensed Parties of any nature whatsoever arising by reason of its use of such UGC.'
            }
          </p>

          <p>
            {
              '3.5. Homepost’s lack of Obligations. We reserve the right, but have no obligation, to take any legally available action that we deem appropriate, in our sole discretion, including, without limitation, editing, removing, deleting, taking down or otherwise erasing your UGC at any time without notice for any reason whatsoever.'
            }
          </p>

          <p>
            {
              '3.6. Intellectual Property. You agree that all goodwill that arises in connection with your use of Homepost’s trademarks inures exclusively to Homepost and agree not to challenge Homepost’s ownership or control of any Homepost trademarks, nor use or adopt any trademarks that might be confusingly similar to such Homepost trademarks. You acknowledge and agree that you do not acquire any ownership or other rights in property information and materials of Homepost by authorizing the use of your UGC or otherwise using or accessing the Services. The Services, their parts may be protected by copyright, trademark, and other intellectual property laws. For purposes of certainty, USER may not make any commercial or any other use of the Services.'
            }
          </p>

          <p>
            {
              '3.7. Non-Confidentiality of UGC. You agree that your UGC will be treated as non-confidential – regardless of whether you mark them “confidential,” “proprietary,” or the like – and will not be returned, and the Licensed Parties have no obligation to maintain the confidentiality of any information, in whatever form, contained in any submission, except pursuant to the Licensed Parties’ respective privacy policies.'
            }
          </p>

          <p>{'4. Representations, warranties, and indemnity'}</p>

          <p>
            {
              '4.1. READ AND AGREE. BY PROVIDING CONSENT TO USE YOUR UGC TO Homepost, YOU REPRESENT AND WARRANT THAT YOU HAVE READ THIS AGREEMENT IN ITS ENTIRETY, UNDERSTAND ALL THE TERMS AND CONDITIONS OF THIS AGREEMENT, AND THAT YOU AGREE TO COMPLY WITH ALL THE TERMS AND CONDITIONS OF THIS AGREEMENT. OTHERWISE, YOU AGREE NOT TO PROVIDE THE CONSENT TO USE YOUR UGC TO Homepost.'
            }
          </p>

          <p>
            {
              '4.2. Age of Majority and Authority. By providing your UGC to Homepost, you represent and warrant that you are at least the age of majority in your country of residence (which is eighteen (18) in most countries) and you have the right to execute and fully perform this Agreement.'
            }
          </p>

          <p>
            {
              '4.3. No Prohibited UGC Content. By providing your UGC to Homepost, you represent and warrant that the UGC does not contain any Prohibited UGC content that:'
            }
          </p>

          <p>{'4.3.1. is known by you to be false, inaccurate or misleading;'}</p>

          <p>
            {
              '4.3.2. infringes any third party’s copyright, patent, trademark, trade secret, or other proprietary rights or rights of publicity or privacy;'
            }
          </p>

          <p>
            {
              '4.3.3. violates any law, statute, ordinance or regulation (including, but not limited to, those governing export control, consumer protection, unfair competition, anti-discrimination or false advertising);'
            }
          </p>

          <p>
            {
              '4.3.4. is, or may reasonably be considered to be abusive, illegal, defamatory, libelous, indecent, hateful, racially or religiously biased or offensive, slanderous, indecent, obscene, pornographic, sexually explicit, unlawfully threatening or unlawfully harassing to any individual, partnership or corporation in any way; or that encourages anyone to break any local, state, national or international law;'
            }
          </p>

          <p>{'4.3.5. is or contains advertising, promotional material or promotes a product, service or other commercial activity;'}</p>

          <p>
            {
              '4.3.6. that impersonates or attempts to impersonate Homepost or any of its employees, another user, or person or entity (including, without limitation, the use of email addresses associated with any of the foregoing);'
            }
          </p>

          <p>
            {
              '4.3.7. that includes any information that references other websites or personally identifiable information (e.g., first and last name, addresses, email addresses, contact information or phone numbers);'
            }
          </p>

          <p>
            {
              '4.3.8. that contains any computer viruses, worms, Trojan horses, spyware or other potentially damaging technologies, computer programs or files or malicious code that could impact the operation of our Services or any computer or other device;'
            }
          </p>

          <p>
            {
              '4.3.9. that contains any persons who have not consented or are unable to consent (whether due to being below the age of majority or otherwise incapable) to the Licensed Parties’ use of their Name and Likeness under clause above of this Agreement.'
            }
          </p>

          <p>
            {
              '4.4. Name and Likeness of Others. By providing your UGC to Homepost, you represent and warrant that you have obtained permission for you to use and for the Licensed Parties to use the personal data, Name and Likeness of any individuals contained in the UGC, in the same manner as to which you agreed under this Agreement. USER represents and warrants that USER has all the necessary rights, licenses, clearances and consents needed from third parties in order for you to submit UGC (including but not limited to all copyright and right of publicity and privacy rights) for USER to comply with the terms of this Agreement (for example, if someone has taken a video of you and your friend, and you submit that video to Homepost as your UGC, then you must obtain your friend’s and the photographer’s permission to do so). We may require, at any time, proof of the permissions referred to above in a form acceptable to us. If you ever have reason to believe that you have not maintained any of these rights or obligations, you must provide immediate notice to Homepost and contact us.'
            }
          </p>

          <p>
            {
              '4.5. Original Work. USER hereby represents and warrants that his or her contribution to UGC are wholly original with USER and are not a copy or imitation of any other material; will not infringe or violate any right whatsoever, including, without limitation, any personal rights (e.g., defamation, publicity, privacy, false light, moral right, etc.) or any property rights (e.g., copyright, trademark, right to ideas, etc.) of any person or entity; and are not the subject of any threatened or pending litigation, claim or dispute that might give rise to litigation, which adversely affects or in any way prejudices, impairs or diminishes the rights granted hereunder or the value thereof.'
            }
          </p>

          <p>
            {
              '4.6. Indemnity. USER agrees to indemnify, hold harmless, and defend the Licensed Parties from any claim or liability related in any way to this Agreement, your use of the Services, the Licensed Parties’ use of your UGC, or relating to any breach of any representation, warranty or covenant made by USER in this Agreement.'
            }
          </p>

          <p>
            {
              '4.7. Liability. To the extent permitted by law, Homepost accepts no liability for any damage, loss or injury suffered due to this Agreement.'
            }
          </p>

          <p>{'5. Miscellaneous'}</p>

          <p>
            {
              '5.1. Terms of Service and Privacy Policy. By using the Services, you acknowledge and accept Homepost’s Privacy Policy and consent to the collection and use of your data in accordance with the Privacy Policy and this Agreement.'
            }
          </p>

          <p>
            {
              '5.2. Right to Modify. Homepost reserves the right to modify this Agreement at any time without prior notice (“Updated Agreement”). You agree that we may notify you of the Updated Agreement by posting it on the Services so that it is accessible via a link on the Services, and that your use of the Services after we post the Updated Agreement (or engaging in such other conduct as we may reasonably specify) constitutes your agreement to the Updated Agreement. Therefore, you should review this Agreement on a regular and frequent basis. The Updated Agreement will be effective as of the time that Homepost posts it on the Services or such later date as may be specified in it.'
            }
          </p>

          <p>{'5.3. Notice. You must send any notice related to this agreement to Homepost by hi@homepost.com.'}</p>

          <p>
            {
              '5.4. Governing law. Any dispute or claim arising out of or in connection with these terms or their subject matter or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with the law of England and Wales and the courts of Hong Kong shall have exclusive jurisdiction to settle any dispute or claim arising out of or in connection with these terms or their subject matter or formation (including non-contractual disputes or claims).'
            }
          </p>
        </Text>
      </Content>
    </>
  );
}

export default EULA;
