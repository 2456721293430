import { FormEvent, ReactElement, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';

import { CurrentUserContext } from 'App';

import { useConnectedUsersByCurrentUserQuery, useCreateGroupMutation } from 'generated';

import Content from 'styled-components/Content';
import Form from 'styled-components/Form';
import Header from 'styled-components/Header';
import InputWrapper from 'styled-components/InputWrapper';
import PrimaryHeaderButton from 'styled-components/PrimaryHeaderButton';
import TextButton from 'styled-components/TextButton';

import track from 'services/track';
import { black, darkGray, darkRed, gray, offWhite, white } from 'styles/colors';
import HeaderTitle from 'styled-components/HeaderTitle';
import useTrackOnMount from 'components/useTrackOnMount';
import Avatar from 'components/Avatar';
import Body from 'styled-components/Body';

const FeaturedImageWrapper = styled.div`
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 8px;

  img {
    width: 100%;
  }
`;

const Select = styled.select`
  width: 100%;
  padding-bottom: 4px;
  font-size: 16px;
  line-height: 16px;
`;

const List = styled.ul`
  list-style: none;
  width: 100%;
  margin-bottom: 16px;
`;

const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid ${gray};
  background: ${white};
`;

const UserContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  color: ${black};
  margin-left: 8px;
  width: calc(100% - 72px);
  overflow: hidden;
`;

const UserName = styled.div``;

function NewGroup(): ReactElement {
  const navigate = useNavigate();
  const currentUser = useContext(CurrentUserContext);

  const [error, setError] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [selectedConnectedUserIds, setSelectedConnectedUserIds] = useState<string[]>([]);

  useTrackOnMount('New Group Form Viewed', {
    days_on_platform: -moment(currentUser?.createdAt).diff(new Date(), 'days'),
  });

  const canCreate = !!name;

  const { data: connectedUsersData } = useConnectedUsersByCurrentUserQuery();

  const [createGroup] = useCreateGroupMutation({
    onCompleted: (data) => {
      if (data.createGroup.error) {
        setError(data.createGroup.error);
        track('Group Created', {});
      } else {
        navigate('/groups');
      }
    },
    variables: {
      input: {
        invitedUserIds: selectedConnectedUserIds,
        name,
      },
    },
  });

  const handleCreateGroup = (): void => {
    createGroup().catch((err) => console.log(err));
  };

  const handleCreateClick = (): void => {
    handleCreateGroup();
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
  };

  if (!currentUser) {
    return <div>{'You must be logged in to create a new group.'}</div>;
  }

  if (error) {
    return <div>{`Sorry, we had trouble creating this post. Error: ${error}`}</div>;
  }

  const handleCancelButtonClick = (): void => {
    navigate(-1);
  };

  const connectedUsers = connectedUsersData?.connectedUsersByCurrentUser || [];

  const handleConnectedUserToggle = (userId: string): void => {
    if (selectedConnectedUserIds.includes(userId)) {
      setSelectedConnectedUserIds(selectedConnectedUserIds.filter((u) => u !== userId));
    } else {
      setSelectedConnectedUserIds([...selectedConnectedUserIds, userId]);
    }
  };

  return (
    <>
      <Header>
        <TextButton color={darkRed} onClick={handleCancelButtonClick}>
          {'Cancel'}
        </TextButton>
        <HeaderTitle>{'Create Group'}</HeaderTitle>
        <PrimaryHeaderButton disabled={!canCreate} onClick={handleCreateClick}>
          {'Create'}
        </PrimaryHeaderButton>
      </Header>
      <Content padding={16} backgroundColor={offWhite}>
        <Form onSubmit={handleSubmit} autoComplete={'off'}>
          <h2 style={{ marginBottom: 8 }}>{'Group information'}</h2>
          <InputWrapper>
            <label htmlFor={'name'}>{'Name'}</label>
            <input
              autoFocus
              id={'name'}
              type={'text'}
              onChange={(e) => setName(e.currentTarget.value)}
              defaultValue={name}
              placeholder={'Add group name'}
            />
          </InputWrapper>
          <Body small color={darkGray}>
            {'Groups are visible to anyone with the link, friends decide to join or leave the group.'}
          </Body>

          <h2 style={{ marginBottom: 8 }}>{'Invitations'}</h2>
          <Body small color={darkGray}>
            {'Select friends to send them an invitation when you create this group.'}
          </Body>
          <List>
            {connectedUsers.map((user) => (
              <ListItem key={user?._id}>
                <Avatar path={user?.profileImage?.path} size={40} text={user?.firstName?.slice(0, 1)} />
                <UserContent>
                  <UserName>{user?.firstName}</UserName>
                </UserContent>
                <input
                  checked={selectedConnectedUserIds.includes(user._id)}
                  type={'checkbox'}
                  onChange={() => handleConnectedUserToggle(user._id)}
                />
              </ListItem>
            ))}
          </List>
        </Form>
      </Content>
    </>
  );
}

export default NewGroup;
