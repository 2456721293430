import styled from 'styled-components';
import BackButton from 'components/BackButton';
import { useDeleteUserMutation } from 'generated';
import { ReactElement, useContext, useState } from 'react';
import Content from 'styled-components/Content';
import Header from 'styled-components/Header';
import HeaderTitle from 'styled-components/HeaderTitle';
import Paragraph from 'styled-components/Paragraph';
import { darkRed, offWhite } from 'styles/colors';
import SimplePopover from 'components/SimplePopover';
import { CurrentUserContext } from 'App';
import { Link } from 'react-router-dom';

const DeleteButton = styled.button`
  text-decoration: none;
  color: #b0243c;
  background: transparent;
  border: 0;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  cursor: pointer;
`;

function DeleteAccount(): ReactElement {
  const currentUser = useContext(CurrentUserContext);

  const [isDeleteConfirmationPopoverOpen, setIsDeleteConfirmationPopoverOpen] = useState<boolean>(false);
  const [deleteAccount] = useDeleteUserMutation();

  const handleDeleteClick = (): void => {
    if (currentUser?._id) {
      deleteAccount({
        onCompleted: (data) => {
          if (data.deleteUser.success) {
            setIsDeleteConfirmationPopoverOpen(false);
          }
        },
        refetchQueries: ['CurrentUser'],
        update: () => {
          localStorage.setItem('auth_token', '');
        },
        variables: {
          input: {
            userId: currentUser._id,
          },
        },
      }).catch((err) => {
        console.log(err.toString());
      });
    }
  };

  const handleConfirmationPopoverRequestClose = (): void => {
    setIsDeleteConfirmationPopoverOpen(false);
  };

  const handleDeleteButtonClick = (): void => {
    setIsDeleteConfirmationPopoverOpen(true);
  };

  return (
    <>
      <Header>
        {!!currentUser && <BackButton />}
        <HeaderTitle>{'Delete Account'}</HeaderTitle>
      </Header>
      <Content padding={16} backgroundColor={offWhite}>
        {!!currentUser && (
          <>
            <Paragraph>
              {'Clicking the "Delete My Account" button below will remove all of your content and information from the Homepost platform.'}
            </Paragraph>
            <Paragraph>
              {
                'Your content will be permanently removed and there is no way to recover that data or information once you click the button below.'
              }
            </Paragraph>
            <Paragraph>{'Handle with care!'}</Paragraph>
            <DeleteButton onClick={handleDeleteButtonClick}>{'Delete My Account'}</DeleteButton>
          </>
        )}
        {!currentUser && (
          <>
            <Paragraph>{'Your account has been deleted.'}</Paragraph>
            <Paragraph>
              <Link to={'/'}>{'Homepost Home'}</Link>
            </Paragraph>
          </>
        )}

        <SimplePopover
          bodyText={'We’ll remove your account and any content related to your account. This action cannot be undone.'}
          buttonBackgroundColor={darkRed}
          buttonText={'Delete it'}
          contentLabel={'Delete your account?'}
          emoji={'🗑'}
          headerText={'Delete your account?'}
          isOpen={isDeleteConfirmationPopoverOpen}
          onButtonClick={handleDeleteClick}
          onRequestClose={handleConfirmationPopoverRequestClose}
        />
      </Content>
    </>
  );
}

export default DeleteAccount;
