import styled from 'styled-components';
import { black, darkGray } from 'styles/colors';

type BodyProps = {
  bold?: boolean;
  color?: string;
  small?: boolean;
};

const Body = styled.div<BodyProps>`
  font-style: normal;
  font-weight: ${(props) => (props?.bold ? 600 : 400)};
  font-size: ${(props) => (props?.small ? '12px' : '15px')};
  line-height: ${(props) => (props?.small ? '15px' : '18px')};
  margin-bottom: 16px;
  color: ${(props) => (props?.color ? props.color : props?.small ? darkGray : black)};
`;

export default Body;
