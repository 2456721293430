import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

import { black, gray } from 'styles/colors';

const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid ${gray};
`;

const PostContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  color: ${black};
  margin-left: 8px;
  width: calc(100% - 72px);
  overflow: hidden;
`;

export type PostTitleProps = {
  unread?: boolean;
};

function PostSkeleton() {
  return (
    <ListItem>
      <Skeleton circle width={40} height={40} />
      <PostContent>
        <Skeleton width={80} />
        <Skeleton width={200} />
      </PostContent>
    </ListItem>
  );
}

export default PostSkeleton;
