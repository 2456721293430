import styled from 'styled-components';

import Avatar from 'components/Avatar';
import Body from 'styled-components/Body';
import { ConnectedUser, PublicUser, useCreateConnectionIntentMutation } from 'generated';
import { ReactElement } from 'react';
import { black, white } from 'styles/colors';
import BlueButton from 'styled-components/BlueButton';
import track from 'services/track';
import { toast } from 'react-toastify';

const Container = styled.div``;

const UserHeader = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  padding: 16px;
`;

const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 16px;
  border-radius: 14px;
  padding: 16px;
  background: ${white};
`;

const Name = styled.div`
  font-size: 20px;
  color: ${black};
  font-weight: 700;
  margin-bottom: 4px;
`;

const ConnectionText = styled.div`
  font-size: 15px;
  font-weight: bold;
`;

export interface UserContentProps {
  isOwnProfile?: boolean;
  user: PublicUser | ConnectedUser;
}

function UserContent({ isOwnProfile = false, user }: UserContentProps): ReactElement {
  // ToDo: The aboutMe duplication is nice and confusing
  let aboutMeBody = '';
  if (aboutMeBody[aboutMeBody.length - 1] === ',') aboutMeBody = aboutMeBody.slice(0, -1);

  const [createConnectionIntent] = useCreateConnectionIntentMutation();

  const handleConnectClick = (): void => {
    createConnectionIntent({
      onCompleted: () => {
        track('Connection Intent Created', {});
        toast.info(`Connection request sent to ${user?.firstName ?? ''}`);
      },
      refetchQueries: ['User'],
      variables: {
        input: {
          connectedUserId: user?._id,
          intent: 'connect',
        },
      },
    }).catch((err) => console.log(err.toString()));
  };

  const handleAcceptConnectClick = (): void => {
    createConnectionIntent({
      onCompleted: () => {
        track('Connection Request Accepted', {});
        toast.info(`You are now connected to ${user?.firstName ?? ''}`);
      },
      refetchQueries: ['User'],
      variables: {
        input: {
          connectedUserId: user?._id,
          intent: 'connect',
        },
      },
    }).catch((err) => console.log(err.toString()));
  };

  const showConnectButton = !isOwnProfile && !user?.connected && !user?.connectionPending && !user?.connectionRequestPending;
  const showPendingButton = !user?.connected && user?.connectionPending;
  const showAcceptButton = !user?.connected && user?.connectionRequestPending;
  const showConnectedButton = user?.connected;
  const hideConnectSection = !showConnectButton && !showPendingButton && !showAcceptButton && !showConnectedButton;

  return (
    <Container>
      <UserHeader>
        <Avatar path={user?.profileImage?.path} size={128} text={user?.firstName?.slice(0, 1)} />
        <Section>
          <Name>{user?.firstName}</Name>
          <Body>{user?.aboutMe}</Body>
        </Section>
        {!hideConnectSection && (
          <Section>
            {showConnectedButton && <ConnectionText>{`${user?.firstName ?? ''} is a connection`}</ConnectionText>}
            {showConnectButton && (
              <BlueButton small onClick={handleConnectClick}>
                {'Connect'}
              </BlueButton>
            )}
            {showAcceptButton && (
              <BlueButton small onClick={handleAcceptConnectClick}>
                {'Accept connection'}
              </BlueButton>
            )}
            {showPendingButton && (
              <BlueButton disabled small>
                {'Connection pending'}
              </BlueButton>
            )}
          </Section>
        )}
      </UserHeader>
    </Container>
  );
}

export default UserContent;
