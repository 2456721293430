import styled from 'styled-components';

import { darkBlue } from 'styles/colors';

const BlueAnchor = styled.a`
  text-decoration: none;
  color: ${darkBlue};
  cursor: pointer;
`;

export default BlueAnchor;
