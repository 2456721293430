import { ReactElement, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { CurrentUserContext } from 'App';

import { PublicUser, useCreateConnectionIntentMutation, usePotentialConnectedUsersByCurrentUserQuery } from 'generated';

import Body from 'styled-components/Body';
import BottomNav from 'components/BottomNav';
import Content from 'styled-components/Content';
import Header from 'styled-components/Header';
import HeaderTitle from 'styled-components/HeaderTitle';
import Image from 'components/Image';
import PrimaryHeaderButton from 'styled-components/PrimaryHeaderButton';
import SimplePopover from 'components/SimplePopover';
import TextButton from 'styled-components/TextButton';
import UserContent from 'components/User/UserContent';

import track from 'services/track';

import { darkGray, darkRed, lightGray, offWhite, white } from 'styles/colors';
import refetchQueries from 'utils/refetchQueries';

const Container = styled.div``;

const HeaderText = styled.h2`
  margin-bottom: 8px;
`;

export interface UserContentWrapperProps {
  active?: boolean;
  centered?: boolean;
  next?: boolean;
  prev?: boolean;
}

const UserContentWrapper = styled.div<UserContentWrapperProps>`
  border: 2px solid ${lightGray};
  position: absolute;
  width: calc(100% - 32px);
  border-radius: 20px;
  padding: 24px;
  background: ${white};
  text-align: ${(props) => (props?.centered ? 'center' : 'initial')};
  transition: transform 0.8s ease-in-out;
  transform: ${(props) => {
    if (props?.active) return 'translate(0, 0)';
    if (props?.next) return 'translate(120vw, 0)';
    if (props?.prev) return 'translate(-120vw, 0)';

    return '';
  }};
`;

const SilhouetteWrapper = styled.div`
  margin-bottom: 16px;

  img {
    width: 128px;
    height: 128px;
  }
`;

const SectionHeader = styled.div`
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 8px;
  color: ${darkGray};
`;

function Menu(): ReactElement {
  const navigate = useNavigate();

  const [potentialConnections, setPotentialConnections] = useState<PublicUser[]>([]);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [firstConnectionPopoverOpen, setFirstConnectionPopoverOpen] = useState<boolean>(false);
  const [photoRequiredPopoverOpen, setPhotoRequiredPopoverOpen] = useState<boolean>(false);
  const [intent, setIntent] = useState<'ignore' | 'connect' | ''>('');

  const currentUser = useContext(CurrentUserContext);
  const [isFirstTimeConnection, setIsFirstTimeConnection] = useState<boolean>(!currentUser?.hasCreatedAConnectionIntent);

  const { error, data, loading } = usePotentialConnectedUsersByCurrentUserQuery({
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (data?.potentialConnectedUsersByCurrentUser?.length && !potentialConnections?.length) {
      setPotentialConnections(data.potentialConnectedUsersByCurrentUser);
    }
  }, [data?.potentialConnectedUsersByCurrentUser, potentialConnections]);

  useEffect(() => {
    track('Connect Screen Viewed', {});
  }, []);

  const currentConnection = potentialConnections[activeIndex];

  const [createConnectionIntent] = useCreateConnectionIntentMutation({
    onCompleted: () => {
      if (intent === 'connect') {
        track('Connection Intent Created', {});
        toast.info(`Connection request sent to ${currentConnection?.firstName ?? ''}`);
      }

      if (intent === 'ignore') {
        track('Connection Ignored', {});
      }

      setActiveIndex((i) => i + 1);
    },
    variables: {
      input: {
        connectedUserId: currentConnection?._id,
        intent,
      },
    },
  });

  const updateIntentAndSubmit = (connectionIntent: 'ignore' | 'connect'): void => {
    setIntent(() => connectionIntent);

    setTimeout(() => {
      createConnectionIntent().catch((err) => console.log(err.toString()));
    }, 0);
  };

  const handleIgnoreClick = (): void => {
    updateIntentAndSubmit('ignore');
  };

  const handleConnectClick = (): void => {
    if (!currentUser?.profileImage?._id) {
      track('Connection Request Photo Required Modal Viewed', {});
      setPhotoRequiredPopoverOpen(true);
    } else if (isFirstTimeConnection) {
      track('Connection Request First Time Modal Viewed', {});
      setFirstConnectionPopoverOpen(true);
    } else {
      updateIntentAndSubmit('connect');
    }
  };

  const handleFirstConnectionPopoverRequestClose = (): void => {
    track('Connection Request First Time Modal Closed', {});
    setFirstConnectionPopoverOpen(false);
  };

  const handleFirstConnectionPopoverButtonClick = (): void => {
    track('Connection Request First Time Modal CTA Clicked', {});
    updateIntentAndSubmit('connect');
    setFirstConnectionPopoverOpen(false);
    setIsFirstTimeConnection(false);
  };

  const handlePhotoRequiredPopoverRequestClose = (): void => {
    track('Connection Request Photo Required Modal Closed', {});
    setPhotoRequiredPopoverOpen(false);
  };

  const handlePhotoRequiredPopoverButtonClick = (): void => {
    track('Connection Request Photo Required Modal CTA Clicked', {});
    navigate('/profile');
  };

  const hasPotentialConnections = !!potentialConnections?.length;
  const showEmptyCard = !loading && activeIndex === potentialConnections?.length;

  useEffect(() => {
    refetchQueries(['CurrentUser']).catch((err) => console.log(err.toString()));
  }, [showEmptyCard]);

  if (error) return <p>Error :(</p>;

  if (!loading && !data?.potentialConnectedUsersByCurrentUser?.length) {
    return (
      <Container>
        <Header>
          <HeaderTitle>{'Connect'}</HeaderTitle>
        </Header>
        <Content backgroundColor={offWhite} style={{ textAlign: 'center' }} padding={16}>
          <HeaderText>{'Neighbors you should meet'}</HeaderText>
          <Body>{'Match with people near you based on your interests and RSVPs'}</Body>
          <UserContentWrapper active centered>
            <SilhouetteWrapper>
              <Image path={'assets/person_silhouette'} />
            </SilhouetteWrapper>
            <SectionHeader>{'New matches coming soon'}</SectionHeader>
            <Body
              color={darkGray}
            >{`We'll keep checking your neighborhood and will add new matches here as soon as they're available`}</Body>
          </UserContentWrapper>
        </Content>
        <BottomNav />
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        {hasPotentialConnections && (
          <TextButton color={darkRed} onClick={handleIgnoreClick}>
            {'Ignore'}
          </TextButton>
        )}
        <HeaderTitle>{'Connect'}</HeaderTitle>
        {hasPotentialConnections && <PrimaryHeaderButton onClick={handleConnectClick}>{'Connect'}</PrimaryHeaderButton>}
      </Header>
      <Content backgroundColor={offWhite} style={{ textAlign: 'center' }} padding={16}>
        <HeaderText>{'Neighbors you should meet'}</HeaderText>
        <Body>{'Match with people near you based on your interests and RSVPs'}</Body>
        {potentialConnections.map((user, idx) => (
          <UserContentWrapper active={idx === activeIndex} key={user._id} next={idx > activeIndex} prev={idx < activeIndex}>
            <UserContent user={user} />
          </UserContentWrapper>
        ))}
        <UserContentWrapper active={showEmptyCard} next={!showEmptyCard} centered>
          <SilhouetteWrapper>
            <Image path={'assets/person_silhouette'} />
          </SilhouetteWrapper>
          <SectionHeader>{'New matches coming soon'}</SectionHeader>
          <Body color={darkGray}>{`We'll keep checking your neighborhood and will add new matches here as soon as they're available`}</Body>
        </UserContentWrapper>
      </Content>
      <BottomNav />

      <SimplePopover
        bodyText={'When a connection is made, the phone number you used to sign up will be shared with the other person.'}
        buttonText={'Sounds good'}
        contentLabel={'Connection request'}
        emoji={'👋'}
        headerText={'Connection request'}
        isOpen={firstConnectionPopoverOpen}
        onButtonClick={handleFirstConnectionPopoverButtonClick}
        onRequestClose={handleFirstConnectionPopoverRequestClose}
      />

      <SimplePopover
        bodyText={
          'People use this app to connect with their community. Add a profile so you can build trust and connect with your neighbors.'
        }
        buttonText={'Add a profile photo'}
        contentLabel={'Hey stranger'}
        emoji={'😐'}
        headerText={'Hey stranger'}
        isOpen={photoRequiredPopoverOpen}
        onButtonClick={handlePhotoRequiredPopoverButtonClick}
        onRequestClose={handlePhotoRequiredPopoverRequestClose}
      />
    </Container>
  );
}

export default Menu;
