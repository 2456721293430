import { useEffect } from 'react';

import { useLogoutMutation } from '../../generated';
import { useNavigate } from 'react-router-dom';

function Logout(): null {
  const navigate = useNavigate();

  const [logout] = useLogoutMutation({
    onCompleted: () => {
      window.setTimeout(() => {
        navigate('/');
      }, 250);
    },
    refetchQueries: ['CurrentUser'],
    update: () => {
      localStorage.setItem('auth_token', '');
    },
  });

  useEffect(() => {
    logout().catch((err) => console.log(err.toString()));
  }, [logout]);

  return null;
}

export default Logout;
