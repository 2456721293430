import styled from 'styled-components';
import { usePlatformInvitationQuery, useRedeemPlatformInvitationMutation } from 'generated';
import { ReactElement } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Content from 'styled-components/Content';
import { white, black, darkBlue, darkGray } from 'styles/colors';
import Image from 'components/Image';
import Body from 'styled-components/Body';
import { toast } from 'react-toastify';

const StackWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Stack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  margin-top: 8px;
  padding: 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: center;
`;

const H1Text = styled.h1`
  margin-top: 24px;
  color: ${black};
  margin-bottom: 16px;
`;

const Button = styled.button`
  background: ${darkBlue};
  border: none;
  border-radius: 5px;
  padding: 12px 40px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */

  text-align: center;

  color: ${white};
  width: auto;
  cursor: pointer;
`;

const ImageWrapper = styled.div`
  margin-top: 80px;
  width: 222px;
  height: 222px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }
`;

function LoggedInPlatformInvitation(): ReactElement {
  const { platformInvitationId = '' } = useParams();
  const navigate = useNavigate();

  const [redeemPlatformInvitation] = useRedeemPlatformInvitationMutation({
    variables: {
      input: {
        platformInvitationId,
      },
    },
    onCompleted: (data) => {
      if (data.redeemPlatformInvitation?.success) {
        toast.success('You are now connected!');
        navigate('/events');
      }
    },
  });

  const { loading, error, data } = usePlatformInvitationQuery({
    variables: {
      input: {
        platformInvitationId,
      },
    },
  });

  const handleConnectClick = (): void => {
    redeemPlatformInvitation().catch((err: Error) => {
      console.log(`Invitation error: ${err.toString()}`);
    });
  };

  const handleIgnoreClick = (): void => {
    navigate('/events');
  };

  if (loading || error || !data?.platformInvitation) {
    return <div />;
  }

  const inviterFirstName = data?.platformInvitation?.createdBy?.firstName || '';
  const inviterProfileImagePath = data?.platformInvitation?.createdBy?.profileImage?.path || '';

  return (
    <Content backgroundColor={white}>
      <StackWrapper>
        <Stack>
          <Image path={'/logos/home_post_logo_dark'} />
          <ImageWrapper>
            <Image path={inviterProfileImagePath} />
          </ImageWrapper>
          <H1Text>{`${inviterFirstName} wants to connect`}</H1Text>
          <Body>{'When you connect, you will be able to see events that you share with each other.'}</Body>
          <ButtonWrapper>
            <Button onClick={handleConnectClick}>{'Connect'}</Button>
            <Button onClick={handleIgnoreClick} style={{ backgroundColor: darkGray }}>
              {'Ignore'}
            </Button>
          </ButtonWrapper>
        </Stack>
      </StackWrapper>
    </Content>
  );
}

export default LoggedInPlatformInvitation;
