import styled from 'styled-components';

import { white } from 'styles/colors';

export interface ContentProps {
  backgroundColor?: string;
  padding?: number;
}

const Content = styled.div<ContentProps>`
  position: relative;
  width: 100%;
  background: ${(props) => (props?.backgroundColor ? props.backgroundColor : white)};
  padding: ${(props) => (props?.padding ? `${props.padding}px` : 0)};

  @media screen and (max-width: 767px) {
    padding-bottom: 72px;
  }

  @media screen and (min-width: 768px) {
    overflow: auto;
  }
`;

export default Content;
