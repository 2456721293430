import { ReactElement } from 'react';
import styled from 'styled-components';
import { lightGray } from 'styles/colors';

import SingleActionButton, { SingleActionButtonProps } from './SingleActionButton';

const OuterWrapper = styled.div`
  margin-top: 16px;
`;

const Wrapper = styled.div`
  > * {
    margin-top: 0 !important;
    border-radius: 0;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 16px;
      top: 0;
      height: 1px;
      width: calc(100% - 32px);
      border-top: 1px solid ${lightGray};
    }

    &:first-child {
      border-top: 0;
      border-top-left-radius: 14px;
      border-top-right-radius: 14px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;

      &:before {
        border-top: 0;
      }
    }

    &:last-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 14px;
      border-bottom-left-radius: 14px;
    }

    &:not(:first-child):not(:last-child) {
      border-radius: 0;
    }
  }
`;

interface StackedActionButtonsProps {
  actionButtons?: SingleActionButtonProps[];
  title?: string;
}

function StackedActionButtons({ actionButtons = [], title }: StackedActionButtonsProps): ReactElement {
  return (
    <OuterWrapper>
      <Wrapper>
        {title && <SingleActionButton text={title} />}
        {actionButtons.map((actionButton, idx) => (
          <SingleActionButton key={`${actionButton.text}-idx`} {...actionButton} />
        ))}
      </Wrapper>
    </OuterWrapper>
  );
}

export default StackedActionButtons;
