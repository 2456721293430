import { ReactElement, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { CurrentUserContext } from 'App';
import { CurrentUser } from 'generated';

import LandingPage from 'components/LandingPage';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

function isProfileComplete(currentUser: CurrentUser | null): boolean {
  if (currentUser) {
    if (currentUser.firstName) {
      return true;
    }
  }

  return false;
}

function Home(): ReactElement | null {
  const navigate = useNavigate();

  const currentUser = useContext<CurrentUser | null>(CurrentUserContext);
  const profileComplete: boolean = isProfileComplete(currentUser);

  const returnToRoute = useSelector((state: RootState) => state.onboarding.returnToRoute);

  useEffect(() => {
    if (currentUser && profileComplete) {
      navigate(returnToRoute ?? '/events');
    }

    if (currentUser && !profileComplete) {
      navigate('/complete-profile');
    }
  }, [currentUser, profileComplete, navigate, returnToRoute]);

  if (!currentUser) {
    return <LandingPage />;
  }

  return null;
}

export default Home;
