import styled from 'styled-components';
import Modal from 'react-modal';

import { ReactElement } from 'react';
import { lightGray } from 'styles/colors';

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  width: 100%;

  > * {
    width: 100%;
  }
`;

export type FullScreenPopoverProps = {
  content: ReactElement;
  contentLabel: string;
  isOpen: boolean;
  onRequestClose: () => void;
};

function FullScreenPopover({ content, contentLabel, isOpen, onRequestClose }: FullScreenPopoverProps) {
  return (
    <Modal
      style={{
        overlay: { background: 'rgba(0, 0, 0, 0.3)', zIndex: 10 },
        content: {
          border: '0',
          borderRadius: '16px',
          bottom: 'auto',
          left: '50%',
          marginRight: '-0%',
          right: 'auto',
          top: '50%',
          width: 'calc(100vw - 32px)',
          height: 'calc(100vh - 32px)',
          background: lightGray,
          zIndex: 1,
        },
      }}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={contentLabel}
      closeTimeoutMS={800}
    >
      <ModalContent>{content}</ModalContent>
    </Modal>
  );
}

export default FullScreenPopover;
