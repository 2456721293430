import styled from 'styled-components';

const HeaderTitle = styled.div`
  position: absolute;
  // Todo: Revisit this
  top: calc(100% - 24px);
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 15px;
  line-height: 18px;
  font-weight: 600;
  color: #000000;
`;

export default HeaderTitle;
