import { ReactElement } from 'react';
import { useParams, Link } from 'react-router-dom';
import styled from 'styled-components';

import { useEventQuery } from 'generated';

import BackButton from 'components/BackButton';
import Content from 'styled-components/Content';
import Header from 'styled-components/Header';
import HeaderTitle from 'styled-components/HeaderTitle';

import { darkGray, white, offWhite, lightGray } from 'styles/colors';

import IconWrapper from 'styled-components/IconWrapper';
import Avatar from 'components/Avatar';

const PeopleButtons = styled.div`
  margin-top: 24px;

  > :not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  > :not(:last-child) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  > :not(:last-child) > div {
    border-bottom: 1px solid ${lightGray};
  }
`;

const PersonButton = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${white};
  outline: 0;
  border: 0;
  border-radius: 14px;
  width: 100%;
  text-decoration: none;
`;

const PersonButtonContent = styled.div`
  margin: 0px 0px 0px 20px;
  padding: 16px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 20px);
`;

const PersonLeft = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const PersonFirstName = styled.div`
  margin-left: 8px;
  font-size: 15px;
`;

const PersonRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 16px;
`;

const PersonMeta = styled.div`
  margin-right: 8px;
  color: ${darkGray};
  font-size: 15px;
`;

function EventAttendees(): ReactElement {
  const params = useParams();
  const { eventId = '' } = params;

  const { error, data, loading } = useEventQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        eventId,
      },
    },
  });

  const event = data?.event;

  if (error) return <p>Error :(</p>;

  if (!event) {
    return (
      <>
        <Header>
          <BackButton />
          <HeaderTitle>{'View Event'}</HeaderTitle>
        </Header>
        {!loading && <Content>{'Unable to find this event'}</Content>}
      </>
    );
  }

  const attendingUsers = (event?.attendance?.attendingUsers ?? []).filter((attendingUser) => attendingUser?._id !== event?.createdBy?._id);

  return (
    <>
      <Header>
        <BackButton fallbackTo={`/events/${event?._id}`} />
        <HeaderTitle>{`Who's Going`}</HeaderTitle>
      </Header>
      <Content backgroundColor={offWhite} padding={16}>
        <PersonButton to={`/neighbors/${event?.createdBy?._id ?? ''}`} style={{ margin: '8px 0' }}>
          <PersonButtonContent>
            <PersonLeft>
              <Avatar path={event?.createdBy?.profileImage?.path} text={event.createdBy?.firstName ?? ''} />
              <PersonFirstName>{event.createdBy?.firstName}</PersonFirstName>
            </PersonLeft>
            <PersonRight>
              <PersonMeta>{'Organizer'}</PersonMeta>
              <IconWrapper color={darkGray} size={20}>
                <span className={'material-symbols-rounded'}>{'chevron_right'}</span>
              </IconWrapper>
            </PersonRight>
          </PersonButtonContent>
        </PersonButton>

        <PeopleButtons>
          {attendingUsers.map((attendingUser) => (
            <PersonButton key={attendingUser?._id} to={`/neighbors/${attendingUser?._id ?? ''}`}>
              <PersonButtonContent>
                <PersonLeft>
                  <Avatar path={attendingUser?.profileImage?.path} text={attendingUser?.firstName ?? ''} />
                  <PersonFirstName>{attendingUser?.firstName}</PersonFirstName>
                </PersonLeft>
                <PersonRight>
                  <PersonMeta>{'Attending'}</PersonMeta>
                  <IconWrapper color={darkGray} size={20}>
                    <span className={'material-symbols-rounded'}>{'chevron_right'}</span>
                  </IconWrapper>
                </PersonRight>
              </PersonButtonContent>
            </PersonButton>
          ))}
        </PeopleButtons>
      </Content>
    </>
  );
}

export default EventAttendees;
