import styled from 'styled-components';

type ParagraphProps = {
  bold?: boolean;
  color?: string;
};

const Paragraph = styled.p<ParagraphProps>`
  color: ${(props) => (props?.color ? props.color : 'inherit')};
  font-style: normal;
  font-weight: ${(props) => (props?.bold ? 600 : 400)};
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 16px;
`;

export default Paragraph;
