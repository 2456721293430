import { createContext, ReactElement, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { setUserId } from '@amplitude/analytics-browser';
import Modal from 'react-modal';
import { Slide, toast, ToastContainer } from 'react-toastify';
import OneSignal from 'onesignal-cordova-plugin';

import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';

import { useCurrentUserQuery, CurrentUser } from 'generated';

import AdminDashboard from 'components/AdminDashboard';
import Connect from 'components/Connect';
import Event from 'components/Event';
import Events from 'components/Events';
import Home from 'components/Home';
import Login from 'components/pages/Login';
import Logout from 'components/Logout';
import Menu from 'components/Menu';
import MyEvents from 'components/MyEvents';
import NewEvent from 'components/NewEvent';
import Notifications from 'components/Notifications';
import PlatformInvitation from 'components/PlatformInvitation';
import ScrollToTop from 'components/ScrollToTop';
import User from 'components/User';
import UserProfile from 'components/UserProfile';
import Users from 'components/Users';

import 'App.css';
import RouteListener from 'components/RouteListener';
import ConnectToUser from 'components/ConnectToUser';
import Calendar from 'components/Calendar';
import EventAttendees from 'components/EventAttendees';
import LandingPage from 'components/LandingPage';
import BottomNav from 'components/BottomNav';
import { offWhite } from 'styles/colors';
import EULA from 'components/pages/EULA';
import TOS from 'components/pages/TOS';
import Support from 'components/pages/Support';
import Privacy from 'components/pages/Privacy';
import AppUrlListener from 'components/AppUrlListener';
import ExternalContacts from 'components/pages/ExternalContacts';
import DeleteAccount from 'components/pages/DeleteAccount';
import LoggedInPlatformInvitation from 'components/LoggedInPlatformInvitation';
import Groups from 'components/pages/Groups';
import NewGroup from 'components/pages/NewGroup';
import GroupInvitation from 'components/pages/GroupInvitation';
import Group from 'components/pages/Group';

const Layout = styled.div`
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${offWhite};
`;

const MobileContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 767px) {
    width: 100vw;
  }

  @media screen and (min-width: 768px) {
    width: 400px;
    position: absolute;
    left: calc(50% - 200px);
    top: 0;
    overflow: auto;
  }
`;

const ContentContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    height: calc(100% - 78px);
  }
`;

export const CurrentUserContext = createContext<CurrentUser | null>(null);

// Note: We block render of the app without geolocation, hence the "as" typing
export const LocationContext = createContext<any>({});

Modal.setAppElement('#root');

function FullHeightLayout(): ReactElement {
  return (
    <ContentContainer>
      <Outlet />
    </ContentContainer>
  );
}

function BottomNavLayout(): ReactElement {
  return (
    <>
      <ContentContainer>
        <Outlet />
      </ContentContainer>
      <BottomNav />
    </>
  );
}

function App({ geoLocation }: { geoLocation?: GeolocationPosition }): ReactElement | null {
  const { data, error, loading } = useCurrentUserQuery();

  const currentUser = data?.currentUser ?? null;

  const hideUI = error || loading;

  useEffect(() => {
    if (!!currentUser) {
      setUserId(currentUser?._id);

      if (window.cordova) {
        OneSignal.login(currentUser._id);
      }
    }
  }, [currentUser]);

  return (
    <BrowserRouter>
      <AppUrlListener />
      {currentUser && !hideUI && (
        <Layout>
          <MobileContainer>
            <LocationContext.Provider value={{}}>
              <CurrentUserContext.Provider value={currentUser}>
                <RouteListener />
                <ScrollToTop>
                  <Routes>
                    <Route element={<BottomNavLayout />}>
                      <Route path={'/'} element={<Home />} />

                      <Route path={'/menu'} element={<Menu />} />

                      <Route path={'/login'} element={<Login />} />
                      <Route path={'/login/:platformInvitationId'} element={<Login />} />
                      <Route path={'/logout'} element={<Logout />} />

                      <Route path={'/profile'} element={<UserProfile />} />

                      <Route path={'/calendar'} element={<Calendar />} />

                      <Route path={'/events'} element={<Events />} />
                      <Route path={'/events/mine'} element={<MyEvents />} />

                      <Route path={'/groups'} element={<Groups />} />
                      <Route path={'/groups/:groupId'} element={<Group />} />
                      <Route path={'/group-invitations/:groupId'} element={<GroupInvitation />} />

                      <Route path={'/connect'} element={<Connect />} />
                      <Route path={'/connect/:userId'} element={<ConnectToUser />} />

                      <Route path={'/notifications'} element={<Notifications />} />

                      <Route path={'/admin'} element={<AdminDashboard />} />
                    </Route>

                    <Route element={<FullHeightLayout />}>
                      <Route path={'/terms'} element={<TOS />} />
                      <Route path={'/privacy'} element={<Privacy />} />

                      <Route path={'/complete-profile'} element={<UserProfile hideBack showContinue />} />

                      <Route path={'/events/new'} element={<NewEvent />} />
                      <Route path={'/events/:eventId'} element={<Event />} />
                      <Route path={'/events/:eventId/attendees'} element={<EventAttendees />} />

                      <Route path={'/groups/new'} element={<NewGroup />} />

                      <Route path={'/friends'} element={<Users />} />
                      <Route path={'/neighbors/:userId'} element={<User />} />
                      <Route path={'/friends/:userId'} element={<User />} />

                      <Route path={'/platform-invitations/:platformInvitationId'} element={<LoggedInPlatformInvitation />} />

                      <Route path={'/external-contacts'} element={<ExternalContacts />} />

                      <Route path={'/delete-account'} element={<DeleteAccount />} />

                      <Route path={'/terms'} element={<TOS />} />
                      <Route path={'/privacy'} element={<Privacy />} />
                      <Route path={'/support'} element={<Support />} />
                      <Route path={'/eula'} element={<EULA />} />
                    </Route>
                  </Routes>
                </ScrollToTop>
              </CurrentUserContext.Provider>
            </LocationContext.Provider>
          </MobileContainer>
          <ToastContainer autoClose={3000} hideProgressBar newestOnTop position={toast.POSITION.BOTTOM_CENTER} transition={Slide} />
        </Layout>
      )}

      {!currentUser && !hideUI && (
        <Layout>
          <MobileContainer>
            <Routes>
              <Route element={<FullHeightLayout />}>
                <Route path="/" element={<LandingPage />} />

                <Route path={'/login'} element={<Login />} />
                <Route path={'/login/:genericInvitationId'} element={<Login />} />

                <Route path={'/events/:eventId'} element={<Event />} />

                <Route path={'/platform-invitations/:platformInvitationId'} element={<PlatformInvitation />} />

                <Route path={'/group-invitations/:groupId'} element={<GroupInvitation />} />

                <Route path={'/terms'} element={<TOS />} />
                <Route path={'/privacy'} element={<Privacy />} />
                <Route path={'/support'} element={<Support />} />
                <Route path={'/eula'} element={<EULA />} />

                <Route path={'/delete-account'} element={<DeleteAccount />} />
              </Route>
            </Routes>
          </MobileContainer>
        </Layout>
      )}
    </BrowserRouter>
  );
}

export default App;
