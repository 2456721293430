import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { useCreateBlockMutation } from '../../generated';

import { darkRed } from 'styles/colors';
import SimplePopover from 'components/SimplePopover';

export type BlockButtonProps = {
  userId: string;
  text?: string;
};

const StyledButton = styled.button`
  text-decoration: none;
  color: #b0243c;
  background: transparent;
  border: 0;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  cursor: pointer;
`;

function BlockButton({ userId, text = 'Block' }: BlockButtonProps) {
  const navigate = useNavigate();

  const [error, setError] = useState<string>('');
  const [isBlockingPopoverOpen, seIsBlockingPopoverOpen] = useState<boolean>(false);

  const [createBlock] = useCreateBlockMutation({
    onCompleted: (data) => {
      if (data.createBlock.error) {
        setError(data.createBlock.error);
      } else {
        toast.success('User blocked successfully');
        navigate('/');
      }
    },
    variables: {
      input: {
        userId,
      },
    },
  });

  const handleButtonClick = () => {
    seIsBlockingPopoverOpen(true);
  };

  const handleRequestClose = () => {
    seIsBlockingPopoverOpen(false);
  };

  const handleContinueBlockClick = () => {
    createBlock();
  };

  return (
    <>
      <StyledButton onClick={handleButtonClick}>{text}</StyledButton>
      <SimplePopover
        bodyText={`If you block this user you won't see any of their posts, and they won't be able to see your posts.`}
        buttonBackgroundColor={darkRed}
        buttonText={'Block them'}
        contentLabel={'Continue with blocking this user?'}
        emoji={'🙈'}
        headerText={'Block this neighbor?'}
        isOpen={isBlockingPopoverOpen}
        onButtonClick={handleContinueBlockClick}
        onRequestClose={handleRequestClose}
      />
    </>
  );
}

export default BlockButton;
