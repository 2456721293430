import Image from 'components/Image';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Content from 'styled-components/Content';
import Header from 'styled-components/Header';

const Text = styled.div`
  padding: 16px;

  * {
    margin-bottom: 16px;
  }
`;

function TOS(): ReactElement {
  return (
    <>
      <Header>
        <div />
        <Link style={{ lineHeight: 0 }} to={'/'}>
          <Image path={'/logos/home_post_logo_dark'} />
        </Link>
        <div />
      </Header>
      <Content>
        <Text>
          <h2>{'Terms of Service'}</h2>
          <p>{'Last updated: October 29, 2023'}</p>

          <p>{'These Terms of Use ("Terms") apply to your access and use of HomePost (the "App"). Please read them carefully.'}</p>

          <p>{'Acceptance of Terms'}</p>

          <p>
            {
              'By downloading, accessing, or using our App, you accept and agree to be bound by these Terms. If you do not agree to these Terms, you may not download, access or use the App.'
            }
          </p>

          <p>{'Changes to Terms'}</p>

          <p>
            {
              "We may modify these Terms at any time, in our sole discretion. If we do so, we'll let you know either by posting the modified Terms on the App or through other communications. It's important that you review any modified Terms before you continue using the App. Your continued use of the App after we have posted modified Terms means you accept and agree to the modifications. If you do not agree to the modified Terms, you must stop using the App."
            }
          </p>

          <p>{'Access and Use'}</p>

          <p>
            {
              'You must be at least 13 years old to access and use the App. If you are under 13 years old, you may not download, access or use the App.'
            }
          </p>

          <p>
            {
              'We grant you a non-exclusive, non-transferable, revocable limited license to access and use the App for your own personal, non-commercial purposes. You may not use the App for any other purpose.'
            }
          </p>

          <p>{'User Content'}</p>

          <p>
            {
              'You are responsible for all content, including but not limited to photos, events, and other materials ("User Content") that you upload, post or share on or through the App. You represent and warrant that you have all rights necessary to share the User Content.'
            }
          </p>

          <p>
            {
              'You retain all ownership over your User Content. However, by sharing User Content through the App, you grant us a worldwide, royalty-free, sublicensable, and transferable license to host, store, use, display, reproduce, modify, adapt, edit, publish and distribute your User Content in order to operate and provide the App.'
            }
          </p>

          <p>
            {
              'We have no obligation to monitor, display or remove your User Content. We reserve the right to delete or modify User Content in accordance with our internal policies and procedures.'
            }
          </p>

          <p>{'Account Security'}</p>

          <p>
            {
              'You are responsible for safeguarding the password you use to access the App. Do not share your password with anyone. If you believe your account has been compromised, please contact us immediately at hi@homepostapp.com.'
            }
          </p>

          <p>{'Disclaimer Of Warranty'}</p>

          <p>
            {
              'THESE SERVICES ARE PROVIDED BY HOMEPOST ON AN “AS IS” AND “AS AVAILABLE” BASIS. HOMEPOST MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES, OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE OF THESE SERVICES, THEIR CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.'
            }
            {
              'NEITHER HOMEPOST NOR ANY PERSON ASSOCIATED WITH IT MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES, THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.'
            }
            {
              'COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.'
            }
            {'THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.'}
          </p>

          <p>{'Limitation Of Liability'}</p>

          <p>
            {
              "EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES (INCLUDING ATTORNEYS' FEES AND ALL RELATED COSTS AND EXPENSES OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND ANY VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE PART OF COMPANY, IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU."
            }
          </p>

          <p>{'Termination'}</p>

          <p>
            {
              'We reserve the right, without notice and at our sole discretion, to suspend or terminate your right to access or use the App. We will not be liable to you for any losses related to the termination of your right to access or use the App.'
            }
          </p>

          <p>{'Governing Law'}</p>

          <p>
            {
              'These Terms are governed by the laws of the State of California without regard to conflict of law principles. Any dispute arising from these Terms shall be resolved exclusively in the state or federal courts located in San Diego, California.'
            }
          </p>

          <p>{'Acknowledgement'}</p>

          <p>
            {
              'BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.'
            }
          </p>

          <p>{'Contact Us'}</p>

          <p>{'If you have any questions about these Terms, please contact us at: hi@homepostapp.com.'}</p>
        </Text>
      </Content>
    </>
  );
}

export default TOS;
