import styled from 'styled-components';

interface IconWrapperProps {
  color: string;
  fill?: boolean;
  size: number;
  bold?: boolean;
}

const IconWrapper = styled.div<IconWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props?.color};
  position: relative;
  height: ${(props) => `${props.size}px`};
  width: ${(props) => `${props.size}px`};

  span {
    font-size: ${(props) => `${props.size}px`};
    font-variation-settings: 'FILL' ${(props) => (props?.fill ? 1 : 0)}, 'wght' ${(props) => (props?.bold ? 500 : 400)}, 'GRAD' 0;
  }
`;

export default IconWrapper;
