import { ReactElement, useContext } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';

import { darkGray, gray, lightBlue, white } from 'styles/colors';
import IconWrapper from 'styled-components/IconWrapper';
import Body from 'styled-components/Body';

import { CurrentUserContext } from 'App';
import { CurrentUser, useCurrentUserQuery } from 'generated';

const Container = styled.nav`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 78px;
  width: 100vw;
  border-top: 2px solid ${gray};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background: ${white};

  @media screen and (min-width: 768px) {
    width: 400px;
    left: calc(50% - 200px);
  }
`;

const UnstyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;

const NavItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60px;
  transform: translateY(-10px);

  svg {
    margin-bottom: 4px;
  }

  > div {
    &:nth-child(2) {
      margin-bottom: 0 !important;
      white-space: nowrap;
    }
  }
`;

const Indicator = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${lightBlue};
  border-radius: 50%;
  position: absolute;
  right: -2px;
  top: -2px;
`;

function BottomNav(): ReactElement {
  // Fetch current user to look for notifications
  // ToDo: Move notifications to a subscription
  const currentUserFromContext = useContext<CurrentUser | null>(CurrentUserContext);
  const { loading, error, data } = useCurrentUserQuery({ fetchPolicy: 'cache-and-network' });
  const currentUser = loading || error ? currentUserFromContext : data?.currentUser;

  const location = useLocation();

  return (
    <Container>
      <UnstyledLink to={'/events'}>
        <NavItem>
          <IconWrapper size={28} color={location.pathname === '/events' ? lightBlue : darkGray}>
            <span className={'material-symbols-outlined'}>{'today'}</span>
          </IconWrapper>
          <Body small color={location.pathname === '/events' ? lightBlue : darkGray}>
            {'Calendar'}
          </Body>
        </NavItem>
      </UnstyledLink>

      <UnstyledLink to={'/events/new'}>
        <NavItem>
          <IconWrapper size={28} color={location.pathname === '/events/new' ? lightBlue : darkGray}>
            <span className={'material-symbols-outlined'}>{'add_circle'}</span>
          </IconWrapper>
          <Body small color={location.pathname === '/events/new' ? lightBlue : darkGray}>
            {'Add Event'}
          </Body>
        </NavItem>
      </UnstyledLink>

      <UnstyledLink to={'/groups'}>
        <NavItem>
          <IconWrapper size={28} color={location.pathname === '/groups' ? lightBlue : darkGray}>
            <span className={'material-symbols-outlined'}>{'group'}</span>
          </IconWrapper>
          <Body small color={location.pathname === '/groups' ? lightBlue : darkGray}>
            {'Friends'}
          </Body>
        </NavItem>
      </UnstyledLink>

      <UnstyledLink to={'/notifications'}>
        <NavItem>
          <IconWrapper size={28} color={location.pathname === '/notifications' ? lightBlue : darkGray}>
            {currentUser?.hasUnseenNotifications && <Indicator />}
            <span className={'material-symbols-outlined'}>{'message'}</span>
          </IconWrapper>
          <Body small color={location.pathname === '/notifications' ? lightBlue : darkGray}>
            {'Inbox'}
          </Body>
        </NavItem>
      </UnstyledLink>
    </Container>
  );
}

export default BottomNav;
