const { REACT_APP_CLIENT_URL = '' } = process.env;

function useHandleGroupInviteClick({ groupId }: { groupId: string }): Array<() => Promise<void>> {
  const handleInviteClick = async (): Promise<void> => {
    if (navigator.share) {
      navigator
        .share({
          title: 'Homepost invite',
          text: 'Join me on Homepost',
          url: `${REACT_APP_CLIENT_URL}/group-invitations/${groupId}`,
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    } else {
      // Show the url? Copy and paste it?
      console.log('invite', `${REACT_APP_CLIENT_URL}/group-invitations/${groupId}`);
    }
  };

  return [handleInviteClick];
}

export default useHandleGroupInviteClick;
