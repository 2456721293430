import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import IconWrapper from 'styled-components/IconWrapper';

import { darkBlue } from 'styles/colors';

export interface BackButtonProps {
  onClickOverride?: () => void;
  showChevron?: boolean;
  text?: string;
  to?: string;
  fallbackTo?: string;
}

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: ${darkBlue};
  background: transparent;
  border: 0;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  cursor: pointer;
`;

function BackButton({ onClickOverride, showChevron = true, text = 'Back', to, fallbackTo }: BackButtonProps): ReactElement {
  const navigate = useNavigate();

  const handleButtonClick = (): void => {
    if (onClickOverride) {
      onClickOverride?.();
    } else if (to) {
      navigate(to);
    } else if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else if (fallbackTo) {
      navigate(fallbackTo);
    }
  };

  return (
    <StyledButton onClick={handleButtonClick}>
      {showChevron && (
        <IconWrapper color={darkBlue} size={20}>
          <span className={'material-symbols-outlined'}>{'chevron_left'}</span>
        </IconWrapper>
      )}
      <div>{text}</div>
    </StyledButton>
  );
}

export default BackButton;
