import { ReactElement, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import { Interweave } from 'interweave';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { Popover } from 'react-tiny-popover';

import { CurrentUserContext } from 'App';

import { useDeleteEventMutation, useCreateEventFlagMutation, useEventQuery, CurrentUser, useCreateEventIntentMutation } from 'generated';

import { onBeginOnboardingFromRoute } from 'store/onboarding';

import BackButton from 'components/BackButton';
// import BlockButton from 'components/BlockButton';
import BlueLink from 'styled-components/BlueLink';
import Content from 'styled-components/Content';
import Header from 'styled-components/Header';
import HeaderTitle from 'styled-components/HeaderTitle';
import SimplePopover from 'components/SimplePopover';
import MultiAvatar from 'components/MultiAvatar';

import { black, darkGray, darkRed, white, offWhite, lightBlue, darkBlue, gray, lightGray } from 'styles/colors';

import useTrackOnData from 'components/useTrackOnData';
import { GoogleMap, Marker } from '@react-google-maps/api';
import BlueAnchor from 'styled-components/BlueAnchor';
import ContentFormatting from 'styled-components/ContentFormatting';
import Image from 'components/Image';
import { useDispatch } from 'react-redux';
import IconWrapper from 'styled-components/IconWrapper';
import Body from 'styled-components/Body';
import InputWrapper from 'styled-components/InputWrapper';

const { REACT_APP_CLIENT_URL = '' } = process.env;

const Container = styled.div``;

const EventContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px;
  width: 100%;
`;

const EventBasicInfoAndActions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const EventAvatarAndInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  color: ${black};
  border-radius: 14px;
  background: ${white};
  margin-top: 8px;
  width: 100%;
  padding: 16px;
`;

const EventBasicInfo = styled.div`
  width: 100%;
`;

const EventContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const EventLocation = styled.div``;

const EventNameAndSelect = styled.div`
  display: flex;
  flex-direction: row;
`;

const EventName = styled.div`
  font-size: 20px;
  width: calc(100% - 84px);
  color: ${black};
  font-weight: bold;
  margin-bottom: 2px;
`;

const EventDateAndTime = styled.div`
  color: ${darkGray};
`;

const EventPostedBy = styled.div`
  color: ${darkGray};
  font-size: 15px;
`;

const EventAddressLink = styled.div`
  padding: 8px 16px 32px 16px;
`;

const DeleteButton = styled.button`
  text-decoration: none;
  color: #b0243c;
  background: transparent;
  border: 0;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  padding: 16px 24px !important;
  cursor: pointer;
`;

// const AdminOptions = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: space-around;
// `;

const ShareButton = styled.button`
  text-decoration: none;
  color: ${darkBlue};
  background: transparent;
  border: 0;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  padding: 16px 24px !important;
  cursor: pointer;
`;

const EventOptions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: ${black};
  border-radius: 14px;
  background: ${white};
  margin-top: 24px;
  width: 100%;
  margin-bottom: 16px;
`;

const Attendance = styled.button`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: transparent;
  outline: 0;
  border: 0;
`;

const AttendanceDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const AttendanceHeader = styled.div``;

const FeaturedImageWrapper = styled.div`
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 16px;

  img {
    width: 100%;
  }
`;

interface SelectWrapperProps {
  isSelected: boolean;
}

const svgPrefix = `"data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='`;
const svgSuffix = `' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>"`;

const SelectWrapper = styled.div<SelectWrapperProps>`
  select {
    width: 84px !important;
    height: unset !important;
    padding: 4px 8px !important;
    border-radius: 16px !important;
    color: ${(props) => (props.isSelected ? white : darkGray)};

    background: url(${svgPrefix}${(props) => (props.isSelected ? 'white' : 'gray')}${svgSuffix})
      ${(props) => (props.isSelected ? lightBlue : gray)} no-repeat;

    background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding-right: 2rem !important;
  }
`;

const PopoverMenu = styled.div`
  display: flex;
  flex-direction: column;

  button {
    :not(:last-child) {
      border-bottom: 1px solid ${lightGray};
    }
  }
`;

const CancelledContainer = styled.div`
  width: 100%;
  height: 80px;
  background: ${darkRed};
  color: ${white};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
`;

const OpacityWrapper = styled.div`
  opacity: 0.7;
  pointer-events: none;
`;

function Event(): ReactElement {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();
  const { eventId = '' } = params;

  const currentUser = useContext<CurrentUser | null>(CurrentUserContext);
  // const showAdminOptions = currentUser?.isAdmin;
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  const [isSignUpPopoverOpen, setIsSignUpPopoverOpen] = useState<boolean>(false);
  const [isDeleteConfirmationPopoverOpen, setIsDeleteConfirmationPopoverOpen] = useState<boolean>(false);
  const [isFlagPopoverOpen, setIsFlagPopoverOpen] = useState<boolean>(false);

  const { error, data, loading } = useEventQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        eventId,
      },
    },
  });

  useTrackOnData('Event Viewed', {}, data);

  const [createEventIntent] = useCreateEventIntentMutation();

  const [createEventFlag] = useCreateEventFlagMutation({
    onCompleted: () => {
      toast.success('Event flagged');
      navigate(-1);
    },
    refetchQueries: () => ['EventsByCurrentUser', 'EventsByPlace', 'PublicEventsByLocation'],
    variables: {
      input: {
        eventId,
      },
    },
  });

  const [deleteEvent] = useDeleteEventMutation({
    onCompleted: () => {
      toast.success('Event cancelled');
      navigate(-1);
    },
    refetchQueries: () => ['EventsByCurrentUser', 'EventsByPlace', 'PublicEventsByLocation'],
    variables: {
      input: {
        eventId,
      },
    },
  });

  const event = data?.event;

  if (error) return <p>Error :(</p>;

  const eventIsCancelled = event?.deleted;
  const isOwnEvent = event?.createdBy?._id === currentUser?._id;
  const eventHasAssociatedPlace = !!event?.place?._id;
  const currentUserIsAttending = event?.attendance?.currentUserIsAttending;

  const handleDeleteIntentClick = (): void => {
    setIsPopoverOpen(false);
    setIsDeleteConfirmationPopoverOpen(true);
  };

  const handleDeleteClick = (): void => {
    deleteEvent().catch((err) => console.log(err.toString()));
  };

  const handleConfirmationPopoverRequestClose = (): void => {
    setIsDeleteConfirmationPopoverOpen(false);
  };

  const handleAttendanceClick = (): void => {
    if (!currentUser?._id) {
      setIsSignUpPopoverOpen(true);
      return;
    }

    navigate(`/events/${event?._id ?? ''}/attendees`);
  };

  const handleSignUpPopoverRequestClose = (): void => {
    setIsSignUpPopoverOpen(false);
  };

  const handleSignUpClick = (): void => {
    dispatch(onBeginOnboardingFromRoute(`/events/${event?._id ?? ''}`));
    navigate('/login');
  };

  const handleIgnoreClick = (): void => {
    createEventIntent({
      variables: {
        input: {
          eventId,
          isAttending: false,
        },
      },
      refetchQueries: ['Event'],
    }).catch((err) => console.log(err.toString()));
  };

  const handleAttendClick = (): void => {
    createEventIntent({
      variables: {
        input: {
          eventId,
          isAttending: true,
        },
      },
      refetchQueries: ['Event'],
    }).catch((err) => console.log(err.toString()));
  };

  const handleShareClick = (): void => {
    setIsPopoverOpen(false);
    if (navigator.share) {
      navigator
        .share({
          title: event?.name,
          text: 'Check out this event',
          url: `${REACT_APP_CLIENT_URL}/events/${eventId || ''}`,
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    }
  };

  const handleFlagClick = (): void => {
    setIsPopoverOpen(false);
    setIsFlagPopoverOpen(true);
  };

  const handleFlagConfirmClick = (): void => {
    createEventFlag();
  };

  const handleFlagPopoverRequestClose = (): void => {
    setIsFlagPopoverOpen(false);
  };

  if (!event) {
    return (
      <Container>
        <Header>
          <BackButton />
          <HeaderTitle>{'View Event'}</HeaderTitle>
        </Header>
        {!loading && <Content>{'Unable to find this event'}</Content>}
      </Container>
    );
  }

  // ToDo: I hate this lol
  const maxCount = (event?.attendance?.attendingUsers?.length || 0) > 3 ? 4 : Math.min(event?.attendance?.attendingUsers?.length || 1, 3);

  if (eventIsCancelled) {
    return (
      <Container>
        <Header>
          <BackButton fallbackTo={'/events'} />
          <HeaderTitle>{'View Event'}</HeaderTitle>
          <div></div>
        </Header>
        <Content backgroundColor={offWhite}>
          <CancelledContainer>{'This event has been cancelled.'}</CancelledContainer>
          <OpacityWrapper>
            <EventContainer>
              <EventContent>
                {event?.featuredImage?.path && (
                  <FeaturedImageWrapper>
                    <Image path={event.featuredImage.path} />
                  </FeaturedImageWrapper>
                )}
                <EventAvatarAndInfo>
                  <EventBasicInfoAndActions>
                    <EventBasicInfo>
                      <EventNameAndSelect>
                        <EventName>{event?.name}</EventName>
                        <SelectWrapper isSelected={!!currentUserIsAttending}>
                          <InputWrapper>
                            <select
                              value={currentUserIsAttending ? 'true' : 'false'}
                              onChange={(e) => {
                                e.target.value === 'true' ? handleAttendClick() : handleIgnoreClick();
                              }}
                            >
                              <option value={'false'}>{'RSVP'}</option>
                              <option value={'true'}>{'Going'}</option>
                            </select>
                          </InputWrapper>
                        </SelectWrapper>
                      </EventNameAndSelect>

                      {eventHasAssociatedPlace && (
                        <BlueLink to={`/places/${event?.place?.vanityUrl ?? ''}`}>
                          <EventLocation>{event?.locationName ?? event?.formattedAddress?.split(',')?.[0]}</EventLocation>
                        </BlueLink>
                      )}
                      {!eventHasAssociatedPlace && (
                        <BlueAnchor
                          href={`https://www.google.com/maps/search/?api=1&query=${event?.locationName ?? event?.formattedAddress ?? ''}`}
                          target={'_blank'}
                        >
                          {event?.locationName ?? event?.formattedAddress?.split(',')?.[0]}
                        </BlueAnchor>
                      )}

                      <EventDateAndTime>{moment(event?.start).format('MMM D, YYYY hh:mm a')}</EventDateAndTime>
                      <EventPostedBy>{`from ${event?.createdBy?.firstName ?? ''}`}</EventPostedBy>
                    </EventBasicInfo>

                    <ContentFormatting style={{ marginRight: '-16px', marginLeft: '-16px', marginBottom: '-16px' }}>
                      <Interweave content={event?.description} />
                    </ContentFormatting>
                  </EventBasicInfoAndActions>
                </EventAvatarAndInfo>
              </EventContent>

              <EventOptions>
                {!!event?.attendance?.attendingUserCount && currentUser?._id && (
                  <Attendance onClick={handleAttendanceClick}>
                    <Body style={{ marginBottom: 0 }}>{"See who's going"}</Body>
                    <AttendanceDetails>
                      <MultiAvatar users={event?.attendance?.attendingUsers} maxCount={3} />
                      <IconWrapper color={darkGray} size={20}>
                        <span className={'material-symbols-rounded'}>{'chevron_right'}</span>
                      </IconWrapper>
                    </AttendanceDetails>
                  </Attendance>
                )}

                {!currentUser?._id && (
                  <>
                    <Attendance onClick={handleAttendanceClick}>
                      <AttendanceHeader style={{ color: lightBlue }}>{'RSVP'}</AttendanceHeader>
                      <AttendanceDetails>
                        <IconWrapper color={darkGray} size={20}>
                          <span className={'material-symbols-rounded'}>{'chevron_right'}</span>
                        </IconWrapper>
                      </AttendanceDetails>
                    </Attendance>

                    <Attendance onClick={handleAttendanceClick}>
                      <AttendanceHeader>{`Who's going`}</AttendanceHeader>
                      <AttendanceDetails>
                        <IconWrapper color={darkGray} size={20}>
                          <span className={'material-symbols-rounded'}>{'chevron_right'}</span>
                        </IconWrapper>
                      </AttendanceDetails>
                    </Attendance>
                  </>
                )}
              </EventOptions>
            </EventContainer>

            <GoogleMap
              mapContainerStyle={{
                width: 'calc(100% - 32px)',
                height: 'calc(80vw - 32px)',
                margin: '0 16px',
                borderRadius: '14px',
              }}
              center={{
                lat: event?.location?.coordinates?.[1],
                lng: event?.location?.coordinates?.[0],
              }}
              options={{
                gestureHandling: 'cooperative',
              }}
              zoom={15}
            >
              <Marker position={{ lat: event?.location?.coordinates?.[1], lng: event?.location?.coordinates?.[0] }} />
            </GoogleMap>
            <EventAddressLink>
              <BlueAnchor
                href={`https://www.google.com/maps/search/?api=1&query=${event?.locationName ?? event?.formattedAddress ?? ''}`}
                target={'_blank'}
              >
                {'Open in Google Maps'}
              </BlueAnchor>
            </EventAddressLink>
          </OpacityWrapper>
        </Content>
      </Container>
    );
  }
  return (
    <Container>
      <Header>
        <BackButton fallbackTo={'/events'} />
        <HeaderTitle>{'View Event'}</HeaderTitle>
        <Popover
          isOpen={isPopoverOpen}
          positions={['bottom', 'left', 'right']}
          content={
            <PopoverMenu>
              <ShareButton onClick={handleShareClick}>{'Share'}</ShareButton>
              <ShareButton onClick={handleFlagClick}>{'Report'}</ShareButton>
              {!!event.createdBy?._id && isOwnEvent && <DeleteButton onClick={handleDeleteIntentClick}>{'Cancel event'}</DeleteButton>}
            </PopoverMenu>
          }
          containerStyle={{
            background: white,
            zIndex: '1',
            border: `1px solid ${lightGray}`,
            borderRadius: '2px',
            boxShadow: '1px 3px 6px rgba(0, 0, 0, 0.05)',
          }}
        >
          <div onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
            <IconWrapper size={28} color={lightBlue}>
              <span className={'material-symbols-outlined'}>{'more_horiz'}</span>
            </IconWrapper>
          </div>
        </Popover>

        {/* {showAdminOptions && (
          <AdminOptions>
            {!!event.createdBy?._id && !isOwnEvent && currentUser._id && (
              <>
                <BlockButton userId={event.createdBy._id} />
                &nbsp;|&nbsp;
              </>
            )}
            <DeleteButton onClick={handleDeleteIntentClick}>{'Delete'}</DeleteButton>
          </AdminOptions>
        )}
        {!showAdminOptions && currentUser?._id && (
          <>
            {!!event.createdBy?._id && !isOwnEvent && <BlockButton userId={event.createdBy._id} />}
            {!!event.createdBy?._id && isOwnEvent && <DeleteButton onClick={handleDeleteIntentClick}>{'Delete'}</DeleteButton>}
          </>
        )} */}
      </Header>
      <Content backgroundColor={offWhite}>
        <EventContainer>
          <EventContent>
            {event?.featuredImage?.path && (
              <FeaturedImageWrapper>
                <Image path={event.featuredImage.path} />
              </FeaturedImageWrapper>
            )}
            <EventAvatarAndInfo>
              <EventBasicInfoAndActions>
                <EventBasicInfo>
                  <EventNameAndSelect>
                    <EventName>{event?.name}</EventName>
                    <SelectWrapper isSelected={!!currentUserIsAttending}>
                      <InputWrapper>
                        <select
                          value={currentUserIsAttending ? 'true' : 'false'}
                          onChange={(e) => {
                            e.target.value === 'true' ? handleAttendClick() : handleIgnoreClick();
                          }}
                        >
                          <option value={'false'}>{'RSVP'}</option>
                          <option value={'true'}>{'Going'}</option>
                        </select>
                      </InputWrapper>
                    </SelectWrapper>
                  </EventNameAndSelect>

                  {eventHasAssociatedPlace && (
                    <BlueLink to={`/places/${event?.place?.vanityUrl ?? ''}`}>
                      <EventLocation>{event?.locationName ?? event?.formattedAddress?.split(',')?.[0]}</EventLocation>
                    </BlueLink>
                  )}
                  {!eventHasAssociatedPlace && (
                    <BlueAnchor
                      href={`https://www.google.com/maps/search/?api=1&query=${event?.locationName ?? event?.formattedAddress ?? ''}`}
                      target={'_blank'}
                    >
                      {event?.locationName ?? event?.formattedAddress?.split(',')?.[0]}
                    </BlueAnchor>
                  )}

                  <EventDateAndTime>{moment(event?.start).format('MMM D, YYYY hh:mm a')}</EventDateAndTime>
                  <EventPostedBy>{`from ${event?.createdBy?.firstName ?? ''}`}</EventPostedBy>
                </EventBasicInfo>

                <ContentFormatting style={{ marginRight: '-16px', marginLeft: '-16px', marginBottom: '-16px' }}>
                  <Interweave content={event?.description} />
                </ContentFormatting>
              </EventBasicInfoAndActions>
            </EventAvatarAndInfo>
          </EventContent>

          <EventOptions>
            {!!event?.attendance?.attendingUserCount && currentUser?._id && (
              <Attendance onClick={handleAttendanceClick}>
                <Body style={{ marginBottom: 0 }}>{"See who's going"}</Body>
                <AttendanceDetails>
                  <MultiAvatar users={event?.attendance?.attendingUsers} maxCount={3} />
                  <IconWrapper color={darkGray} size={20}>
                    <span className={'material-symbols-rounded'}>{'chevron_right'}</span>
                  </IconWrapper>
                </AttendanceDetails>
              </Attendance>
            )}

            {!currentUser?._id && (
              <>
                <Attendance onClick={handleAttendanceClick}>
                  <AttendanceHeader style={{ color: lightBlue }}>{'RSVP'}</AttendanceHeader>
                  <AttendanceDetails>
                    <IconWrapper color={darkGray} size={20}>
                      <span className={'material-symbols-rounded'}>{'chevron_right'}</span>
                    </IconWrapper>
                  </AttendanceDetails>
                </Attendance>

                <Attendance onClick={handleAttendanceClick}>
                  <AttendanceHeader>{`Who's going`}</AttendanceHeader>
                  <AttendanceDetails>
                    <IconWrapper color={darkGray} size={20}>
                      <span className={'material-symbols-rounded'}>{'chevron_right'}</span>
                    </IconWrapper>
                  </AttendanceDetails>
                </Attendance>
              </>
            )}
          </EventOptions>
        </EventContainer>

        <GoogleMap
          mapContainerStyle={{
            width: 'calc(100% - 32px)',
            height: 'calc(80vw - 32px)',
            margin: '0 16px',
            borderRadius: '14px',
          }}
          center={{
            lat: event?.location?.coordinates?.[1],
            lng: event?.location?.coordinates?.[0],
          }}
          options={{
            gestureHandling: 'cooperative',
          }}
          zoom={15}
        >
          <Marker position={{ lat: event?.location?.coordinates?.[1], lng: event?.location?.coordinates?.[0] }} />
        </GoogleMap>
        <EventAddressLink>
          <BlueAnchor
            href={`https://www.google.com/maps/search/?api=1&query=${event?.locationName ?? event?.formattedAddress ?? ''}`}
            target={'_blank'}
          >
            {'Open in Google Maps'}
          </BlueAnchor>
        </EventAddressLink>
      </Content>

      <SimplePopover
        bodyText={'We’ll cancel this event. This action cannot be undone.'}
        buttonBackgroundColor={darkRed}
        buttonText={'Cancel it'}
        contentLabel={'Cancel this event?'}
        emoji={'🗑'}
        headerText={'Cancel this event?'}
        isOpen={isDeleteConfirmationPopoverOpen}
        onButtonClick={handleDeleteClick}
        onRequestClose={handleConfirmationPopoverRequestClose}
      />

      <SimplePopover
        bodyText={'Homepost helps you connect with your community. Please sign in to use this feature.'}
        buttonBackgroundColor={darkBlue}
        buttonText={'Sign Up or Log In'}
        contentLabel={'Account required'}
        emoji={'😎'}
        headerText={'Account required'}
        isOpen={isSignUpPopoverOpen}
        onButtonClick={handleSignUpClick}
        onRequestClose={handleSignUpPopoverRequestClose}
      />

      <SimplePopover
        bodyText={
          'If this event contains offensive or inappropriate content, you can flag it for review. This action will also remove it from your view.'
        }
        buttonBackgroundColor={darkRed}
        buttonText={'Flag this event'}
        contentLabel={'Flag this event'}
        emoji={'🚨'}
        headerText={'Flag this event'}
        isOpen={isFlagPopoverOpen}
        onButtonClick={handleFlagConfirmClick}
        onRequestClose={handleFlagPopoverRequestClose}
      />

      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Event: ${event?.name}`}</title>
      </Helmet>
    </Container>
  );
}

export default Event;
