import { ReactElement } from 'react';
import styled from 'styled-components';
import { white } from 'styles/colors';

import Image from '../Image';

export interface ImageWrapperProps {
  background?: string;
  size: number;
}

const ImageWrapper = styled.div<ImageWrapperProps>`
  min-width: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.background ? props.background : white)};
  opacity: 1;
  line-height: 0;
  color: white;

  img {
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
  }
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

export interface AvatarProps {
  longText?: string;
  size?: number;
  path?: string | null;
  text?: string;
}

const colors = ['#00AA55', '#009FD4', '#B381B3', '#939393', '#E3BC00', '#D47500', '#DC2A2A'];

// https://marcoslooten.com/blog/creating-avatars-with-colors-using-the-modulus/
function numberFromText(text: string): number {
  // numberFromText("AA");
  const charCodes = [text, text]
    .map((char: string) => char.charCodeAt(0)) // => [65, 65]
    .join(''); // => "6565"
  return parseInt(charCodes, 10);
}

function Avatar({ longText, path, size = 40, text }: AvatarProps): ReactElement {
  const textToShow = longText || (text?.length ? text.slice(0, 1) : '');
  const background = textToShow && !path ? colors[numberFromText(textToShow) % colors.length] : '';

  return (
    <ImageWrapper size={size} background={background}>
      {path && <Image path={path} />}
      {textToShow && !path && <Text>{textToShow}</Text>}
    </ImageWrapper>
  );
}

export default Avatar;
