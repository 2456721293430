import { createSlice } from '@reduxjs/toolkit';

export interface LocationCoordinates {
  lat: number;
  lng: number;
}

export interface Geolocation {
  coordinates: LocationCoordinates;
}

export interface GeolocationSliceState {
  geolocation?: Geolocation;
}

const initialState: GeolocationSliceState = {
  geolocation: undefined,
};

const geolocationSlice = createSlice({
  name: 'geolocation',
  initialState,
  reducers: {
    onGeolocationComplete: (state, action) => {
      state.geolocation = action.payload;
    },
    resetGeolocation: (state) => {
      state.geolocation = undefined;
    },
  },
});

export const { resetGeolocation, onGeolocationComplete } = geolocationSlice.actions;

export default geolocationSlice.reducer;
