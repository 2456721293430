import { useEffect, useRef } from 'react';
import { camelCase, mapKeys } from 'lodash';

import { black, white } from 'styles/colors';

export interface UseCloudinaryWidgetProps {
  cropToSquare?: boolean;
  cropToWide?: boolean;
  folder: string;
  onChange: (value: any) => void;
}

export interface UseCloudinaryWidgetReturn {
  openCloudinaryWidget: (url?: string) => () => void;
}

function useCloudinaryWidget({
  folder,
  onChange,
  cropToSquare = false,
  cropToWide = false,
}: UseCloudinaryWidgetProps): UseCloudinaryWidgetReturn {
  const widgetRef = useRef<any>();

  const requiresCrop = cropToSquare || cropToWide;

  let croppingAspectRatio: number | undefined;

  if (requiresCrop) {
    if (cropToSquare) {
      croppingAspectRatio = 1;
    }
    if (cropToWide) {
      croppingAspectRatio = 16 / 9;
    }
  }

  useEffect(() => {
    widgetRef.current = window.cloudinary.createUploadWidget(
      {
        cloudName: process.env.REACT_APP_CLOUDINARY_KEY,
        uploadPreset: 'hy6otml9',
        sources: ['local'],
        showAdvancedOptions: false,
        cropping: true,
        showSkipCropButton: !requiresCrop,
        croppingAspectRatio,
        multiple: false,
        defaultSource: 'local',
        folder,
        styles: {
          palette: {
            window: white,
            windowBorder: '#90A0B3',
            tabIcon: '#0078FF',
            menuIcons: '#5A616A',
            textDark: black,
            textLight: white,
            link: '#0078FF',
            action: '#FF620C',
            inactiveTabIcon: '#0E2F5A',
            error: '#F44235',
            inProgress: '#0078FF',
            complete: '#20B832',
            sourceBg: '#E4EBF1',
          },
        },
      },
      (err: Error, info: any) => {
        if (!err) {
          if (info?.event === 'success') {
            const camelCasedResponse = mapKeys(info?.info, function (value, key) {
              if (key === 'id') return 'cloudinaryId';
              if (key === 'public_id') return 'cloudinaryPublicID';

              return camelCase(key);
            });

            delete camelCasedResponse.createdAt;
            delete camelCasedResponse.originalExtension;
            onChange(camelCasedResponse);
          }
        }
      },
    );
  }, []);

  return {
    openCloudinaryWidget: (url?: string) => (!url ? widgetRef.current?.open() : widgetRef.current?.open(null, { files: [url] })),
  };
}

export default useCloudinaryWidget;
