import ReactDOM from 'react-dom/client';
import { ApolloClient, ApolloProvider, InMemoryCache, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Cloudinary } from '@cloudinary/url-gen';
import { Provider as ReduxProvider } from 'react-redux';
import moment from 'moment';
import 'material-symbols';
import OneSignal from 'onesignal-cordova-plugin';

import App from './App';

import store from './store';

import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const { REACT_APP_GRAPHQL_ENDPOINT, REACT_APP_ONE_SIGNAL_APP_ID = '' } = process.env;

declare global {
  interface Window {
    cordova: any;
  }
}

// Call this function when your app starts
function OneSignalInit(): void {
  // Uncomment to set OneSignal device logging to VERBOSE
  // OneSignal.Debug.setLogLevel(6);

  // Uncomment to set OneSignal visual logging to VERBOSE
  // OneSignal.Debug.setAlertLevel(6);

  OneSignal.initialize(REACT_APP_ONE_SIGNAL_APP_ID);
}

if (window.cordova) {
  OneSignalInit();
}

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s',
    s: '1m',
    ss: '1m',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    w: '1w',
    ww: '%dw',
    M: '1mo',
    MM: '%dmo',
    y: '1y',
    yy: '%dy',
  },
});

const link = new HttpLink({ uri: REACT_APP_GRAPHQL_ENDPOINT });

export const cld = new Cloudinary({
  cloud: {
    cloudName: process.env.REACT_APP_CLOUDINARY_KEY,
  },
});

const setAuthorizationLink = setContext((request, previousContext) => ({
  headers: {
    ...previousContext.headers,
    authorization: `Bearer ${localStorage.getItem('auth_token') || ''}`,
  },
}));

export const client = new ApolloClient({
  link: setAuthorizationLink.concat(link),
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

function renderApp(location?: GeolocationPosition): void {
  root.render(
    <ApolloProvider client={client}>
      <ReduxProvider store={store}>
        <App geoLocation={location} />
      </ReduxProvider>
    </ApolloProvider>,
  );
}

function init(): void {
  renderApp();
}

init();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

serviceWorkerRegistration.register();

window.addEventListener('beforeinstallprompt', function (event) {
  // Don't display the standard one
  event.preventDefault();

  // Save the event to use it later
  window.promptEvent = event;
});
