import styled from 'styled-components';

const ContentFormatting = styled.div`
  margin-top: 16px;

  padding: 0 16px;

  p {
    margin-bottom: 16px;
  }

  ul,
  ol {
    margin-left: 32px;
  }

  li {
    margin-bottom: 12px;
  }
`;

export default ContentFormatting;
