import { createSlice } from '@reduxjs/toolkit';

const defaultState = {
  eventsScrollDepth: 0,
  groupsScrollDepth: 0,
};

const lastClickedListItemSlice = createSlice({
  name: 'lastClickedListItem',
  initialState: defaultState,
  reducers: {
    onScroll: (state, action) => {
      if (action.payload.target === 'events') {
        state.eventsScrollDepth = action.payload.scrollDepth;
      }
      if (action.payload.target === 'groups') {
        state.groupsScrollDepth = action.payload.scrollDepth;
      }
    },
    reset: (state) => {
      state = defaultState;
    },
  },
});

export const { onScroll, reset } = lastClickedListItemSlice.actions;

export default lastClickedListItemSlice.reducer;
