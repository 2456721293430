import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  _Any: any;
  _FieldSet: any;
};

export type AdminDashboard = {
  __typename?: 'AdminDashboard';
  counts?: Maybe<AdminDashboardCounts>;
  users?: Maybe<Array<CurrentUser>>;
};

export type AdminDashboardCount = {
  __typename?: 'AdminDashboardCount';
  blockCount: Scalars['Int'];
  eventCount: Scalars['Int'];
  eventViewCount: Scalars['Int'];
  postCount: Scalars['Int'];
  postViewCount: Scalars['Int'];
  userCount: Scalars['Int'];
};

export type AdminDashboardCounts = {
  __typename?: 'AdminDashboardCounts';
  lastFourteen?: Maybe<AdminDashboardCount>;
  lastSeven?: Maybe<AdminDashboardCount>;
  lastTwentyEight?: Maybe<AdminDashboardCount>;
  total?: Maybe<AdminDashboardCount>;
};

export type AdminDashboardResponse = {
  __typename?: 'AdminDashboardResponse';
  adminDashboard?: Maybe<AdminDashboard>;
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type AdminDeleteUserInput = {
  userId: Scalars['String'];
};

export type AdminDeleteUserResponse = {
  __typename?: 'AdminDeleteUserResponse';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type Block = {
  __typename?: 'Block';
  _id: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  createdById?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ConnectedUser = {
  __typename?: 'ConnectedUser';
  _id: Scalars['String'];
  aboutMe?: Maybe<Scalars['String']>;
  connected?: Maybe<Scalars['Boolean']>;
  connectionPending?: Maybe<Scalars['Boolean']>;
  connectionRequestPending?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Image>;
  profileImageId?: Maybe<Scalars['String']>;
};

export type ConnectionIntent = {
  __typename?: 'ConnectionIntent';
  _id: Scalars['String'];
  connectedUserId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdById: Scalars['String'];
  intent: Scalars['String'];
};

export type CreateBlockInput = {
  userId: Scalars['String'];
};

export type CreateBlockResponse = {
  __typename?: 'CreateBlockResponse';
  block?: Maybe<Block>;
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type CreateConnectionIntentInput = {
  connectedUserId: Scalars['String'];
  intent: Scalars['String'];
};

export type CreateConnectionIntentResponse = {
  __typename?: 'CreateConnectionIntentResponse';
  connectionIntent?: Maybe<ConnectionIntent>;
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type CreateEventFlagInput = {
  eventId: Scalars['String'];
};

export type CreateEventFlagResponse = {
  __typename?: 'CreateEventFlagResponse';
  error?: Maybe<Scalars['String']>;
  eventFlag?: Maybe<EventFlag>;
  success: Scalars['Boolean'];
};

export type CreateEventInput = {
  description: Scalars['String'];
  featuredImageId?: InputMaybe<Scalars['String']>;
  invitedGroupIds?: InputMaybe<Array<Scalars['String']>>;
  invitedUserIds?: InputMaybe<Array<Scalars['String']>>;
  location: LocationInput;
  name: Scalars['String'];
  placeId?: InputMaybe<Scalars['String']>;
  requireLinkToView?: InputMaybe<Scalars['Boolean']>;
  start: Scalars['DateTime'];
  timezone?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  visibleWithLink: Scalars['Boolean'];
};

export type CreateEventIntentInput = {
  eventId: Scalars['String'];
  isAttending: Scalars['Boolean'];
};

export type CreateEventIntentResponse = {
  __typename?: 'CreateEventIntentResponse';
  error?: Maybe<Scalars['String']>;
  eventIntent?: Maybe<EventIntent>;
  success: Scalars['Boolean'];
};

export type CreateEventResponse = {
  __typename?: 'CreateEventResponse';
  error?: Maybe<Scalars['String']>;
  event?: Maybe<Event>;
  success: Scalars['Boolean'];
};

export type CreateGroupInput = {
  invitedUserIds?: InputMaybe<Array<Scalars['String']>>;
  name: Scalars['String'];
};

export type CreateGroupResponse = {
  __typename?: 'CreateGroupResponse';
  error?: Maybe<Scalars['String']>;
  group?: Maybe<Group>;
  success: Scalars['Boolean'];
};

export type CreateImageInput = {
  accessMode?: InputMaybe<Scalars['String']>;
  assetId?: InputMaybe<Scalars['String']>;
  batchId?: InputMaybe<Scalars['String']>;
  bytes?: InputMaybe<Scalars['Int']>;
  cloudinaryId?: InputMaybe<Scalars['String']>;
  cloudinaryPublicID?: InputMaybe<Scalars['String']>;
  coordinates?: InputMaybe<ImageCoordinatesInput>;
  etag?: InputMaybe<Scalars['String']>;
  folder?: InputMaybe<Scalars['String']>;
  format?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  originalFilename?: InputMaybe<Scalars['String']>;
  path?: InputMaybe<Scalars['String']>;
  placeholder?: InputMaybe<Scalars['Boolean']>;
  resourceType?: InputMaybe<Scalars['String']>;
  secureUrl?: InputMaybe<Scalars['String']>;
  signature?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
  versionId?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type CreateImageResponse = {
  __typename?: 'CreateImageResponse';
  error?: Maybe<Scalars['String']>;
  image?: Maybe<Image>;
  success: Scalars['Boolean'];
};

export type CreatePlaceInput = {
  description?: InputMaybe<Scalars['String']>;
  hours?: InputMaybe<Scalars['String']>;
  location: LocationInput;
  name: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  profileImageId?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type CreatePlaceResponse = {
  __typename?: 'CreatePlaceResponse';
  error?: Maybe<Scalars['String']>;
  place?: Maybe<PrivilegedPlace>;
  success: Scalars['Boolean'];
};

export type CreatePlatformInvitationResponse = {
  __typename?: 'CreatePlatformInvitationResponse';
  error?: Maybe<Scalars['String']>;
  platformInvitation?: Maybe<PlatformInvitation>;
  success: Scalars['Boolean'];
};

export type CreatePostInput = {
  body: Scalars['String'];
  replyTo?: InputMaybe<Scalars['String']>;
  replyType?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type CreatePostResponse = {
  __typename?: 'CreatePostResponse';
  error?: Maybe<Scalars['String']>;
  post?: Maybe<Post>;
  success: Scalars['Boolean'];
};

export type CurrentUser = {
  __typename?: 'CurrentUser';
  _id: Scalars['String'];
  aboutMe?: Maybe<Scalars['String']>;
  connectionCount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  formattedAddress?: Maybe<Scalars['String']>;
  hasCreatedAConnectionIntent: Scalars['Boolean'];
  hasPotentialConnections?: Maybe<Scalars['Boolean']>;
  hasUnseenNotifications?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  lastVisitedNotificationsAt?: Maybe<Scalars['DateTime']>;
  phoneNumber?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Image>;
  profileImageId?: Maybe<Scalars['String']>;
};

export type DeleteEventInput = {
  eventId: Scalars['String'];
};

export type DeleteEventResponse = {
  __typename?: 'DeleteEventResponse';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type DeletePlaceInput = {
  placeId: Scalars['String'];
};

export type DeletePlaceResponse = {
  __typename?: 'DeletePlaceResponse';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type DeletePostInput = {
  postId: Scalars['String'];
};

export type DeletePostResponse = {
  __typename?: 'DeletePostResponse';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type DeleteUserInput = {
  userId: Scalars['String'];
};

export type DeleteUserResponse = {
  __typename?: 'DeleteUserResponse';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type Event = {
  __typename?: 'Event';
  _id: Scalars['String'];
  attendance?: Maybe<EventAttendance>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<PublicUser>;
  createdById?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  featuredImage?: Maybe<Image>;
  featuredImageId?: Maybe<Scalars['String']>;
  formattedAddress?: Maybe<Scalars['String']>;
  location: Location;
  locationName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  place?: Maybe<PublicPlace>;
  placeId?: Maybe<Scalars['String']>;
  start: Scalars['DateTime'];
  timezone?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  viewed?: Maybe<Scalars['Boolean']>;
};

export type EventAttendance = {
  __typename?: 'EventAttendance';
  attendingUserCount: Scalars['Int'];
  attendingUsers: Array<PublicUser>;
  currentUserIsAttending: Scalars['Boolean'];
};

export type EventFlag = {
  __typename?: 'EventFlag';
  _id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdById: Scalars['String'];
};

export type EventInput = {
  eventId: Scalars['String'];
};

export type EventIntent = {
  __typename?: 'EventIntent';
  _id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdById: Scalars['String'];
  isAttending: Scalars['Boolean'];
};

export type EventsByPlaceInput = {
  placeId: Scalars['String'];
};

export type GenerateAndSendPassCodeInput = {
  phoneNumber: Scalars['String'];
};

export type GenerateAndSendPassCodeResponse = {
  __typename?: 'GenerateAndSendPassCodeResponse';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  token?: Maybe<Scalars['String']>;
};

export type GooglePlaceDetails = {
  __typename?: 'GooglePlaceDetails';
  formattedPhoneNumber?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  openingHoursWeekdayText?: Maybe<Array<Scalars['String']>>;
};

export type GooglePlaceDetailsInput = {
  googlePlaceId: Scalars['String'];
};

export type Group = {
  __typename?: 'Group';
  _id: Scalars['String'];
  createdBy?: Maybe<PublicUser>;
  invitedUsers?: Maybe<Array<PublicUser>>;
  members?: Maybe<Array<PublicUser>>;
  name: Scalars['String'];
};

export type GroupInput = {
  groupId: Scalars['String'];
};

export type Image = {
  __typename?: 'Image';
  _id: Scalars['String'];
  accessMode?: Maybe<Scalars['String']>;
  assetId?: Maybe<Scalars['String']>;
  batchId?: Maybe<Scalars['String']>;
  bytes?: Maybe<Scalars['Int']>;
  cloudinaryId?: Maybe<Scalars['String']>;
  cloudinaryPublicID?: Maybe<Scalars['String']>;
  coordinates?: Maybe<ImageCoordinates>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<PublicUser>;
  etag?: Maybe<Scalars['String']>;
  folder?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  originalFilename?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  placeholder?: Maybe<Scalars['Boolean']>;
  resourceType?: Maybe<Scalars['String']>;
  secureUrl?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
  versionId?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

export type ImageCoordinates = {
  __typename?: 'ImageCoordinates';
  custom?: Maybe<Array<Maybe<Array<Maybe<Scalars['Int']>>>>>;
};

export type ImageCoordinatesInput = {
  custom?: InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars['Int']>>>>>;
};

export type JoinGroupInput = {
  groupId: Scalars['String'];
};

export type JoinGroupResponse = {
  __typename?: 'JoinGroupResponse';
  error?: Maybe<Scalars['String']>;
  group?: Maybe<Group>;
  success: Scalars['Boolean'];
};

export type LeaveGroupInput = {
  groupId: Scalars['String'];
};

export type LeaveGroupResponse = {
  __typename?: 'LeaveGroupResponse';
  error?: Maybe<Scalars['String']>;
  group?: Maybe<Group>;
  success: Scalars['Boolean'];
};

export type Location = {
  __typename?: 'Location';
  coordinates: Array<Scalars['Float']>;
  type: Scalars['String'];
};

export type LocationCoordinatesInput = {
  lat?: InputMaybe<Scalars['Float']>;
  lng?: InputMaybe<Scalars['Float']>;
};

export type LocationInput = {
  coordinates?: InputMaybe<LocationCoordinatesInput>;
  formattedAddress?: InputMaybe<Scalars['String']>;
  locationName?: InputMaybe<Scalars['String']>;
};

export type LogoutResponse = {
  __typename?: 'LogoutResponse';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type Mutation = {
  __typename?: 'Mutation';
  adminDeleteUser: AdminDeleteUserResponse;
  createBlock: CreateBlockResponse;
  createConnectionIntent: CreateConnectionIntentResponse;
  createEvent: CreateEventResponse;
  createEventFlag: CreateEventFlagResponse;
  createEventIntent: CreateEventIntentResponse;
  createGroup: CreateGroupResponse;
  createImage: CreateImageResponse;
  createPlace: CreatePlaceResponse;
  createPlatformInvitation: CreatePlatformInvitationResponse;
  createPost: CreatePostResponse;
  deleteEvent: DeleteEventResponse;
  deletePlace: DeletePlaceResponse;
  deletePost: DeletePostResponse;
  deleteUser: DeleteUserResponse;
  generateAndSendPassCode: GenerateAndSendPassCodeResponse;
  joinGroup: JoinGroupResponse;
  leaveGroup: LeaveGroupResponse;
  logout: LogoutResponse;
  redeemPlatformInvitation: RedeemPlatformInvitationResponse;
  resolvePassCode: ResolvePassCodeResponse;
  updatePlace: UpdatePlaceResponse;
  updateUser?: Maybe<CurrentUser>;
};


export type MutationAdminDeleteUserArgs = {
  input: AdminDeleteUserInput;
};


export type MutationCreateBlockArgs = {
  input: CreateBlockInput;
};


export type MutationCreateConnectionIntentArgs = {
  input: CreateConnectionIntentInput;
};


export type MutationCreateEventArgs = {
  input: CreateEventInput;
};


export type MutationCreateEventFlagArgs = {
  input: CreateEventFlagInput;
};


export type MutationCreateEventIntentArgs = {
  input: CreateEventIntentInput;
};


export type MutationCreateGroupArgs = {
  input: CreateGroupInput;
};


export type MutationCreateImageArgs = {
  input: CreateImageInput;
};


export type MutationCreatePlaceArgs = {
  input: CreatePlaceInput;
};


export type MutationCreatePostArgs = {
  input: CreatePostInput;
};


export type MutationDeleteEventArgs = {
  input: DeleteEventInput;
};


export type MutationDeletePlaceArgs = {
  input: DeletePlaceInput;
};


export type MutationDeletePostArgs = {
  input: DeletePostInput;
};


export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};


export type MutationGenerateAndSendPassCodeArgs = {
  input: GenerateAndSendPassCodeInput;
};


export type MutationJoinGroupArgs = {
  input: JoinGroupInput;
};


export type MutationLeaveGroupArgs = {
  input: LeaveGroupInput;
};


export type MutationRedeemPlatformInvitationArgs = {
  input: RedeemPlatformInvitationInput;
};


export type MutationResolvePassCodeArgs = {
  input: ResolvePassCodeInput;
};


export type MutationUpdatePlaceArgs = {
  input: UpdatePlaceInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type Notification = {
  __typename?: 'Notification';
  _id: Scalars['String'];
  connectedUser?: Maybe<ConnectedUser>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdForId?: Maybe<Scalars['String']>;
  group?: Maybe<PublicGroup>;
  groupId?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
};

export type PlaceInput = {
  vanityUrl: Scalars['String'];
};

export type PlacesBySearchInput = {
  searchTerm: Scalars['String'];
};

export type PlatformInvitation = {
  __typename?: 'PlatformInvitation';
  _id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<PublicUser>;
  createdById: Scalars['String'];
  redeemed?: Maybe<Scalars['Boolean']>;
  redeemedAt?: Maybe<Scalars['DateTime']>;
  redeemedById?: Maybe<Scalars['String']>;
};

export type PlatformInvitationInput = {
  platformInvitationId: Scalars['String'];
};

export type Post = {
  __typename?: 'Post';
  _id: Scalars['String'];
  body: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<PublicUser>;
  createdById?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
  replyType?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  viewed?: Maybe<Scalars['Boolean']>;
};

export type PostInput = {
  postId: Scalars['String'];
};

export type PotentialConnectionsByContactsListInput = {
  emails?: InputMaybe<Array<Scalars['String']>>;
  phoneNumbers?: InputMaybe<Array<Scalars['String']>>;
};

export type PrivilegedPlace = {
  __typename?: 'PrivilegedPlace';
  _id: Scalars['String'];
  createdById: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  formattedAddress: Scalars['String'];
  hours?: Maybe<Scalars['String']>;
  location: Location;
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Image>;
  profileImageId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  vanityUrl: Scalars['String'];
};

export type PublicEventsByLocationInput = {
  coordinates: Array<Scalars['Float']>;
  radiusInMeters?: InputMaybe<Scalars['Int']>;
};

export type PublicGroup = {
  __typename?: 'PublicGroup';
  _id: Scalars['String'];
  createdBy?: Maybe<PublicUser>;
  name: Scalars['String'];
};

export type PublicPlace = {
  __typename?: 'PublicPlace';
  _id: Scalars['String'];
  createdById: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  formattedAddress: Scalars['String'];
  hours?: Maybe<Scalars['String']>;
  location: Location;
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Image>;
  profileImageId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  vanityUrl: Scalars['String'];
};

export type PublicUser = {
  __typename?: 'PublicUser';
  _id: Scalars['String'];
  aboutMe?: Maybe<Scalars['String']>;
  connected?: Maybe<Scalars['Boolean']>;
  connectionPending?: Maybe<Scalars['Boolean']>;
  connectionRequestPending?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Image>;
  profileImageId?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  _service: _Service;
  adminDashboard: AdminDashboardResponse;
  calendarEventsByCurrentUser?: Maybe<Array<Event>>;
  connectedUser?: Maybe<ConnectedUser>;
  connectedUsersByCurrentUser?: Maybe<Array<ConnectedUser>>;
  currentUser?: Maybe<CurrentUser>;
  event?: Maybe<Event>;
  eventsByCurrentUser?: Maybe<Array<Event>>;
  eventsByPlace?: Maybe<Array<Maybe<Event>>>;
  eventsCreatedByCurrentUser?: Maybe<Array<Maybe<Event>>>;
  googlePlaceDetails?: Maybe<GooglePlaceDetails>;
  group?: Maybe<PublicGroup>;
  groupsByCurrentUser?: Maybe<Array<Group>>;
  notificationsByCurrentUser?: Maybe<Array<Maybe<Notification>>>;
  place?: Maybe<PublicPlace>;
  placesBySearch?: Maybe<Array<PublicPlace>>;
  platformInvitation?: Maybe<PlatformInvitation>;
  post?: Maybe<Post>;
  postsByCurrentUser?: Maybe<Array<Maybe<Post>>>;
  postsCreatedByCurrentUser?: Maybe<Array<Maybe<Post>>>;
  potentialConnectedUsersByCurrentUser?: Maybe<Array<PublicUser>>;
  potentialConnectionsByContactsList?: Maybe<Array<PublicUser>>;
  publicEventsByLocation?: Maybe<Array<Maybe<Event>>>;
  user?: Maybe<PublicUser>;
  usersByCurrentUser?: Maybe<Array<Maybe<PublicUser>>>;
};


export type QueryConnectedUserArgs = {
  input: UserInput;
};


export type QueryEventArgs = {
  input: EventInput;
};


export type QueryEventsByPlaceArgs = {
  input?: InputMaybe<EventsByPlaceInput>;
};


export type QueryGooglePlaceDetailsArgs = {
  input?: InputMaybe<GooglePlaceDetailsInput>;
};


export type QueryGroupArgs = {
  input: GroupInput;
};


export type QueryPlaceArgs = {
  input: PlaceInput;
};


export type QueryPlacesBySearchArgs = {
  input: PlacesBySearchInput;
};


export type QueryPlatformInvitationArgs = {
  input: PlatformInvitationInput;
};


export type QueryPostArgs = {
  input: PostInput;
};


export type QueryPotentialConnectionsByContactsListArgs = {
  input: PotentialConnectionsByContactsListInput;
};


export type QueryPublicEventsByLocationArgs = {
  input: PublicEventsByLocationInput;
};


export type QueryUserArgs = {
  input: UserInput;
};

export type RedeemPlatformInvitationInput = {
  platformInvitationId: Scalars['String'];
};

export type RedeemPlatformInvitationResponse = {
  __typename?: 'RedeemPlatformInvitationResponse';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ResolvePassCodeInput = {
  genericInvitationId?: InputMaybe<Scalars['String']>;
  passCode: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type ResolvePassCodeResponse = {
  __typename?: 'ResolvePassCodeResponse';
  error?: Maybe<Scalars['String']>;
  isNewUser?: Maybe<Scalars['Boolean']>;
  success: Scalars['Boolean'];
  token?: Maybe<Scalars['String']>;
};

export type UpdatePlaceInput = {
  description?: InputMaybe<Scalars['String']>;
  hours?: InputMaybe<Scalars['String']>;
  location: LocationInput;
  name: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  placeId: Scalars['String'];
  profileImageId?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type UpdatePlaceResponse = {
  __typename?: 'UpdatePlaceResponse';
  error?: Maybe<Scalars['String']>;
  place?: Maybe<PrivilegedPlace>;
  success: Scalars['Boolean'];
};

export type UpdateUserInput = {
  aboutMe?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  fullName?: InputMaybe<Scalars['String']>;
  lastVisitedNotificationsAt?: InputMaybe<Scalars['DateTime']>;
  profileImageId?: InputMaybe<Scalars['String']>;
};

export type UserInput = {
  userId: Scalars['String'];
};

export type _Service = {
  __typename?: '_Service';
  sdl?: Maybe<Scalars['String']>;
};

export type AdminDeleteUserMutationVariables = Exact<{
  input: AdminDeleteUserInput;
}>;


export type AdminDeleteUserMutation = { __typename?: 'Mutation', adminDeleteUser: { __typename?: 'AdminDeleteUserResponse', error?: string | null, success: boolean } };

export type CreateBlockMutationVariables = Exact<{
  input: CreateBlockInput;
}>;


export type CreateBlockMutation = { __typename?: 'Mutation', createBlock: { __typename?: 'CreateBlockResponse', error?: string | null, success: boolean, block?: { __typename?: 'Block', _id: string, createdAt?: any | null, createdById?: string | null, userId?: string | null } | null } };

export type CreateConnectionIntentMutationVariables = Exact<{
  input: CreateConnectionIntentInput;
}>;


export type CreateConnectionIntentMutation = { __typename?: 'Mutation', createConnectionIntent: { __typename?: 'CreateConnectionIntentResponse', error?: string | null, success: boolean, connectionIntent?: { __typename?: 'ConnectionIntent', _id: string, connectedUserId: string, createdAt: any, createdById: string, intent: string } | null } };

export type CreateEventMutationVariables = Exact<{
  input: CreateEventInput;
}>;


export type CreateEventMutation = { __typename?: 'Mutation', createEvent: { __typename?: 'CreateEventResponse', error?: string | null, success: boolean, event?: { __typename?: 'Event', _id: string, description?: string | null, name: string } | null } };

export type CreateEventFlagMutationVariables = Exact<{
  input: CreateEventFlagInput;
}>;


export type CreateEventFlagMutation = { __typename?: 'Mutation', createEventFlag: { __typename?: 'CreateEventFlagResponse', error?: string | null, success: boolean, eventFlag?: { __typename?: 'EventFlag', _id: string, createdById: string } | null } };

export type CreateEventIntentMutationVariables = Exact<{
  input: CreateEventIntentInput;
}>;


export type CreateEventIntentMutation = { __typename?: 'Mutation', createEventIntent: { __typename?: 'CreateEventIntentResponse', error?: string | null, success: boolean, eventIntent?: { __typename?: 'EventIntent', _id: string, createdById: string, isAttending: boolean } | null } };

export type CreateGroupMutationVariables = Exact<{
  input: CreateGroupInput;
}>;


export type CreateGroupMutation = { __typename?: 'Mutation', createGroup: { __typename?: 'CreateGroupResponse', error?: string | null, success: boolean, group?: { __typename?: 'Group', _id: string } | null } };

export type CreateImageMutationVariables = Exact<{
  input: CreateImageInput;
}>;


export type CreateImageMutation = { __typename?: 'Mutation', createImage: { __typename?: 'CreateImageResponse', error?: string | null, success: boolean, image?: { __typename?: 'Image', _id: string, path?: string | null } | null } };

export type CreatePlatformInvitationMutationVariables = Exact<{ [key: string]: never; }>;


export type CreatePlatformInvitationMutation = { __typename?: 'Mutation', createPlatformInvitation: { __typename?: 'CreatePlatformInvitationResponse', error?: string | null, success: boolean, platformInvitation?: { __typename?: 'PlatformInvitation', _id: string } | null } };

export type DeleteEventMutationVariables = Exact<{
  input: DeleteEventInput;
}>;


export type DeleteEventMutation = { __typename?: 'Mutation', deleteEvent: { __typename?: 'DeleteEventResponse', error?: string | null, success: boolean } };

export type DeleteUserMutationVariables = Exact<{
  input: DeleteUserInput;
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: { __typename?: 'DeleteUserResponse', error?: string | null, success: boolean } };

export type GenerateAndSendPassCodeMutationVariables = Exact<{
  input: GenerateAndSendPassCodeInput;
}>;


export type GenerateAndSendPassCodeMutation = { __typename?: 'Mutation', generateAndSendPassCode: { __typename?: 'GenerateAndSendPassCodeResponse', error?: string | null, success: boolean } };

export type JoinGroupMutationVariables = Exact<{
  input: JoinGroupInput;
}>;


export type JoinGroupMutation = { __typename?: 'Mutation', joinGroup: { __typename?: 'JoinGroupResponse', error?: string | null, success: boolean, group?: { __typename?: 'Group', _id: string, name: string } | null } };

export type LeaveGroupMutationVariables = Exact<{
  input: LeaveGroupInput;
}>;


export type LeaveGroupMutation = { __typename?: 'Mutation', leaveGroup: { __typename?: 'LeaveGroupResponse', error?: string | null, success: boolean, group?: { __typename?: 'Group', _id: string } | null } };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: { __typename?: 'LogoutResponse', success: boolean, error?: string | null } };

export type RedeemPlatformInvitationMutationVariables = Exact<{
  input: RedeemPlatformInvitationInput;
}>;


export type RedeemPlatformInvitationMutation = { __typename?: 'Mutation', redeemPlatformInvitation: { __typename?: 'RedeemPlatformInvitationResponse', error?: string | null, success: boolean } };

export type ResolvePassCodeMutationVariables = Exact<{
  input: ResolvePassCodeInput;
}>;


export type ResolvePassCodeMutation = { __typename?: 'Mutation', resolvePassCode: { __typename?: 'ResolvePassCodeResponse', isNewUser?: boolean | null, success: boolean, token?: string | null, error?: string | null } };

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser?: { __typename?: 'CurrentUser', _id: string, createdAt?: any | null, firstName?: string | null, lastName?: string | null, aboutMe?: string | null, lastVisitedNotificationsAt?: any | null, profileImage?: { __typename?: 'Image', _id: string, path?: string | null } | null } | null };

export type AdminDashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminDashboardQuery = { __typename?: 'Query', adminDashboard: { __typename?: 'AdminDashboardResponse', success: boolean, error?: string | null, adminDashboard?: { __typename?: 'AdminDashboard', counts?: { __typename?: 'AdminDashboardCounts', lastSeven?: { __typename?: 'AdminDashboardCount', blockCount: number, eventCount: number, eventViewCount: number, postCount: number, postViewCount: number, userCount: number } | null, lastFourteen?: { __typename?: 'AdminDashboardCount', blockCount: number, eventCount: number, eventViewCount: number, postCount: number, postViewCount: number, userCount: number } | null, lastTwentyEight?: { __typename?: 'AdminDashboardCount', blockCount: number, eventCount: number, eventViewCount: number, postCount: number, postViewCount: number, userCount: number } | null, total?: { __typename?: 'AdminDashboardCount', blockCount: number, eventCount: number, eventViewCount: number, postCount: number, postViewCount: number, userCount: number } | null } | null, users?: Array<{ __typename?: 'CurrentUser', _id: string, firstName?: string | null, lastName?: string | null, createdAt?: any | null, profileImage?: { __typename?: 'Image', _id: string, path?: string | null } | null }> | null } | null } };

export type CalendarEventsByCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CalendarEventsByCurrentUserQuery = { __typename?: 'Query', calendarEventsByCurrentUser?: Array<{ __typename?: 'Event', _id: string, createdAt?: any | null, description?: string | null, formattedAddress?: string | null, locationName?: string | null, name: string, start: any, viewed?: boolean | null, attendance?: { __typename?: 'EventAttendance', attendingUserCount: number, currentUserIsAttending: boolean, attendingUsers: Array<{ __typename?: 'PublicUser', _id: string, connected?: boolean | null, firstName?: string | null, profileImage?: { __typename?: 'Image', _id: string, path?: string | null } | null }> } | null, createdBy?: { __typename?: 'PublicUser', _id: string, firstName?: string | null, lastName?: string | null, profileImage?: { __typename?: 'Image', _id: string, path?: string | null } | null } | null, featuredImage?: { __typename?: 'Image', _id: string, path?: string | null } | null, location: { __typename?: 'Location', coordinates: Array<number>, type: string }, place?: { __typename?: 'PublicPlace', _id: string, createdById: string, formattedAddress: string, name: string, phoneNumber?: string | null, profileImageId?: string | null, url?: string | null, location: { __typename?: 'Location', coordinates: Array<number>, type: string }, profileImage?: { __typename?: 'Image', _id: string, path?: string | null } | null } | null }> | null };

export type ConnectedUserQueryVariables = Exact<{
  input: UserInput;
}>;


export type ConnectedUserQuery = { __typename?: 'Query', connectedUser?: { __typename?: 'ConnectedUser', _id: string, aboutMe?: string | null, connected?: boolean | null, createdAt?: any | null, firstName?: string | null, lastName?: string | null, profileImageId?: string | null, profileImage?: { __typename?: 'Image', _id: string, path?: string | null } | null } | null };

export type ConnectedUsersByCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type ConnectedUsersByCurrentUserQuery = { __typename?: 'Query', connectedUsersByCurrentUser?: Array<{ __typename?: 'ConnectedUser', _id: string, aboutMe?: string | null, connected?: boolean | null, createdAt?: any | null, firstName?: string | null, lastName?: string | null, profileImageId?: string | null, profileImage?: { __typename?: 'Image', _id: string, path?: string | null } | null }> | null };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'Query', currentUser?: { __typename?: 'CurrentUser', _id: string, aboutMe?: string | null, connectionCount?: number | null, createdAt?: any | null, firstName?: string | null, formattedAddress?: string | null, hasCreatedAConnectionIntent: boolean, hasPotentialConnections?: boolean | null, hasUnseenNotifications?: boolean | null, isAdmin?: boolean | null, lastName?: string | null, lastVisitedNotificationsAt?: any | null, profileImage?: { __typename?: 'Image', _id: string, path?: string | null } | null } | null };

export type EventQueryVariables = Exact<{
  input: EventInput;
}>;


export type EventQuery = { __typename?: 'Query', event?: { __typename?: 'Event', _id: string, createdAt?: any | null, deleted?: boolean | null, description?: string | null, featuredImageId?: string | null, formattedAddress?: string | null, locationName?: string | null, name: string, start: any, viewed?: boolean | null, attendance?: { __typename?: 'EventAttendance', attendingUserCount: number, currentUserIsAttending: boolean, attendingUsers: Array<{ __typename?: 'PublicUser', _id: string, connected?: boolean | null, firstName?: string | null, profileImage?: { __typename?: 'Image', _id: string, path?: string | null } | null }> } | null, createdBy?: { __typename?: 'PublicUser', _id: string, firstName?: string | null, lastName?: string | null, profileImage?: { __typename?: 'Image', _id: string, path?: string | null } | null } | null, featuredImage?: { __typename?: 'Image', _id: string, path?: string | null } | null, location: { __typename?: 'Location', coordinates: Array<number>, type: string }, place?: { __typename?: 'PublicPlace', _id: string, createdById: string, formattedAddress: string, name: string, phoneNumber?: string | null, profileImageId?: string | null, url?: string | null, vanityUrl: string, location: { __typename?: 'Location', coordinates: Array<number>, type: string }, profileImage?: { __typename?: 'Image', _id: string, path?: string | null } | null } | null } | null };

export type EventsByCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type EventsByCurrentUserQuery = { __typename?: 'Query', eventsByCurrentUser?: Array<{ __typename?: 'Event', _id: string, createdAt?: any | null, description?: string | null, formattedAddress?: string | null, locationName?: string | null, name: string, start: any, viewed?: boolean | null, attendance?: { __typename?: 'EventAttendance', attendingUserCount: number, currentUserIsAttending: boolean, attendingUsers: Array<{ __typename?: 'PublicUser', _id: string, connected?: boolean | null, firstName?: string | null, profileImage?: { __typename?: 'Image', _id: string, path?: string | null } | null }> } | null, createdBy?: { __typename?: 'PublicUser', _id: string, firstName?: string | null, lastName?: string | null, profileImage?: { __typename?: 'Image', _id: string, path?: string | null } | null } | null, featuredImage?: { __typename?: 'Image', _id: string, path?: string | null } | null, location: { __typename?: 'Location', coordinates: Array<number>, type: string }, place?: { __typename?: 'PublicPlace', _id: string, createdById: string, formattedAddress: string, name: string, phoneNumber?: string | null, profileImageId?: string | null, url?: string | null, location: { __typename?: 'Location', coordinates: Array<number>, type: string }, profileImage?: { __typename?: 'Image', _id: string, path?: string | null } | null } | null }> | null };

export type EventsByPlaceQueryVariables = Exact<{
  input: EventsByPlaceInput;
}>;


export type EventsByPlaceQuery = { __typename?: 'Query', eventsByPlace?: Array<{ __typename?: 'Event', _id: string, createdAt?: any | null, description?: string | null, formattedAddress?: string | null, locationName?: string | null, name: string, start: any, viewed?: boolean | null, attendance?: { __typename?: 'EventAttendance', attendingUserCount: number, currentUserIsAttending: boolean, attendingUsers: Array<{ __typename?: 'PublicUser', _id: string, connected?: boolean | null, firstName?: string | null, profileImage?: { __typename?: 'Image', _id: string, path?: string | null } | null }> } | null, createdBy?: { __typename?: 'PublicUser', _id: string, firstName?: string | null, lastName?: string | null, profileImage?: { __typename?: 'Image', _id: string, path?: string | null } | null } | null, location: { __typename?: 'Location', coordinates: Array<number>, type: string }, place?: { __typename?: 'PublicPlace', _id: string, createdById: string, formattedAddress: string, name: string, phoneNumber?: string | null, profileImageId?: string | null, url?: string | null, vanityUrl: string, location: { __typename?: 'Location', coordinates: Array<number>, type: string }, profileImage?: { __typename?: 'Image', _id: string, path?: string | null } | null } | null } | null> | null };

export type EventsCreatedByCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type EventsCreatedByCurrentUserQuery = { __typename?: 'Query', eventsCreatedByCurrentUser?: Array<{ __typename?: 'Event', _id: string, createdAt?: any | null, description?: string | null, formattedAddress?: string | null, locationName?: string | null, name: string, start: any, viewed?: boolean | null, createdBy?: { __typename?: 'PublicUser', _id: string, firstName?: string | null, lastName?: string | null, profileImage?: { __typename?: 'Image', _id: string, path?: string | null } | null } | null, location: { __typename?: 'Location', coordinates: Array<number>, type: string }, place?: { __typename?: 'PublicPlace', _id: string, createdById: string, formattedAddress: string, name: string, phoneNumber?: string | null, profileImageId?: string | null, url?: string | null, vanityUrl: string, location: { __typename?: 'Location', coordinates: Array<number>, type: string }, profileImage?: { __typename?: 'Image', _id: string, path?: string | null } | null } | null } | null> | null };

export type GooglePlaceDetailsQueryVariables = Exact<{
  input?: InputMaybe<GooglePlaceDetailsInput>;
}>;


export type GooglePlaceDetailsQuery = { __typename?: 'Query', googlePlaceDetails?: { __typename?: 'GooglePlaceDetails', formattedPhoneNumber?: string | null, icon?: string | null, name?: string | null, openingHoursWeekdayText?: Array<string> | null } | null };

export type GroupQueryVariables = Exact<{
  input: GroupInput;
}>;


export type GroupQuery = { __typename?: 'Query', group?: { __typename?: 'PublicGroup', _id: string, name: string, createdBy?: { __typename?: 'PublicUser', _id: string, firstName?: string | null, profileImage?: { __typename?: 'Image', _id: string, path?: string | null } | null } | null } | null };

export type GroupsByCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GroupsByCurrentUserQuery = { __typename?: 'Query', groupsByCurrentUser?: Array<{ __typename?: 'Group', _id: string, name: string, createdBy?: { __typename?: 'PublicUser', _id: string, firstName?: string | null, profileImage?: { __typename?: 'Image', _id: string, path?: string | null } | null } | null, members?: Array<{ __typename?: 'PublicUser', _id: string, connected?: boolean | null, firstName?: string | null, profileImage?: { __typename?: 'Image', _id: string, path?: string | null } | null }> | null }> | null };

export type NotificationsByCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type NotificationsByCurrentUserQuery = { __typename?: 'Query', notificationsByCurrentUser?: Array<{ __typename?: 'Notification', _id: string, createdAt?: any | null, createdForId?: string | null, type: string, connectedUser?: { __typename?: 'ConnectedUser', _id: string, firstName?: string | null, profileImage?: { __typename?: 'Image', _id: string, path?: string | null } | null } | null, group?: { __typename?: 'PublicGroup', _id: string, name: string, createdBy?: { __typename?: 'PublicUser', _id: string, firstName?: string | null, profileImage?: { __typename?: 'Image', _id: string, path?: string | null } | null } | null } | null } | null> | null };

export type PlatformInvitationQueryVariables = Exact<{
  input: PlatformInvitationInput;
}>;


export type PlatformInvitationQuery = { __typename?: 'Query', platformInvitation?: { __typename?: 'PlatformInvitation', _id: string, createdBy?: { __typename?: 'PublicUser', _id: string, firstName?: string | null, profileImage?: { __typename?: 'Image', _id: string, path?: string | null } | null } | null } | null };

export type PotentialConnectedUsersByCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type PotentialConnectedUsersByCurrentUserQuery = { __typename?: 'Query', potentialConnectedUsersByCurrentUser?: Array<{ __typename?: 'PublicUser', _id: string, aboutMe?: string | null, createdAt?: any | null, firstName?: string | null, lastName?: string | null, profileImageId?: string | null, profileImage?: { __typename?: 'Image', _id: string, path?: string | null } | null }> | null };

export type PotentialConnectionsByContactsListQueryVariables = Exact<{
  input: PotentialConnectionsByContactsListInput;
}>;


export type PotentialConnectionsByContactsListQuery = { __typename?: 'Query', potentialConnectionsByContactsList?: Array<{ __typename?: 'PublicUser', _id: string, aboutMe?: string | null, createdAt?: any | null, firstName?: string | null, lastName?: string | null, profileImageId?: string | null, profileImage?: { __typename?: 'Image', _id: string, path?: string | null } | null }> | null };

export type PublicEventsByLocationQueryVariables = Exact<{
  input: PublicEventsByLocationInput;
}>;


export type PublicEventsByLocationQuery = { __typename?: 'Query', publicEventsByLocation?: Array<{ __typename?: 'Event', _id: string, createdAt?: any | null, description?: string | null, formattedAddress?: string | null, locationName?: string | null, name: string, start: any, viewed?: boolean | null, attendance?: { __typename?: 'EventAttendance', attendingUserCount: number } | null, createdBy?: { __typename?: 'PublicUser', _id: string, firstName?: string | null, lastName?: string | null, profileImage?: { __typename?: 'Image', _id: string, path?: string | null } | null } | null, location: { __typename?: 'Location', coordinates: Array<number>, type: string } } | null> | null };

export type UserQueryVariables = Exact<{
  input: UserInput;
}>;


export type UserQuery = { __typename?: 'Query', user?: { __typename?: 'PublicUser', _id: string, aboutMe?: string | null, connected?: boolean | null, connectionPending?: boolean | null, connectionRequestPending?: boolean | null, createdAt?: any | null, firstName?: string | null, lastName?: string | null, profileImage?: { __typename?: 'Image', _id: string, path?: string | null } | null } | null };

export type UsersByCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersByCurrentUserQuery = { __typename?: 'Query', usersByCurrentUser?: Array<{ __typename?: 'PublicUser', _id: string, aboutMe?: string | null, connected?: boolean | null, connectionPending?: boolean | null, connectionRequestPending?: boolean | null, createdAt?: any | null, firstName?: string | null, lastName?: string | null, profileImageId?: string | null, profileImage?: { __typename?: 'Image', _id: string, path?: string | null } | null } | null> | null };


export const AdminDeleteUserDocument = gql`
    mutation AdminDeleteUser($input: AdminDeleteUserInput!) {
  adminDeleteUser(input: $input) {
    error
    success
  }
}
    `;
export type AdminDeleteUserMutationFn = Apollo.MutationFunction<AdminDeleteUserMutation, AdminDeleteUserMutationVariables>;

/**
 * __useAdminDeleteUserMutation__
 *
 * To run a mutation, you first call `useAdminDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteUserMutation, { data, loading, error }] = useAdminDeleteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<AdminDeleteUserMutation, AdminDeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminDeleteUserMutation, AdminDeleteUserMutationVariables>(AdminDeleteUserDocument, options);
      }
export type AdminDeleteUserMutationHookResult = ReturnType<typeof useAdminDeleteUserMutation>;
export type AdminDeleteUserMutationResult = Apollo.MutationResult<AdminDeleteUserMutation>;
export type AdminDeleteUserMutationOptions = Apollo.BaseMutationOptions<AdminDeleteUserMutation, AdminDeleteUserMutationVariables>;
export const CreateBlockDocument = gql`
    mutation CreateBlock($input: CreateBlockInput!) {
  createBlock(input: $input) {
    error
    block {
      _id
      createdAt
      createdById
      userId
    }
    success
  }
}
    `;
export type CreateBlockMutationFn = Apollo.MutationFunction<CreateBlockMutation, CreateBlockMutationVariables>;

/**
 * __useCreateBlockMutation__
 *
 * To run a mutation, you first call `useCreateBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBlockMutation, { data, loading, error }] = useCreateBlockMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBlockMutation(baseOptions?: Apollo.MutationHookOptions<CreateBlockMutation, CreateBlockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBlockMutation, CreateBlockMutationVariables>(CreateBlockDocument, options);
      }
export type CreateBlockMutationHookResult = ReturnType<typeof useCreateBlockMutation>;
export type CreateBlockMutationResult = Apollo.MutationResult<CreateBlockMutation>;
export type CreateBlockMutationOptions = Apollo.BaseMutationOptions<CreateBlockMutation, CreateBlockMutationVariables>;
export const CreateConnectionIntentDocument = gql`
    mutation CreateConnectionIntent($input: CreateConnectionIntentInput!) {
  createConnectionIntent(input: $input) {
    error
    connectionIntent {
      _id
      connectedUserId
      createdAt
      createdById
      intent
    }
    success
  }
}
    `;
export type CreateConnectionIntentMutationFn = Apollo.MutationFunction<CreateConnectionIntentMutation, CreateConnectionIntentMutationVariables>;

/**
 * __useCreateConnectionIntentMutation__
 *
 * To run a mutation, you first call `useCreateConnectionIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConnectionIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConnectionIntentMutation, { data, loading, error }] = useCreateConnectionIntentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateConnectionIntentMutation(baseOptions?: Apollo.MutationHookOptions<CreateConnectionIntentMutation, CreateConnectionIntentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateConnectionIntentMutation, CreateConnectionIntentMutationVariables>(CreateConnectionIntentDocument, options);
      }
export type CreateConnectionIntentMutationHookResult = ReturnType<typeof useCreateConnectionIntentMutation>;
export type CreateConnectionIntentMutationResult = Apollo.MutationResult<CreateConnectionIntentMutation>;
export type CreateConnectionIntentMutationOptions = Apollo.BaseMutationOptions<CreateConnectionIntentMutation, CreateConnectionIntentMutationVariables>;
export const CreateEventDocument = gql`
    mutation CreateEvent($input: CreateEventInput!) {
  createEvent(input: $input) {
    error
    event {
      _id
      description
      name
    }
    success
  }
}
    `;
export type CreateEventMutationFn = Apollo.MutationFunction<CreateEventMutation, CreateEventMutationVariables>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventMutation, CreateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEventMutation, CreateEventMutationVariables>(CreateEventDocument, options);
      }
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = Apollo.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<CreateEventMutation, CreateEventMutationVariables>;
export const CreateEventFlagDocument = gql`
    mutation CreateEventFlag($input: CreateEventFlagInput!) {
  createEventFlag(input: $input) {
    error
    eventFlag {
      _id
      createdById
    }
    success
  }
}
    `;
export type CreateEventFlagMutationFn = Apollo.MutationFunction<CreateEventFlagMutation, CreateEventFlagMutationVariables>;

/**
 * __useCreateEventFlagMutation__
 *
 * To run a mutation, you first call `useCreateEventFlagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventFlagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventFlagMutation, { data, loading, error }] = useCreateEventFlagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEventFlagMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventFlagMutation, CreateEventFlagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEventFlagMutation, CreateEventFlagMutationVariables>(CreateEventFlagDocument, options);
      }
export type CreateEventFlagMutationHookResult = ReturnType<typeof useCreateEventFlagMutation>;
export type CreateEventFlagMutationResult = Apollo.MutationResult<CreateEventFlagMutation>;
export type CreateEventFlagMutationOptions = Apollo.BaseMutationOptions<CreateEventFlagMutation, CreateEventFlagMutationVariables>;
export const CreateEventIntentDocument = gql`
    mutation CreateEventIntent($input: CreateEventIntentInput!) {
  createEventIntent(input: $input) {
    error
    eventIntent {
      _id
      createdById
      isAttending
    }
    success
  }
}
    `;
export type CreateEventIntentMutationFn = Apollo.MutationFunction<CreateEventIntentMutation, CreateEventIntentMutationVariables>;

/**
 * __useCreateEventIntentMutation__
 *
 * To run a mutation, you first call `useCreateEventIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventIntentMutation, { data, loading, error }] = useCreateEventIntentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEventIntentMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventIntentMutation, CreateEventIntentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEventIntentMutation, CreateEventIntentMutationVariables>(CreateEventIntentDocument, options);
      }
export type CreateEventIntentMutationHookResult = ReturnType<typeof useCreateEventIntentMutation>;
export type CreateEventIntentMutationResult = Apollo.MutationResult<CreateEventIntentMutation>;
export type CreateEventIntentMutationOptions = Apollo.BaseMutationOptions<CreateEventIntentMutation, CreateEventIntentMutationVariables>;
export const CreateGroupDocument = gql`
    mutation CreateGroup($input: CreateGroupInput!) {
  createGroup(input: $input) {
    error
    group {
      _id
    }
    success
  }
}
    `;
export type CreateGroupMutationFn = Apollo.MutationFunction<CreateGroupMutation, CreateGroupMutationVariables>;

/**
 * __useCreateGroupMutation__
 *
 * To run a mutation, you first call `useCreateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupMutation, { data, loading, error }] = useCreateGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateGroupMutation, CreateGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGroupMutation, CreateGroupMutationVariables>(CreateGroupDocument, options);
      }
export type CreateGroupMutationHookResult = ReturnType<typeof useCreateGroupMutation>;
export type CreateGroupMutationResult = Apollo.MutationResult<CreateGroupMutation>;
export type CreateGroupMutationOptions = Apollo.BaseMutationOptions<CreateGroupMutation, CreateGroupMutationVariables>;
export const CreateImageDocument = gql`
    mutation CreateImage($input: CreateImageInput!) {
  createImage(input: $input) {
    error
    image {
      _id
      path
    }
    success
  }
}
    `;
export type CreateImageMutationFn = Apollo.MutationFunction<CreateImageMutation, CreateImageMutationVariables>;

/**
 * __useCreateImageMutation__
 *
 * To run a mutation, you first call `useCreateImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createImageMutation, { data, loading, error }] = useCreateImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateImageMutation(baseOptions?: Apollo.MutationHookOptions<CreateImageMutation, CreateImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateImageMutation, CreateImageMutationVariables>(CreateImageDocument, options);
      }
export type CreateImageMutationHookResult = ReturnType<typeof useCreateImageMutation>;
export type CreateImageMutationResult = Apollo.MutationResult<CreateImageMutation>;
export type CreateImageMutationOptions = Apollo.BaseMutationOptions<CreateImageMutation, CreateImageMutationVariables>;
export const CreatePlatformInvitationDocument = gql`
    mutation CreatePlatformInvitation {
  createPlatformInvitation {
    error
    platformInvitation {
      _id
    }
    success
  }
}
    `;
export type CreatePlatformInvitationMutationFn = Apollo.MutationFunction<CreatePlatformInvitationMutation, CreatePlatformInvitationMutationVariables>;

/**
 * __useCreatePlatformInvitationMutation__
 *
 * To run a mutation, you first call `useCreatePlatformInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlatformInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlatformInvitationMutation, { data, loading, error }] = useCreatePlatformInvitationMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreatePlatformInvitationMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlatformInvitationMutation, CreatePlatformInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePlatformInvitationMutation, CreatePlatformInvitationMutationVariables>(CreatePlatformInvitationDocument, options);
      }
export type CreatePlatformInvitationMutationHookResult = ReturnType<typeof useCreatePlatformInvitationMutation>;
export type CreatePlatformInvitationMutationResult = Apollo.MutationResult<CreatePlatformInvitationMutation>;
export type CreatePlatformInvitationMutationOptions = Apollo.BaseMutationOptions<CreatePlatformInvitationMutation, CreatePlatformInvitationMutationVariables>;
export const DeleteEventDocument = gql`
    mutation DeleteEvent($input: DeleteEventInput!) {
  deleteEvent(input: $input) {
    error
    success
  }
}
    `;
export type DeleteEventMutationFn = Apollo.MutationFunction<DeleteEventMutation, DeleteEventMutationVariables>;

/**
 * __useDeleteEventMutation__
 *
 * To run a mutation, you first call `useDeleteEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventMutation, { data, loading, error }] = useDeleteEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteEventMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEventMutation, DeleteEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEventMutation, DeleteEventMutationVariables>(DeleteEventDocument, options);
      }
export type DeleteEventMutationHookResult = ReturnType<typeof useDeleteEventMutation>;
export type DeleteEventMutationResult = Apollo.MutationResult<DeleteEventMutation>;
export type DeleteEventMutationOptions = Apollo.BaseMutationOptions<DeleteEventMutation, DeleteEventMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($input: DeleteUserInput!) {
  deleteUser(input: $input) {
    error
    success
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const GenerateAndSendPassCodeDocument = gql`
    mutation GenerateAndSendPassCode($input: GenerateAndSendPassCodeInput!) {
  generateAndSendPassCode(input: $input) {
    error
    success
  }
}
    `;
export type GenerateAndSendPassCodeMutationFn = Apollo.MutationFunction<GenerateAndSendPassCodeMutation, GenerateAndSendPassCodeMutationVariables>;

/**
 * __useGenerateAndSendPassCodeMutation__
 *
 * To run a mutation, you first call `useGenerateAndSendPassCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateAndSendPassCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateAndSendPassCodeMutation, { data, loading, error }] = useGenerateAndSendPassCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateAndSendPassCodeMutation(baseOptions?: Apollo.MutationHookOptions<GenerateAndSendPassCodeMutation, GenerateAndSendPassCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateAndSendPassCodeMutation, GenerateAndSendPassCodeMutationVariables>(GenerateAndSendPassCodeDocument, options);
      }
export type GenerateAndSendPassCodeMutationHookResult = ReturnType<typeof useGenerateAndSendPassCodeMutation>;
export type GenerateAndSendPassCodeMutationResult = Apollo.MutationResult<GenerateAndSendPassCodeMutation>;
export type GenerateAndSendPassCodeMutationOptions = Apollo.BaseMutationOptions<GenerateAndSendPassCodeMutation, GenerateAndSendPassCodeMutationVariables>;
export const JoinGroupDocument = gql`
    mutation JoinGroup($input: JoinGroupInput!) {
  joinGroup(input: $input) {
    error
    group {
      _id
      name
    }
    success
  }
}
    `;
export type JoinGroupMutationFn = Apollo.MutationFunction<JoinGroupMutation, JoinGroupMutationVariables>;

/**
 * __useJoinGroupMutation__
 *
 * To run a mutation, you first call `useJoinGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinGroupMutation, { data, loading, error }] = useJoinGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJoinGroupMutation(baseOptions?: Apollo.MutationHookOptions<JoinGroupMutation, JoinGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinGroupMutation, JoinGroupMutationVariables>(JoinGroupDocument, options);
      }
export type JoinGroupMutationHookResult = ReturnType<typeof useJoinGroupMutation>;
export type JoinGroupMutationResult = Apollo.MutationResult<JoinGroupMutation>;
export type JoinGroupMutationOptions = Apollo.BaseMutationOptions<JoinGroupMutation, JoinGroupMutationVariables>;
export const LeaveGroupDocument = gql`
    mutation LeaveGroup($input: LeaveGroupInput!) {
  leaveGroup(input: $input) {
    error
    group {
      _id
    }
    success
  }
}
    `;
export type LeaveGroupMutationFn = Apollo.MutationFunction<LeaveGroupMutation, LeaveGroupMutationVariables>;

/**
 * __useLeaveGroupMutation__
 *
 * To run a mutation, you first call `useLeaveGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveGroupMutation, { data, loading, error }] = useLeaveGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLeaveGroupMutation(baseOptions?: Apollo.MutationHookOptions<LeaveGroupMutation, LeaveGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LeaveGroupMutation, LeaveGroupMutationVariables>(LeaveGroupDocument, options);
      }
export type LeaveGroupMutationHookResult = ReturnType<typeof useLeaveGroupMutation>;
export type LeaveGroupMutationResult = Apollo.MutationResult<LeaveGroupMutation>;
export type LeaveGroupMutationOptions = Apollo.BaseMutationOptions<LeaveGroupMutation, LeaveGroupMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout {
    success
    error
  }
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const RedeemPlatformInvitationDocument = gql`
    mutation RedeemPlatformInvitation($input: RedeemPlatformInvitationInput!) {
  redeemPlatformInvitation(input: $input) {
    error
    success
  }
}
    `;
export type RedeemPlatformInvitationMutationFn = Apollo.MutationFunction<RedeemPlatformInvitationMutation, RedeemPlatformInvitationMutationVariables>;

/**
 * __useRedeemPlatformInvitationMutation__
 *
 * To run a mutation, you first call `useRedeemPlatformInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedeemPlatformInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redeemPlatformInvitationMutation, { data, loading, error }] = useRedeemPlatformInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRedeemPlatformInvitationMutation(baseOptions?: Apollo.MutationHookOptions<RedeemPlatformInvitationMutation, RedeemPlatformInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RedeemPlatformInvitationMutation, RedeemPlatformInvitationMutationVariables>(RedeemPlatformInvitationDocument, options);
      }
export type RedeemPlatformInvitationMutationHookResult = ReturnType<typeof useRedeemPlatformInvitationMutation>;
export type RedeemPlatformInvitationMutationResult = Apollo.MutationResult<RedeemPlatformInvitationMutation>;
export type RedeemPlatformInvitationMutationOptions = Apollo.BaseMutationOptions<RedeemPlatformInvitationMutation, RedeemPlatformInvitationMutationVariables>;
export const ResolvePassCodeDocument = gql`
    mutation ResolvePassCode($input: ResolvePassCodeInput!) {
  resolvePassCode(input: $input) {
    isNewUser
    success
    token
    error
  }
}
    `;
export type ResolvePassCodeMutationFn = Apollo.MutationFunction<ResolvePassCodeMutation, ResolvePassCodeMutationVariables>;

/**
 * __useResolvePassCodeMutation__
 *
 * To run a mutation, you first call `useResolvePassCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolvePassCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolvePassCodeMutation, { data, loading, error }] = useResolvePassCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResolvePassCodeMutation(baseOptions?: Apollo.MutationHookOptions<ResolvePassCodeMutation, ResolvePassCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResolvePassCodeMutation, ResolvePassCodeMutationVariables>(ResolvePassCodeDocument, options);
      }
export type ResolvePassCodeMutationHookResult = ReturnType<typeof useResolvePassCodeMutation>;
export type ResolvePassCodeMutationResult = Apollo.MutationResult<ResolvePassCodeMutation>;
export type ResolvePassCodeMutationOptions = Apollo.BaseMutationOptions<ResolvePassCodeMutation, ResolvePassCodeMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    _id
    createdAt
    firstName
    lastName
    aboutMe
    createdAt
    firstName
    lastName
    lastVisitedNotificationsAt
    profileImage {
      _id
      path
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const AdminDashboardDocument = gql`
    query AdminDashboard {
  adminDashboard {
    success
    error
    adminDashboard {
      counts {
        lastSeven {
          blockCount
          eventCount
          eventViewCount
          postCount
          postViewCount
          userCount
        }
        lastFourteen {
          blockCount
          eventCount
          eventViewCount
          postCount
          postViewCount
          userCount
        }
        lastTwentyEight {
          blockCount
          eventCount
          eventViewCount
          postCount
          postViewCount
          userCount
        }
        total {
          blockCount
          eventCount
          eventViewCount
          postCount
          postViewCount
          userCount
        }
      }
      users {
        _id
        firstName
        lastName
        createdAt
        profileImage {
          _id
          path
        }
      }
    }
  }
}
    `;

/**
 * __useAdminDashboardQuery__
 *
 * To run a query within a React component, call `useAdminDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminDashboardQuery(baseOptions?: Apollo.QueryHookOptions<AdminDashboardQuery, AdminDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminDashboardQuery, AdminDashboardQueryVariables>(AdminDashboardDocument, options);
      }
export function useAdminDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminDashboardQuery, AdminDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminDashboardQuery, AdminDashboardQueryVariables>(AdminDashboardDocument, options);
        }
export type AdminDashboardQueryHookResult = ReturnType<typeof useAdminDashboardQuery>;
export type AdminDashboardLazyQueryHookResult = ReturnType<typeof useAdminDashboardLazyQuery>;
export type AdminDashboardQueryResult = Apollo.QueryResult<AdminDashboardQuery, AdminDashboardQueryVariables>;
export const CalendarEventsByCurrentUserDocument = gql`
    query CalendarEventsByCurrentUser {
  calendarEventsByCurrentUser {
    _id
    attendance {
      attendingUserCount
      attendingUsers {
        _id
        connected
        firstName
        profileImage {
          _id
          path
        }
      }
      currentUserIsAttending
    }
    createdAt
    createdBy {
      _id
      firstName
      lastName
      profileImage {
        _id
        path
      }
    }
    description
    featuredImage {
      _id
      path
    }
    formattedAddress
    location {
      coordinates
      type
    }
    locationName
    name
    place {
      _id
      createdById
      formattedAddress
      location {
        coordinates
        type
      }
      name
      phoneNumber
      profileImage {
        _id
        path
      }
      profileImageId
      url
    }
    start
    viewed
  }
}
    `;

/**
 * __useCalendarEventsByCurrentUserQuery__
 *
 * To run a query within a React component, call `useCalendarEventsByCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarEventsByCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarEventsByCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCalendarEventsByCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CalendarEventsByCurrentUserQuery, CalendarEventsByCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalendarEventsByCurrentUserQuery, CalendarEventsByCurrentUserQueryVariables>(CalendarEventsByCurrentUserDocument, options);
      }
export function useCalendarEventsByCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalendarEventsByCurrentUserQuery, CalendarEventsByCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalendarEventsByCurrentUserQuery, CalendarEventsByCurrentUserQueryVariables>(CalendarEventsByCurrentUserDocument, options);
        }
export type CalendarEventsByCurrentUserQueryHookResult = ReturnType<typeof useCalendarEventsByCurrentUserQuery>;
export type CalendarEventsByCurrentUserLazyQueryHookResult = ReturnType<typeof useCalendarEventsByCurrentUserLazyQuery>;
export type CalendarEventsByCurrentUserQueryResult = Apollo.QueryResult<CalendarEventsByCurrentUserQuery, CalendarEventsByCurrentUserQueryVariables>;
export const ConnectedUserDocument = gql`
    query ConnectedUser($input: UserInput!) {
  connectedUser(input: $input) {
    _id
    aboutMe
    connected
    createdAt
    firstName
    lastName
    profileImage {
      _id
      path
    }
    profileImageId
  }
}
    `;

/**
 * __useConnectedUserQuery__
 *
 * To run a query within a React component, call `useConnectedUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectedUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectedUserQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConnectedUserQuery(baseOptions: Apollo.QueryHookOptions<ConnectedUserQuery, ConnectedUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConnectedUserQuery, ConnectedUserQueryVariables>(ConnectedUserDocument, options);
      }
export function useConnectedUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConnectedUserQuery, ConnectedUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConnectedUserQuery, ConnectedUserQueryVariables>(ConnectedUserDocument, options);
        }
export type ConnectedUserQueryHookResult = ReturnType<typeof useConnectedUserQuery>;
export type ConnectedUserLazyQueryHookResult = ReturnType<typeof useConnectedUserLazyQuery>;
export type ConnectedUserQueryResult = Apollo.QueryResult<ConnectedUserQuery, ConnectedUserQueryVariables>;
export const ConnectedUsersByCurrentUserDocument = gql`
    query ConnectedUsersByCurrentUser {
  connectedUsersByCurrentUser {
    _id
    aboutMe
    connected
    createdAt
    firstName
    lastName
    profileImage {
      _id
      path
    }
    profileImageId
  }
}
    `;

/**
 * __useConnectedUsersByCurrentUserQuery__
 *
 * To run a query within a React component, call `useConnectedUsersByCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectedUsersByCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectedUsersByCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useConnectedUsersByCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<ConnectedUsersByCurrentUserQuery, ConnectedUsersByCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConnectedUsersByCurrentUserQuery, ConnectedUsersByCurrentUserQueryVariables>(ConnectedUsersByCurrentUserDocument, options);
      }
export function useConnectedUsersByCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConnectedUsersByCurrentUserQuery, ConnectedUsersByCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConnectedUsersByCurrentUserQuery, ConnectedUsersByCurrentUserQueryVariables>(ConnectedUsersByCurrentUserDocument, options);
        }
export type ConnectedUsersByCurrentUserQueryHookResult = ReturnType<typeof useConnectedUsersByCurrentUserQuery>;
export type ConnectedUsersByCurrentUserLazyQueryHookResult = ReturnType<typeof useConnectedUsersByCurrentUserLazyQuery>;
export type ConnectedUsersByCurrentUserQueryResult = Apollo.QueryResult<ConnectedUsersByCurrentUserQuery, ConnectedUsersByCurrentUserQueryVariables>;
export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUser {
    _id
    aboutMe
    connectionCount
    createdAt
    firstName
    formattedAddress
    hasCreatedAConnectionIntent
    hasPotentialConnections
    hasUnseenNotifications
    isAdmin
    lastName
    lastVisitedNotificationsAt
    profileImage {
      _id
      path
    }
  }
}
    `;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const EventDocument = gql`
    query Event($input: EventInput!) {
  event(input: $input) {
    _id
    attendance {
      attendingUserCount
      attendingUsers {
        _id
        connected
        firstName
        profileImage {
          _id
          path
        }
      }
      currentUserIsAttending
    }
    createdAt
    createdBy {
      _id
      firstName
      lastName
      profileImage {
        _id
        path
      }
    }
    deleted
    description
    featuredImage {
      _id
      path
    }
    featuredImageId
    formattedAddress
    location {
      coordinates
      type
    }
    locationName
    name
    place {
      _id
      createdById
      formattedAddress
      location {
        coordinates
        type
      }
      name
      phoneNumber
      profileImage {
        _id
        path
      }
      profileImageId
      url
      vanityUrl
    }
    start
    viewed
  }
}
    `;

/**
 * __useEventQuery__
 *
 * To run a query within a React component, call `useEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEventQuery(baseOptions: Apollo.QueryHookOptions<EventQuery, EventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventQuery, EventQueryVariables>(EventDocument, options);
      }
export function useEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventQuery, EventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventQuery, EventQueryVariables>(EventDocument, options);
        }
export type EventQueryHookResult = ReturnType<typeof useEventQuery>;
export type EventLazyQueryHookResult = ReturnType<typeof useEventLazyQuery>;
export type EventQueryResult = Apollo.QueryResult<EventQuery, EventQueryVariables>;
export const EventsByCurrentUserDocument = gql`
    query EventsByCurrentUser {
  eventsByCurrentUser {
    _id
    attendance {
      attendingUserCount
      attendingUsers {
        _id
        connected
        firstName
        profileImage {
          _id
          path
        }
      }
      currentUserIsAttending
    }
    createdAt
    createdBy {
      _id
      firstName
      lastName
      profileImage {
        _id
        path
      }
    }
    description
    featuredImage {
      _id
      path
    }
    formattedAddress
    location {
      coordinates
      type
    }
    locationName
    name
    place {
      _id
      createdById
      formattedAddress
      location {
        coordinates
        type
      }
      name
      phoneNumber
      profileImage {
        _id
        path
      }
      profileImageId
      url
    }
    start
    viewed
  }
}
    `;

/**
 * __useEventsByCurrentUserQuery__
 *
 * To run a query within a React component, call `useEventsByCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsByCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsByCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useEventsByCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<EventsByCurrentUserQuery, EventsByCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventsByCurrentUserQuery, EventsByCurrentUserQueryVariables>(EventsByCurrentUserDocument, options);
      }
export function useEventsByCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventsByCurrentUserQuery, EventsByCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventsByCurrentUserQuery, EventsByCurrentUserQueryVariables>(EventsByCurrentUserDocument, options);
        }
export type EventsByCurrentUserQueryHookResult = ReturnType<typeof useEventsByCurrentUserQuery>;
export type EventsByCurrentUserLazyQueryHookResult = ReturnType<typeof useEventsByCurrentUserLazyQuery>;
export type EventsByCurrentUserQueryResult = Apollo.QueryResult<EventsByCurrentUserQuery, EventsByCurrentUserQueryVariables>;
export const EventsByPlaceDocument = gql`
    query EventsByPlace($input: EventsByPlaceInput!) {
  eventsByPlace(input: $input) {
    _id
    attendance {
      attendingUserCount
      attendingUsers {
        _id
        connected
        firstName
        profileImage {
          _id
          path
        }
      }
      currentUserIsAttending
    }
    createdAt
    createdBy {
      _id
      firstName
      lastName
      profileImage {
        _id
        path
      }
    }
    description
    formattedAddress
    location {
      coordinates
      type
    }
    locationName
    name
    place {
      _id
      createdById
      formattedAddress
      location {
        coordinates
        type
      }
      name
      phoneNumber
      profileImage {
        _id
        path
      }
      profileImageId
      url
      vanityUrl
    }
    start
    viewed
  }
}
    `;

/**
 * __useEventsByPlaceQuery__
 *
 * To run a query within a React component, call `useEventsByPlaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsByPlaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsByPlaceQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEventsByPlaceQuery(baseOptions: Apollo.QueryHookOptions<EventsByPlaceQuery, EventsByPlaceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventsByPlaceQuery, EventsByPlaceQueryVariables>(EventsByPlaceDocument, options);
      }
export function useEventsByPlaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventsByPlaceQuery, EventsByPlaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventsByPlaceQuery, EventsByPlaceQueryVariables>(EventsByPlaceDocument, options);
        }
export type EventsByPlaceQueryHookResult = ReturnType<typeof useEventsByPlaceQuery>;
export type EventsByPlaceLazyQueryHookResult = ReturnType<typeof useEventsByPlaceLazyQuery>;
export type EventsByPlaceQueryResult = Apollo.QueryResult<EventsByPlaceQuery, EventsByPlaceQueryVariables>;
export const EventsCreatedByCurrentUserDocument = gql`
    query EventsCreatedByCurrentUser {
  eventsCreatedByCurrentUser {
    _id
    createdAt
    createdBy {
      _id
      firstName
      lastName
      profileImage {
        _id
        path
      }
    }
    description
    formattedAddress
    location {
      coordinates
      type
    }
    locationName
    name
    place {
      _id
      createdById
      formattedAddress
      location {
        coordinates
        type
      }
      name
      phoneNumber
      profileImage {
        _id
        path
      }
      profileImageId
      url
      vanityUrl
    }
    start
    viewed
  }
}
    `;

/**
 * __useEventsCreatedByCurrentUserQuery__
 *
 * To run a query within a React component, call `useEventsCreatedByCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsCreatedByCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsCreatedByCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useEventsCreatedByCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<EventsCreatedByCurrentUserQuery, EventsCreatedByCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventsCreatedByCurrentUserQuery, EventsCreatedByCurrentUserQueryVariables>(EventsCreatedByCurrentUserDocument, options);
      }
export function useEventsCreatedByCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventsCreatedByCurrentUserQuery, EventsCreatedByCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventsCreatedByCurrentUserQuery, EventsCreatedByCurrentUserQueryVariables>(EventsCreatedByCurrentUserDocument, options);
        }
export type EventsCreatedByCurrentUserQueryHookResult = ReturnType<typeof useEventsCreatedByCurrentUserQuery>;
export type EventsCreatedByCurrentUserLazyQueryHookResult = ReturnType<typeof useEventsCreatedByCurrentUserLazyQuery>;
export type EventsCreatedByCurrentUserQueryResult = Apollo.QueryResult<EventsCreatedByCurrentUserQuery, EventsCreatedByCurrentUserQueryVariables>;
export const GooglePlaceDetailsDocument = gql`
    query GooglePlaceDetails($input: GooglePlaceDetailsInput) {
  googlePlaceDetails(input: $input) {
    formattedPhoneNumber
    icon
    name
    openingHoursWeekdayText
  }
}
    `;

/**
 * __useGooglePlaceDetailsQuery__
 *
 * To run a query within a React component, call `useGooglePlaceDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGooglePlaceDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGooglePlaceDetailsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGooglePlaceDetailsQuery(baseOptions?: Apollo.QueryHookOptions<GooglePlaceDetailsQuery, GooglePlaceDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GooglePlaceDetailsQuery, GooglePlaceDetailsQueryVariables>(GooglePlaceDetailsDocument, options);
      }
export function useGooglePlaceDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GooglePlaceDetailsQuery, GooglePlaceDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GooglePlaceDetailsQuery, GooglePlaceDetailsQueryVariables>(GooglePlaceDetailsDocument, options);
        }
export type GooglePlaceDetailsQueryHookResult = ReturnType<typeof useGooglePlaceDetailsQuery>;
export type GooglePlaceDetailsLazyQueryHookResult = ReturnType<typeof useGooglePlaceDetailsLazyQuery>;
export type GooglePlaceDetailsQueryResult = Apollo.QueryResult<GooglePlaceDetailsQuery, GooglePlaceDetailsQueryVariables>;
export const GroupDocument = gql`
    query Group($input: GroupInput!) {
  group(input: $input) {
    _id
    createdBy {
      _id
      firstName
      profileImage {
        _id
        path
      }
    }
    name
  }
}
    `;

/**
 * __useGroupQuery__
 *
 * To run a query within a React component, call `useGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGroupQuery(baseOptions: Apollo.QueryHookOptions<GroupQuery, GroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupQuery, GroupQueryVariables>(GroupDocument, options);
      }
export function useGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupQuery, GroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupQuery, GroupQueryVariables>(GroupDocument, options);
        }
export type GroupQueryHookResult = ReturnType<typeof useGroupQuery>;
export type GroupLazyQueryHookResult = ReturnType<typeof useGroupLazyQuery>;
export type GroupQueryResult = Apollo.QueryResult<GroupQuery, GroupQueryVariables>;
export const GroupsByCurrentUserDocument = gql`
    query GroupsByCurrentUser {
  groupsByCurrentUser {
    _id
    createdBy {
      _id
      firstName
      profileImage {
        _id
        path
      }
    }
    members {
      _id
      connected
      firstName
      profileImage {
        _id
        path
      }
    }
    name
  }
}
    `;

/**
 * __useGroupsByCurrentUserQuery__
 *
 * To run a query within a React component, call `useGroupsByCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsByCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsByCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGroupsByCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GroupsByCurrentUserQuery, GroupsByCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupsByCurrentUserQuery, GroupsByCurrentUserQueryVariables>(GroupsByCurrentUserDocument, options);
      }
export function useGroupsByCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupsByCurrentUserQuery, GroupsByCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupsByCurrentUserQuery, GroupsByCurrentUserQueryVariables>(GroupsByCurrentUserDocument, options);
        }
export type GroupsByCurrentUserQueryHookResult = ReturnType<typeof useGroupsByCurrentUserQuery>;
export type GroupsByCurrentUserLazyQueryHookResult = ReturnType<typeof useGroupsByCurrentUserLazyQuery>;
export type GroupsByCurrentUserQueryResult = Apollo.QueryResult<GroupsByCurrentUserQuery, GroupsByCurrentUserQueryVariables>;
export const NotificationsByCurrentUserDocument = gql`
    query NotificationsByCurrentUser {
  notificationsByCurrentUser {
    _id
    connectedUser {
      _id
      firstName
      profileImage {
        _id
        path
      }
    }
    createdAt
    createdForId
    group {
      _id
      createdBy {
        _id
        firstName
        profileImage {
          _id
          path
        }
      }
      name
    }
    type
  }
}
    `;

/**
 * __useNotificationsByCurrentUserQuery__
 *
 * To run a query within a React component, call `useNotificationsByCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsByCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsByCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationsByCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<NotificationsByCurrentUserQuery, NotificationsByCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsByCurrentUserQuery, NotificationsByCurrentUserQueryVariables>(NotificationsByCurrentUserDocument, options);
      }
export function useNotificationsByCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsByCurrentUserQuery, NotificationsByCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsByCurrentUserQuery, NotificationsByCurrentUserQueryVariables>(NotificationsByCurrentUserDocument, options);
        }
export type NotificationsByCurrentUserQueryHookResult = ReturnType<typeof useNotificationsByCurrentUserQuery>;
export type NotificationsByCurrentUserLazyQueryHookResult = ReturnType<typeof useNotificationsByCurrentUserLazyQuery>;
export type NotificationsByCurrentUserQueryResult = Apollo.QueryResult<NotificationsByCurrentUserQuery, NotificationsByCurrentUserQueryVariables>;
export const PlatformInvitationDocument = gql`
    query PlatformInvitation($input: PlatformInvitationInput!) {
  platformInvitation(input: $input) {
    _id
    createdBy {
      _id
      firstName
      profileImage {
        _id
        path
      }
    }
  }
}
    `;

/**
 * __usePlatformInvitationQuery__
 *
 * To run a query within a React component, call `usePlatformInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlatformInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlatformInvitationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlatformInvitationQuery(baseOptions: Apollo.QueryHookOptions<PlatformInvitationQuery, PlatformInvitationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlatformInvitationQuery, PlatformInvitationQueryVariables>(PlatformInvitationDocument, options);
      }
export function usePlatformInvitationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlatformInvitationQuery, PlatformInvitationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlatformInvitationQuery, PlatformInvitationQueryVariables>(PlatformInvitationDocument, options);
        }
export type PlatformInvitationQueryHookResult = ReturnType<typeof usePlatformInvitationQuery>;
export type PlatformInvitationLazyQueryHookResult = ReturnType<typeof usePlatformInvitationLazyQuery>;
export type PlatformInvitationQueryResult = Apollo.QueryResult<PlatformInvitationQuery, PlatformInvitationQueryVariables>;
export const PotentialConnectedUsersByCurrentUserDocument = gql`
    query PotentialConnectedUsersByCurrentUser {
  potentialConnectedUsersByCurrentUser {
    _id
    aboutMe
    createdAt
    firstName
    lastName
    profileImage {
      _id
      path
    }
    profileImageId
  }
}
    `;

/**
 * __usePotentialConnectedUsersByCurrentUserQuery__
 *
 * To run a query within a React component, call `usePotentialConnectedUsersByCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `usePotentialConnectedUsersByCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePotentialConnectedUsersByCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function usePotentialConnectedUsersByCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<PotentialConnectedUsersByCurrentUserQuery, PotentialConnectedUsersByCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PotentialConnectedUsersByCurrentUserQuery, PotentialConnectedUsersByCurrentUserQueryVariables>(PotentialConnectedUsersByCurrentUserDocument, options);
      }
export function usePotentialConnectedUsersByCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PotentialConnectedUsersByCurrentUserQuery, PotentialConnectedUsersByCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PotentialConnectedUsersByCurrentUserQuery, PotentialConnectedUsersByCurrentUserQueryVariables>(PotentialConnectedUsersByCurrentUserDocument, options);
        }
export type PotentialConnectedUsersByCurrentUserQueryHookResult = ReturnType<typeof usePotentialConnectedUsersByCurrentUserQuery>;
export type PotentialConnectedUsersByCurrentUserLazyQueryHookResult = ReturnType<typeof usePotentialConnectedUsersByCurrentUserLazyQuery>;
export type PotentialConnectedUsersByCurrentUserQueryResult = Apollo.QueryResult<PotentialConnectedUsersByCurrentUserQuery, PotentialConnectedUsersByCurrentUserQueryVariables>;
export const PotentialConnectionsByContactsListDocument = gql`
    query PotentialConnectionsByContactsList($input: PotentialConnectionsByContactsListInput!) {
  potentialConnectionsByContactsList(input: $input) {
    _id
    aboutMe
    createdAt
    firstName
    lastName
    profileImage {
      _id
      path
    }
    profileImageId
  }
}
    `;

/**
 * __usePotentialConnectionsByContactsListQuery__
 *
 * To run a query within a React component, call `usePotentialConnectionsByContactsListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePotentialConnectionsByContactsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePotentialConnectionsByContactsListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePotentialConnectionsByContactsListQuery(baseOptions: Apollo.QueryHookOptions<PotentialConnectionsByContactsListQuery, PotentialConnectionsByContactsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PotentialConnectionsByContactsListQuery, PotentialConnectionsByContactsListQueryVariables>(PotentialConnectionsByContactsListDocument, options);
      }
export function usePotentialConnectionsByContactsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PotentialConnectionsByContactsListQuery, PotentialConnectionsByContactsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PotentialConnectionsByContactsListQuery, PotentialConnectionsByContactsListQueryVariables>(PotentialConnectionsByContactsListDocument, options);
        }
export type PotentialConnectionsByContactsListQueryHookResult = ReturnType<typeof usePotentialConnectionsByContactsListQuery>;
export type PotentialConnectionsByContactsListLazyQueryHookResult = ReturnType<typeof usePotentialConnectionsByContactsListLazyQuery>;
export type PotentialConnectionsByContactsListQueryResult = Apollo.QueryResult<PotentialConnectionsByContactsListQuery, PotentialConnectionsByContactsListQueryVariables>;
export const PublicEventsByLocationDocument = gql`
    query PublicEventsByLocation($input: PublicEventsByLocationInput!) {
  publicEventsByLocation(input: $input) {
    _id
    attendance {
      attendingUserCount
    }
    createdAt
    createdBy {
      _id
      firstName
      lastName
      profileImage {
        _id
        path
      }
    }
    description
    formattedAddress
    location {
      coordinates
      type
    }
    locationName
    name
    start
    viewed
  }
}
    `;

/**
 * __usePublicEventsByLocationQuery__
 *
 * To run a query within a React component, call `usePublicEventsByLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicEventsByLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicEventsByLocationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublicEventsByLocationQuery(baseOptions: Apollo.QueryHookOptions<PublicEventsByLocationQuery, PublicEventsByLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicEventsByLocationQuery, PublicEventsByLocationQueryVariables>(PublicEventsByLocationDocument, options);
      }
export function usePublicEventsByLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicEventsByLocationQuery, PublicEventsByLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicEventsByLocationQuery, PublicEventsByLocationQueryVariables>(PublicEventsByLocationDocument, options);
        }
export type PublicEventsByLocationQueryHookResult = ReturnType<typeof usePublicEventsByLocationQuery>;
export type PublicEventsByLocationLazyQueryHookResult = ReturnType<typeof usePublicEventsByLocationLazyQuery>;
export type PublicEventsByLocationQueryResult = Apollo.QueryResult<PublicEventsByLocationQuery, PublicEventsByLocationQueryVariables>;
export const UserDocument = gql`
    query User($input: UserInput!) {
  user(input: $input) {
    _id
    aboutMe
    connected
    connectionPending
    connectionRequestPending
    createdAt
    firstName
    lastName
    profileImage {
      _id
      path
    }
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UsersByCurrentUserDocument = gql`
    query UsersByCurrentUser {
  usersByCurrentUser {
    _id
    aboutMe
    connected
    connectionPending
    connectionRequestPending
    createdAt
    firstName
    lastName
    profileImage {
      _id
      path
    }
    profileImageId
  }
}
    `;

/**
 * __useUsersByCurrentUserQuery__
 *
 * To run a query within a React component, call `useUsersByCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersByCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersByCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersByCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<UsersByCurrentUserQuery, UsersByCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersByCurrentUserQuery, UsersByCurrentUserQueryVariables>(UsersByCurrentUserDocument, options);
      }
export function useUsersByCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersByCurrentUserQuery, UsersByCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersByCurrentUserQuery, UsersByCurrentUserQueryVariables>(UsersByCurrentUserDocument, options);
        }
export type UsersByCurrentUserQueryHookResult = ReturnType<typeof useUsersByCurrentUserQuery>;
export type UsersByCurrentUserLazyQueryHookResult = ReturnType<typeof useUsersByCurrentUserLazyQuery>;
export type UsersByCurrentUserQueryResult = Apollo.QueryResult<UsersByCurrentUserQuery, UsersByCurrentUserQueryVariables>;