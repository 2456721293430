import { createSlice } from '@reduxjs/toolkit';

export interface LocationCoordinates {
  lat: number;
  lng: number;
}

export interface Location {
  description?: string;
  formattedAddress: string;
  coordinates: LocationCoordinates;
}

export interface OnboardingSliceState {
  location?: Location;
  phoneNumber?: string;
  returnToRoute?: string;
}

const initialState: OnboardingSliceState = {
  location: undefined,
  phoneNumber: undefined,
  returnToRoute: undefined,
};

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    onLocationUpdate: (state, action) => {
      state.location = action.payload;
    },
    onPhoneNumberUpdate: (state, action) => {
      state.phoneNumber = action.payload;
    },
    onBeginOnboardingFromRoute: (state, action) => {
      state.returnToRoute = action.payload;
    },
  },
});

export const { onLocationUpdate, onPhoneNumberUpdate, onBeginOnboardingFromRoute } = onboardingSlice.actions;

export default onboardingSlice.reducer;
