import { configureStore } from '@reduxjs/toolkit';

import geolocationReducer from './geolocation';
import listViewReducer from './listView';
import onboardingReducer from './onboarding';

const store = configureStore({
  reducer: {
    geolocation: geolocationReducer,
    listItem: listViewReducer,
    onboarding: onboardingReducer,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
