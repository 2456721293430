import styled from 'styled-components';

import { white, gray, darkBlue } from 'styles/colors';

const PrimaryHeaderButton = styled.button`
  background: ${(props) => (props.disabled ? gray : darkBlue)};
  color: ${white};
  font-size: 16px;
  font-weight: 600;
  padding: 4px 8px;
  border: none;
  border-radius: 4px;
  line-height: 19px;
  cursor: pointer;
`;

export default PrimaryHeaderButton;
