import { ReactElement, useContext } from 'react';

import Content from 'styled-components/Content';
import Header from 'styled-components/Header';
import HeaderTitle from 'styled-components/HeaderTitle';
import SingleActionButton from 'components/ActionButtons/SingleActionButton';

import { CurrentUserContext } from 'App';

import { offWhite, lightBlue, darkBlue } from 'styles/colors';
import StackedActionButtons from 'components/ActionButtons/StackedActionButtons';
import styled from 'styled-components';
import BackButton from 'components/BackButton';
import useHandleInviteClick from 'components/useHandleInviteClick';

const Wrapper = styled.div`
  padding: 16px 16px 48px;
`;

function Menu(): ReactElement {
  const currentUser = useContext(CurrentUserContext);

  const showAdminMenuOptions = currentUser?.isAdmin;

  const [handleInviteClick] = useHandleInviteClick();

  return (
    <>
      <Header>
        <BackButton />
        <HeaderTitle>{'Menu'}</HeaderTitle>
      </Header>
      <Content backgroundColor={offWhite}>
        <Wrapper>
          <SingleActionButton onClick={handleInviteClick} text={'Add or invite friends'} textColor={darkBlue} />

          <StackedActionButtons
            actionButtons={[
              {
                text: 'Profile',
                to: '/profile',
                showCaret: true,
              },
              {
                text: 'Friends',
                to: '/friends',
                showCaret: true,
              },
              {
                text: 'My Events',
                to: '/events/mine',
                showCaret: true,
              },
            ]}
          />

          <SingleActionButton href={'mailto:jarret@homepostapp.com?subject=Careers at Homepost'} text={'Contact us'} showCaret />

          {showAdminMenuOptions && <SingleActionButton to={'/admin'} text={'Admin'} showCaret />}

          <SingleActionButton to={'/logout'} text={'Logout'} showCaret />
          <SingleActionButton to={'/delete-account'} text={'Delete Account'} showCaret />

          <SingleActionButton onClick={() => {}} text={'Current version: 4.0'} />
        </Wrapper>
      </Content>
    </>
  );
}

export default Menu;
