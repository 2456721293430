import { track as trackAmplitudeEvent } from '@amplitude/analytics-browser';
import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';

async function trackEvent(eventName: string, eventProperties: any): Promise<void> {
  if (window.cordova) {
    const response = await AppTrackingTransparency.getStatus();

    if (response?.status !== 'authorized') return;
  }

  if (process.env.NODE_ENV === 'production') {
    trackAmplitudeEvent(eventName, eventProperties);
  } else {
    console.debug('AMPLITUDE EVENT LOGGED:', eventName, eventProperties);
  }
}

function track(eventName: string, eventProperties: any): void {
  trackEvent(eventName, eventProperties).catch((err) => {
    console.log('Tracking Error: ', err.toString());
  });
}

export default track;
