import { AdvancedImage } from '@cloudinary/react';
import { ReactElement } from 'react';

import { cld } from '../../index';

export interface ImageProps {
  id?: string;
  onClick?: () => void;
  path: string;
}

function Image({ id, onClick = () => {}, path }: ImageProps): ReactElement {
  const myImage = cld.image(path);

  return <AdvancedImage id={id} cldImg={myImage} onClick={onClick} />;
}

export default Image;
