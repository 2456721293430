import styled from 'styled-components';

import Avatar from 'components/Avatar';
import BackButton from 'components/BackButton';
import BlueLink from 'styled-components/BlueLink';
import Content from 'styled-components/Content';
import Header from 'styled-components/Header';
import HeaderTitle from 'styled-components/HeaderTitle';

import { useConnectedUsersByCurrentUserQuery } from 'generated';

import { black, gray, offWhite, white } from 'styles/colors';
import { ReactElement } from 'react';

const List = styled.ul`
  list-style: none;
`;

const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid ${gray};
  background: ${white};
`;

const UserContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  color: ${black};
  margin-left: 8px;
  width: calc(100% - 72px);
  overflow: hidden;
`;

const UserName = styled.div``;

function Users(): ReactElement {
  const { error, data, loading } = useConnectedUsersByCurrentUserQuery({
    fetchPolicy: 'cache-and-network',
  });

  if (error) return <p>Error :(</p>;

  if (loading) return <div />;

  const users = data?.connectedUsersByCurrentUser || [];

  return (
    <>
      <Header>
        <BackButton />
        <HeaderTitle>{'Friends'}</HeaderTitle>
      </Header>
      <Content backgroundColor={offWhite}>
        <List>
          {users.map((user) => (
            <ListItem key={user?._id}>
              <Avatar path={user?.profileImage?.path} size={40} text={user?.firstName?.slice(0, 1)} />
              <UserContent>
                <UserName>{user?.firstName}</UserName>
                <BlueLink to={`/friends/${user?._id}`} state={{ from: 'view_neighbors' }}>
                  {'View Profile'}
                </BlueLink>
              </UserContent>
            </ListItem>
          ))}
        </List>
      </Content>
    </>
  );
}

export default Users;
