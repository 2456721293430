export const black = '#000000';

export const darkBlue = '#2486B0';
export const lightBlue = '#2fade4';

export const darkGray = '#798389';
export const gray = '#eeeff1';
export const lightGray = '#e5e5e5';
export const paleGray = '#e0e0e0';

export const white = '#ffffff';
export const offWhite = '#fafafa';

export const darkRed = '#b0243c';
