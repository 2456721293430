import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { reset } from 'store/listView';

function RouteListener(): null {
  const dispatch = useDispatch();
  const location = useLocation();

  const [lastPathPrefix, setLastPathPrefix] = useState<string>();

  useEffect(() => {
    const pathPrefix = location?.pathname?.split('/')?.[1];

    if (lastPathPrefix && lastPathPrefix !== pathPrefix) {
      dispatch(reset());
    }

    setLastPathPrefix(pathPrefix);
  }, [location, lastPathPrefix, dispatch]);

  return null;
}

export default RouteListener;
