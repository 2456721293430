import { Fragment, ReactElement, useEffect, useRef } from 'react';
import { Link, useNavigationType } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import { groupBy } from 'lodash';

import useShowLoading from 'components/useShowLoading';
import useTrackOnData from 'components/useTrackOnData';
import PostSkeleton from 'components/Skeletons/PostSkeleton';

import { CurrentUser, useCalendarEventsByCurrentUserQuery } from 'generated';

import { black, darkGray, gray, lightBlue, white } from 'styles/colors';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import Content from 'styled-components/Content';
import { onScroll } from 'store/listView';

import { Helmet } from 'react-helmet';

import BottomNav from 'components/BottomNav';
import Header from 'styled-components/Header';
import Image from 'components/Image';

import HeaderTitle from 'styled-components/HeaderTitle';
import Avatar from 'components/Avatar';

const Container = styled.div``;

interface EventsProps {
  currentUser?: CurrentUser | null;
}

const List = styled.ul`
  list-style: none;
  padding: 0 0 48px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid ${gray};
`;

const EventInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
`;

const EventLocationAndTime = styled.div`
  color: ${black};
  font-size: 15px;
  margin-bottom: 2px;
`;

const EventName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  width: calc(100% - 16px);
  text-overflow: ellipsis;
  color: ${black};
  font-weight: bold;
  margin-bottom: 2px;
`;

const EventPostedBy = styled.div`
  color: ${darkGray};
  font-size: 15px;
`;

const DateDelimiter = styled.div`
  width: 100%;
  padding: 16px 16px 0 16px;
  display: flex;
  flex-direction: row;
`;

const Day = styled.div`
  color: ${lightBlue};
  font-weight: 600;
  margin-right: 8px;
`;

const DateMeta = styled.div`
  color: ${darkGray};
`;

const EmptyText = styled.div`
  padding: 16px;
  border-radius: 14px;
  background: ${white};
  margin: 16px;
`;

const EventOwner = styled.div`
  margin-right: 16px;
`;

function dateToDate(dateString: string): string {
  return moment(dateString, 'YYYY-MM-DD').format('MMM D, YYYY');
}

function dateToDay(dateString: string): string {
  return moment(dateString, 'YYYY-MM-DD').calendar({
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    lastWeek: 'dddd',
    nextWeek: 'dddd',
    sameElse: 'dddd',
  });
}

function Calendar({ currentUser }: EventsProps): ReactElement {
  const navigationType = useNavigationType();

  const dispatch = useDispatch();
  const lastScrollDepth = useSelector((state: RootState) => state?.listItem?.eventsScrollDepth);

  const contentRef = useRef<HTMLInputElement>(null);

  const { error, data, loading } = useCalendarEventsByCurrentUserQuery({
    fetchPolicy: navigationType === 'POP' ? 'cache-and-network' : 'network-only',
  });

  useTrackOnData(
    'Calendar Viewed',
    {
      number_of_events: data?.calendarEventsByCurrentUser?.length,
    },
    data,
  );

  const showLoading = useShowLoading(loading);

  useEffect(() => {
    if (lastScrollDepth && !loading && contentRef?.current) {
      contentRef.current?.scrollTo({ top: lastScrollDepth, left: 0 });
    }
  }, [lastScrollDepth, loading, contentRef]);

  if (error) return <p>Error :(</p>;

  const eventsGroupedByDate = groupBy(
    (data?.calendarEventsByCurrentUser ?? []).map((event) => ({
      ...event,
      startDate: moment(event?.start).format('YYYY-MM-DD'),
    })),
    'startDate',
  );

  const handleEventClick = (): void => {
    if (contentRef?.current?.scrollTop) {
      dispatch(
        onScroll({
          target: 'events',
          scrollDepth: contentRef?.current?.scrollTop,
        }),
      );
    }
  };

  return (
    <Container>
      <Header>
        <div style={{ width: '24px' }} />
        <HeaderTitle style={{ lineHeight: 0 }}>
          <Link style={{ lineHeight: 0 }} to={'/'}>
            <Image path={'/logos/home_post_logo_dark'} />
          </Link>
        </HeaderTitle>
      </Header>

      <Content ref={contentRef}>
        {showLoading && (
          <div>
            <PostSkeleton />
            <PostSkeleton />
            <PostSkeleton />
          </div>
        )}
        {!showLoading && !!Object.keys(eventsGroupedByDate)?.length && (
          <div>
            <List>
              {Object.entries(eventsGroupedByDate).map(([date, events]) => (
                <Fragment key={date}>
                  <DateDelimiter>
                    <Day>{dateToDay(date)}</Day>
                    <DateMeta>{dateToDate(date)}</DateMeta>
                  </DateDelimiter>
                  {events.map((event) => (
                    <StyledLink onClick={handleEventClick} key={event?._id} to={`/events/${event?._id}`}>
                      <ListItem>
                        <EventOwner>
                          <Avatar size={36} path={event?.createdBy?.profileImage?.path} text={event?.createdBy?.firstName ?? ''} />
                        </EventOwner>
                        <EventInfo>
                          <EventName>{event?.name}</EventName>
                          <EventLocationAndTime>{`${moment(event.start).format('h:mm a')} @ ${
                            event?.locationName ?? event?.formattedAddress?.split(',')?.[0] ?? ''
                          }`}</EventLocationAndTime>
                          <EventPostedBy>
                            <>
                              {`from ${event?.createdBy?.firstName ?? ''}`}
                              {(event?.attendance?.attendingUserCount ?? 0) > 1
                                ? ` + ${(event?.attendance?.attendingUserCount ?? 2) - 1} more`
                                : ''}
                            </>
                          </EventPostedBy>
                        </EventInfo>
                      </ListItem>
                    </StyledLink>
                  ))}
                </Fragment>
              ))}
            </List>
          </div>
        )}

        {!showLoading && !Object.keys(eventsGroupedByDate)?.length && (
          <EmptyText>
            {'You do not have any upcoming events. Check out the events feed to find something interesting to add to your calendar!'}
          </EmptyText>
        )}
      </Content>
      <BottomNav />
      <Helmet>
        <meta charSet="utf-8" />
        <title>{'Calendar'}</title>
      </Helmet>
    </Container>
  );
}

export default Calendar;
