import { useEffect, useRef, useState } from 'react';

function useShowLoading(loading: boolean) {
  const [loadingTimeoutEclipsed, setLoadingTimeoutEclipsed] = useState<boolean>(false);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      setLoadingTimeoutEclipsed(true);
    }, 250);

    return () => clearTimeout(timeoutRef.current);
  }, []);

  useEffect(() => {
    if (!loading) {
      clearTimeout(timeoutRef.current);
    }
  }, [loading]);

  return loadingTimeoutEclipsed && loading;
}

export default useShowLoading;
