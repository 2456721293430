import styled from 'styled-components';

export type FormProps = {
  marginTop?: number;
};

const Form = styled.form<FormProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 0)}px;
  width: 100%;
`;

export default Form;
