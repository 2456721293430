import styled from 'styled-components';

import { darkBlue, darkGray, lightGray, white } from 'styles/colors';

export interface BlueButtonProps {
  disabled?: boolean;
  small?: boolean;
}

const BlueButton = styled.button<BlueButtonProps>`
  background: ${(props) => (props?.disabled ? lightGray : darkBlue)};
  color: ${(props) => (props?.disabled ? darkGray : white)};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  padding: ${(props) => (props?.small ? '8px 16px' : '12px 60px')};
  border-radius: 4px;
  min-width: ${(props) => (props?.small ? 'none' : '180px')};
  border: 0;
  outline: 0;
  cursor: pointer;
`;

export default BlueButton;
