import { useContext, useEffect, useState, FormEvent, ReactElement } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import TextareaAutosize from 'react-textarea-autosize';

import BackButton from 'components/BackButton';
import Content from 'styled-components/Content';
import Form from 'styled-components/Form';
import Header from 'styled-components/Header';
import HeaderTitle from 'styled-components/HeaderTitle';
import IconWrapper from 'styled-components/IconWrapper';
import Image from 'components/Image';
import InputWrapper from 'styled-components/InputWrapper';
import SaveButton from 'styled-components/SaveButton';

import { CurrentUserContext } from 'App';

import { CreateImageInput, CurrentUser, useCreateImageMutation, useJoinGroupMutation, useUpdateUserMutation } from 'generated';

import { black, lightGray, offWhite, white } from 'styles/colors';
import useCloudinaryWidget from 'components/useCloudinaryWidget/useCloudinaryWidget';
import useTrackOnMount from 'components/useTrackOnMount';
import track from 'services/track';
import Body from 'styled-components/Body';
import { useNavigate, useSearchParams } from 'react-router-dom';

const ProfileImageContainer = styled.div`
  background: ${white};
  padding: 16px 16px 0;
  border-radius: 14px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProfileImageWrapper = styled.div`
  width: 128px;
  height: 128px;
  margin-bottom: 16px;

  > div {
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
  }

  img {
    width: 128px;
    height: 128px;
  }
`;

const ProfileImagePlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 128px;
  height: 128px;
  background: ${lightGray};
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  margin-bottom: 16px;

  > div {
    margin-bottom: 0;
  }
`;

const StyledContent = styled(Content)`
  padding-top: 24px;
`;

export interface UserProfileProps {
  hideBack?: boolean;
  showContinue?: boolean;
}

function UserProfile({ hideBack = false, showContinue = false }: UserProfileProps): ReactElement {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const genericInvitationId = searchParams.get('genericInvitationId') || '';

  const currentUser = useContext<CurrentUser | null>(CurrentUserContext);

  const [fullName, setFullName] = useState<string>(currentUser?.firstName ? `${currentUser.firstName} ${currentUser?.lastName ?? ''}` : '');
  const [profileImage, setProfileImage] = useState<CreateImageInput | undefined>(undefined);
  const [profileImageId, setProfileImageId] = useState<string | undefined>(undefined);
  const [aboutMe, setAboutMe] = useState<string>(currentUser?.aboutMe ?? '');

  const [profileNameTracked, setProfileNameTracked] = useState<boolean>(false);

  const currentProfileImagePath = profileImage?.path ?? currentUser?.profileImage?.path;

  const ctaDisabled = !fullName;

  useTrackOnMount(!!fullName ? 'Profile Setup Screen Viewed' : 'Edit Profile Viewed', {});

  const [joinGroup] = useJoinGroupMutation();

  const [updateUser] = useUpdateUserMutation({
    onCompleted: () => {
      if (hideBack) {
        if (genericInvitationId) {
          joinGroup({
            onCompleted: () => {
              navigate('/events');
            },
            variables: {
              input: {
                groupId: genericInvitationId,
              },
            },
          });
        } else {
          navigate('/events');
        }
      } else {
        toast.success('Profile updated');
      }
    },
    refetchQueries: ['CurrentUser', 'Posts'],
    variables: {
      input: {
        aboutMe,
        fullName,
        profileImageId,
      },
    },
  });

  const [createImage] = useCreateImageMutation({
    onCompleted: (data) => {
      setProfileImageId(data?.createImage?.image?._id);
    },
    variables: {
      input: profileImage ?? {},
    },
  });

  // When the profileImageId changes, we know the createImage mutation
  // was successful
  useEffect(() => {
    if (!!profileImageId) {
      track('Profile Photo Uploaded', {});
    }
  }, [profileImageId, updateUser]);

  // When the cloudinary widget changes, call the createImage mutation
  useEffect(() => {
    if (profileImage?.cloudinaryId) {
      createImage().catch((err) => console.log(err));
    }
  }, [profileImage, createImage]);

  useEffect(() => {
    if (!profileNameTracked && !!fullName) {
      track('Profile Name Entered', {});
      setProfileNameTracked(() => true);
    }
  }, [fullName, profileNameTracked]);

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    updateUser().catch((err) => console.log(err));
  };

  const handleSubmit = (): void => {
    updateUser().catch((err) => console.log(err));
  };

  const { openCloudinaryWidget } = useCloudinaryWidget({
    // ToDo: Fix this
    folder: `uploads/${currentUser?._id ?? 'unknown'}`,
    onChange: (value: any) => setProfileImage(value),
  });

  return (
    <>
      <Header>
        {!hideBack && <BackButton />}
        {hideBack && <div />}
        <HeaderTitle>{'Your Profile'}</HeaderTitle>
        <SaveButton disabled={ctaDisabled} onClick={handleSubmit}>
          {showContinue ? 'Continue' : 'Save'}
        </SaveButton>
      </Header>
      <StyledContent backgroundColor={offWhite} padding={16}>
        <ProfileImageContainer>
          <ProfileImageWrapper>
            {!!currentProfileImagePath && (
              <ProfileImageWrapper>
                <Image path={currentProfileImagePath} onClick={() => openCloudinaryWidget()} />
              </ProfileImageWrapper>
            )}
            {!currentProfileImagePath && (
              <ProfileImagePlaceholder onClick={() => openCloudinaryWidget()}>
                <IconWrapper color={black} size={48}>
                  <span className={'material-symbols-outlined'}>{'add_a_photo'}</span>
                </IconWrapper>
                <Body style={{ transform: 'translateX(-2px)' }}>{'Add'}</Body>
              </ProfileImagePlaceholder>
            )}
          </ProfileImageWrapper>
          <Body small>{'We recommend you choose a profile pic that clearly shows your face to build trust with your community'}</Body>
        </ProfileImageContainer>

        <Form onSubmit={handleFormSubmit} marginTop={32}>
          <InputWrapper>
            <input id={'fullName'} onChange={(e) => setFullName(e.currentTarget.value)} placeholder={'Full name'} value={fullName} />
          </InputWrapper>

          <InputWrapper>
            <TextareaAutosize
              id={'quickBio'}
              onChange={(e) => setAboutMe(e.currentTarget.value)}
              defaultValue={aboutMe}
              placeholder={'Quick bio'}
            />
          </InputWrapper>
        </Form>
      </StyledContent>
    </>
  );
}

export default UserProfile;
