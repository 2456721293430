import styled from 'styled-components';

import { darkBlue } from 'styles/colors';

const SaveButton = styled.button`
  text-decoration: none;
  color: ${(props) => (props.disabled ? 'rgba(0, 0, 0, 0.3)' : darkBlue)};
  background: transparent;
  border: 0;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  cursor: pointer;
`;

export default SaveButton;
