import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { darkBlue } from 'styles/colors';

const BlueLink = styled(Link)`
  text-decoration: none;
  color: ${darkBlue};
  cursor: pointer;
`;

export default BlueLink;
