import styled from 'styled-components';

import { gray, white } from 'styles/colors';

interface HeaderProps {
  hideBottomBorder?: boolean;
}

const Header = styled.div<HeaderProps>`
  position: sticky;
  top: 0;
  background: ${white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  height: 54px;
  border-bottom: ${(props) => (props.hideBottomBorder ? 'none' : `2px solid ${gray}`)};
  width: 100%;
  z-index: 1;
  min-height: 54px;
`;

export default Header;
