const URL_WITHOUT_PROTOCOL_REGEX = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g;

function convertLineBreaksToParagraphs(body: string) {
  return body
    .split(/\r\n|\r|\n/gi)
    .map((text) => `<p>${text}</p>`)
    .reduce((acc, cur) => `${acc}${cur}`)
    .replace(/https?:\/\//g, '')
    .replace(/http?:\/\//g, '')
    .replace(URL_WITHOUT_PROTOCOL_REGEX, (link) => {
      if (link.includes('@')) {
        return `<a href="mailto:${link}">${link}</a>`;
      }
      return `<a href="https://${link}">${link}</a>`;
    });
}

export default convertLineBreaksToParagraphs;
