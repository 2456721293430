import { ReactElement } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { black, darkGray, white } from 'styles/colors';
import IconWrapper from 'styled-components/IconWrapper';

interface StyledLinkProps {
  color: string;
}

const StyledAnchor = styled.a<StyledLinkProps>`
  width: 100%;
  padding: 16px;
  color: ${(props) => props.color};
  background: ${white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  border-radius: 14px;
  cursor: pointer;

  a {
    color: ${(props) => props.color};
    text-decoration: none;
  }
`;

const StyledLink = styled(Link)<StyledLinkProps>`
  width: 100%;
  padding: 16px;
  color: ${(props) => props.color};
  background: ${white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  border-radius: 14px;
`;

const StyledButton = styled.button<StyledLinkProps>`
  width: 100%;
  padding: 16px;
  color: ${(props) => props.color};
  background: ${white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  border-radius: 14px;
  font-size: 16px;
  outline: 0;
  border: 0;
`;

export interface SingleActionButtonProps {
  to?: string;
  href?: string;
  onClick?: () => void;
  onToggle?: () => void;
  showCaret?: boolean;
  text: string;
  textColor?: string;
  toggleValue?: boolean;
}

function SingleActionButton({
  href,
  to,
  onClick,
  onToggle,
  toggleValue,
  showCaret = false,
  text,
  textColor = black,
}: SingleActionButtonProps): ReactElement {
  if (onToggle) {
    return (
      <StyledButton onClick={onToggle} color={textColor}>
        <div>{text}</div>
        {toggleValue === true && (
          <IconWrapper color={darkGray} size={24}>
            <span className={'material-symbols-rounded'}>{'radio_button_checked'}</span>
          </IconWrapper>
        )}
        {toggleValue === false && (
          <IconWrapper color={darkGray} size={24}>
            <span className={'material-symbols-rounded'}>{'radio_button_unchecked'}</span>
          </IconWrapper>
        )}
      </StyledButton>
    );
  }

  if (to) {
    return (
      <StyledLink color={textColor} to={to}>
        <div>{text}</div>
        {showCaret && (
          <IconWrapper color={darkGray} size={24}>
            <span className={'material-symbols-rounded'}>{'chevron_right'}</span>
          </IconWrapper>
        )}
      </StyledLink>
    );
  }

  if (href) {
    return (
      <StyledAnchor color={textColor} href={href} target={'_blank'} rel={'noreferrer'}>
        <div>{text}</div>
        {showCaret && (
          <IconWrapper color={darkGray} size={24}>
            <span className={'material-symbols-rounded'}>{'chevron_right'}</span>
          </IconWrapper>
        )}
      </StyledAnchor>
    );
  }

  return (
    <StyledButton onClick={onClick} color={textColor}>
      <div>{text}</div>
      {showCaret && (
        <IconWrapper color={darkGray} size={24}>
          <span className={'material-symbols-rounded'}>{'chevron_right'}</span>
        </IconWrapper>
      )}
    </StyledButton>
  );
}

export default SingleActionButton;
