import styled from 'styled-components';

const SubText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #798389;
  margin-bottom: 40px;
`;

export default SubText;
