import styled from 'styled-components';
import { black, darkGray, white } from 'styles/colors';

export interface InputWrapperProps {
  centered?: boolean;
}

const InputWrapper = styled.div<InputWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props?.centered ? 'center' : 'flex-start')};
  width: 100%;
  text-align: initial;
  position: relative;

  label {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: ${black};
    margin-bottom: 16px;
  }

  input,
  select,
  textarea {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: ${black};
    padding-bottom: 8px;
    border: 0;
    outline: 0;
    margin-bottom: 24px;
    border-bottom: 0;
    width: 100%;
    background: ${white};
    border-radius: 7px;
  }

  select,
  textarea {
    padding: 16px;
  }

  input {
    padding: 8px 16px;
  }

  select {
    text-align: right !important;
    text-align-last: right;
    color: ${darkGray};
    height: 54px !important;
  }

  .select-label {
    position: absolute;
    top: 16px;
    left: 16px;
  }

  .select-label-with-label {
    position: absolute;
    top: 48px;
    left: 16px;
  }
`;

export default InputWrapper;
