import { Fragment, ReactElement, useCallback, useEffect, useRef } from 'react';
import { Link, useNavigationType } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import { App } from '@capacitor/app';
import OneSignal from 'onesignal-cordova-plugin';

import useShowLoading from 'components/useShowLoading';
import useTrackOnData from 'components/useTrackOnData';
import PostSkeleton from 'components/Skeletons/PostSkeleton';

import { CurrentUser, useEventsByCurrentUserQuery, useEventsByCurrentUserLazyQuery } from 'generated';

import { black, darkGray, white, offWhite, lightBlue, gray } from 'styles/colors';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import Content from 'styled-components/Content';
import { onScroll } from 'store/listView';

import { Helmet } from 'react-helmet';

import Header from 'styled-components/Header';
import Image from 'components/Image';

import HeaderTitle from 'styled-components/HeaderTitle';
import IconWrapper from 'styled-components/IconWrapper';
import Avatar from 'components/Avatar';
import useHandleInviteClick from 'components/useHandleInviteClick';
import { groupBy } from 'lodash';

interface EventsProps {
  currentUser?: CurrentUser | null;
}

const List = styled.ul`
  list-style: none;
  background: ${white};
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;
const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid ${gray};
`;

const EventContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  color: ${black};
  width: calc(100% - 72px);
  overflow: hidden;
  margin-left: 16px;
`;

export interface EventNameProps {
  unread?: boolean;
}

const EventTimeAndLocation = styled.div`
  color: ${black};
  font-size: 15px;
`;

const EventName = styled.div<EventNameProps>`
  white-space: nowrap;
  overflow: hidden;
  width: calc(100% - 16px);
  text-overflow: ellipsis;
  font-weight: ${(props) => (props?.unread ? '600' : '400')};
`;

const DateDelimiter = styled.div`
  width: 100%;
  padding: 16px 16px 0 16px;
  display: flex;
  flex-direction: row;
`;

const Day = styled.div`
  color: ${lightBlue};
  font-weight: 600;
  margin-right: 8px;
`;

const DateMeta = styled.div`
  color: ${darkGray};
`;

const AttendanceLine = styled.div`
  font-size: 15px;
  color: ${darkGray};
`;

export interface EventAttendanceIndicatorProps {
  isActive?: boolean;
}

const EventAttendanceIndicator = styled.div<EventAttendanceIndicatorProps>`
  visibility: ${(props) => (props?.isActive ? 'visible' : 'hidden')};
`;

const EmptyText = styled.div`
  color: ${darkGray};
`;

const EventsFooter = styled.div`
  width: 100%;
  padding: 16px 16px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  > button {
    margin-top: 16px;
  }
`;

function Events({ currentUser }: EventsProps): ReactElement {
  const navigationType = useNavigationType();

  const dispatch = useDispatch();
  const lastScrollDepth = useSelector((state: RootState) => state?.listItem?.eventsScrollDepth);

  const contentRef = useRef<HTMLInputElement>(null);

  const [handleInviteClick] = useHandleInviteClick();

  const [refetchEvents, { error: refetchEventsError, data: refetchEventsData, loading: refetchEventsLoading }] =
    useEventsByCurrentUserLazyQuery({
      fetchPolicy: 'no-cache',
    });

  const handleRefetchEvents = useCallback((): void => {
    refetchEvents().catch((err) => {
      console.log(err);
    });
  }, [refetchEvents]);

  const { error, data, loading } = useEventsByCurrentUserQuery({
    fetchPolicy: navigationType === 'POP' ? 'cache-and-network' : 'network-only',
  });

  useTrackOnData(
    'Event Board Viewed',
    {
      number_of_events: data?.eventsByCurrentUser?.length,
    },
    data,
  );

  useEffect(() => {
    if (window.cordova) {
      // Prompts the user for notification permissions.
      //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 7) to better communicate to your users what notifications they will get.
      OneSignal.Notifications.requestPermission(true)
        .then((accepted: boolean) => {
          console.log('User accepted notifications');
        })
        .catch((err: Error) => {
          console.log(`User did not accept? ${err.toString()}`);
        });
    }
  }, []);

  useEffect(() => {
    if (lastScrollDepth && !loading && contentRef?.current) {
      contentRef.current?.scrollTo({ top: lastScrollDepth, left: 0 });
    }
  }, [lastScrollDepth, loading, contentRef]);

  useEffect(() => {
    App.addListener('resume', handleRefetchEvents).catch((err) => {
      console.log(err);
    });

    return () => {
      App.removeAllListeners().catch((err) => {
        console.log(err);
      });
    };
  }, [handleRefetchEvents]);

  const anyError = error || refetchEventsError;
  const anyLoading = loading || refetchEventsLoading;

  const showLoading = useShowLoading(anyLoading);

  if (anyError) return <p>Error :(</p>;

  const anyEvents = refetchEventsData?.eventsByCurrentUser || data?.eventsByCurrentUser || [];

  const activeEvents = anyEvents;

  const handleEventClick = (): void => {
    if (contentRef?.current?.scrollTop) {
      dispatch(
        onScroll({
          target: 'events',
          scrollDepth: contentRef?.current?.scrollTop,
        }),
      );
    }
  };

  const eventsGroupedByDate = groupBy(
    activeEvents.map((event) => ({
      ...event,
      startDate: moment(event?.start).format('YYYY-MM-DD'),
    })),
    'startDate',
  );

  function dateToDate(dateString: string): string {
    return moment(dateString, 'YYYY-MM-DD').format('MMM D, YYYY');
  }

  function dateToDay(dateString: string): string {
    return moment(dateString, 'YYYY-MM-DD').calendar({
      lastDay: '[Yesterday]',
      sameDay: '[Today]',
      nextDay: '[Tomorrow]',
      lastWeek: 'dddd',
      nextWeek: 'dddd',
      sameElse: 'dddd',
    });
  }

  return (
    <>
      <Header>
        <Link to={'/menu'}>
          <IconWrapper color={black} size={24}>
            <span className={'material-symbols-rounded'}>{'account_circle'}</span>
          </IconWrapper>
        </Link>
        <HeaderTitle style={{ lineHeight: 0 }}>
          <Link style={{ lineHeight: 0 }} to={'/'}>
            <Image path={'/logos/home_post_logo_dark'} />
          </Link>
        </HeaderTitle>
        <IconWrapper color={black} size={24} onClick={handleInviteClick}>
          <span className={'material-symbols-rounded'}>{'person_add'}</span>
        </IconWrapper>
      </Header>

      <Content ref={contentRef} backgroundColor={offWhite}>
        {showLoading && (
          <div>
            <PostSkeleton />
            <PostSkeleton />
            <PostSkeleton />
          </div>
        )}
        {!showLoading && !!activeEvents?.length && (
          <div>
            <List>
              {Object.entries(eventsGroupedByDate).map(([date, events]) => (
                <Fragment key={date}>
                  <DateDelimiter>
                    <Day>{dateToDay(date)}</Day>
                    <DateMeta>{dateToDate(date)}</DateMeta>
                  </DateDelimiter>
                  {events.map((event) => (
                    <StyledLink onClick={handleEventClick} key={event?._id} to={`/events/${event?._id ?? ''}`}>
                      <ListItem>
                        <Avatar path={event?.createdBy?.profileImage?.path} text={event?.createdBy?.firstName || ''} />
                        <EventContent>
                          <EventName unread={!event?.viewed}>{event?.name}</EventName>
                          <EventTimeAndLocation>{`${moment(event.start).format('h:mm a')} @ ${
                            event?.locationName || event?.formattedAddress?.split(',')?.[0] || ''
                          }`}</EventTimeAndLocation>
                          <AttendanceLine>
                            {`with ${event?.createdBy?.firstName || ''}`}
                            {event?.attendance?.attendingUserCount === 1
                              ? ''
                              : ` and ${(event?.attendance?.attendingUserCount || 2) - 1} more`}
                          </AttendanceLine>
                        </EventContent>
                        <EventAttendanceIndicator isActive={event?.attendance?.currentUserIsAttending}>
                          <IconWrapper color={white} style={{ borderRadius: '50%', backgroundColor: lightBlue }} size={20}>
                            <span className={'material-symbols-outlined'}>{'check'}</span>
                          </IconWrapper>
                        </EventAttendanceIndicator>
                      </ListItem>
                    </StyledLink>
                  ))}
                </Fragment>
              ))}
            </List>
          </div>
        )}

        <EventsFooter>
          <EmptyText>{'People like you help build stronger communities by sharing this app.'}</EmptyText>
        </EventsFooter>
      </Content>

      <Helmet>
        <meta charSet="utf-8" />
        <title>{'Calendar'}</title>
      </Helmet>
    </>
  );
}

export default Events;
