import { ReactElement, useEffect, useRef } from 'react';
import { Link, useNavigationType } from 'react-router-dom';
import styled from 'styled-components';

import useShowLoading from 'components/useShowLoading';
import useTrackOnData from 'components/useTrackOnData';
import PostSkeleton from 'components/Skeletons/PostSkeleton';

import { CurrentUser, useGroupsByCurrentUserQuery } from 'generated';

import { black, darkGray, white, offWhite, lightBlue, gray } from 'styles/colors';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import Content from 'styled-components/Content';
import { onScroll } from 'store/listView';

import { Helmet } from 'react-helmet';

import Header from 'styled-components/Header';
import Image from 'components/Image';

import HeaderTitle from 'styled-components/HeaderTitle';
import IconWrapper from 'styled-components/IconWrapper';
import Avatar from 'components/Avatar';
import BlueLink from 'styled-components/BlueLink';
import BackButton from 'components/BackButton';
import MultiAvatar from 'components/MultiAvatar';

interface GroupsProps {
  currentUser?: CurrentUser | null;
}

const List = styled.ul`
  list-style: none;
  background: ${white};
  border-radius: 8px;

  li {
    border-bottom: 1px solid ${gray};
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  outline: 0;
  border: 0;
`;
const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  width: 100%;
`;

const GroupContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  color: ${black};
  width: calc(100% - 72px);
  overflow: hidden;
  margin-left: 16px;
`;

const Group = styled.button``;

export interface GroupNameProps {
  unread?: boolean;
}

const GroupName = styled.div<GroupNameProps>`
  white-space: nowrap;
  overflow: hidden;
  width: calc(100% - 16px);
  text-overflow: ellipsis;
  font-weight: ${(props) => (props?.unread ? '600' : '400')};
`;

const GroupDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

function Groups({ currentUser }: GroupsProps): ReactElement {
  const navigationType = useNavigationType();

  const dispatch = useDispatch();
  const lastScrollDepth = useSelector((state: RootState) => state?.listItem?.groupsScrollDepth);

  const contentRef = useRef<HTMLInputElement>(null);

  const { error, data, loading } = useGroupsByCurrentUserQuery({
    fetchPolicy: navigationType === 'POP' ? 'cache-and-network' : 'network-only',
  });

  useTrackOnData(
    'Groups Viewed',
    {
      number_of_groups: data?.groupsByCurrentUser?.length,
    },
    data,
  );

  useEffect(() => {
    if (lastScrollDepth && !loading && contentRef?.current) {
      contentRef.current?.scrollTo({ top: lastScrollDepth, left: 0 });
    }
  }, [lastScrollDepth, loading, contentRef]);

  const showLoading = useShowLoading(loading);

  if (error) return <p>Error :(</p>;

  const groups = data?.groupsByCurrentUser || [];
  const connectionCount = currentUser?.connectionCount?.toString() || '';

  const handleGroupClick = (): void => {
    if (contentRef?.current?.scrollTop) {
      dispatch(
        onScroll({
          target: 'groups',
          scrollDepth: contentRef?.current?.scrollTop,
        }),
      );
    }
  };

  return (
    <>
      <Header>
        <BackButton />
        <HeaderTitle style={{ lineHeight: 0 }}>{'Friends'}</HeaderTitle>
        <BlueLink to={'/groups/new'}>{'Create Group'}</BlueLink>
      </Header>

      <Content ref={contentRef} backgroundColor={offWhite}>
        {showLoading && (
          <div>
            <PostSkeleton />
            <PostSkeleton />
            <PostSkeleton />
          </div>
        )}
        {!showLoading && (
          <div style={{ padding: 16 }}>
            <List>
              {!!connectionCount && (
                <StyledLink onClick={handleGroupClick} key={'all'} to={`/groups/all`}>
                  <ListItem>
                    <GroupContent>
                      <GroupName>{'All Friends'}</GroupName>
                    </GroupContent>
                    <Avatar longText={connectionCount} size={36} />
                    <IconWrapper color={darkGray} size={24}>
                      <span className={'material-symbols-rounded'}>{'chevron_right'}</span>
                    </IconWrapper>
                  </ListItem>
                </StyledLink>
              )}
              {groups.map((group) => (
                <StyledLink onClick={handleGroupClick} key={group?._id} to={`/groups/${group?._id ?? ''}`}>
                  <ListItem>
                    <GroupContent>
                      <GroupName>{group?.name}</GroupName>
                    </GroupContent>
                    <GroupDetails>
                      <div style={{ transform: 'translateX(-14px)' }}>
                        <MultiAvatar users={group?.members || []} maxCount={3} />
                      </div>
                      <IconWrapper color={darkGray} size={24}>
                        <span className={'material-symbols-rounded'}>{'chevron_right'}</span>
                      </IconWrapper>
                    </GroupDetails>
                  </ListItem>
                </StyledLink>
              ))}
            </List>
          </div>
        )}
      </Content>

      <Helmet>
        <meta charSet="utf-8" />
        <title>{'Groups'}</title>
      </Helmet>
    </>
  );
}

export default Groups;
