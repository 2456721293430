import Image from 'components/Image';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Content from 'styled-components/Content';
import Header from 'styled-components/Header';

const Text = styled.div`
  padding: 16px;

  * {
    margin-bottom: 16px;
  }
`;

function Support(): ReactElement {
  return (
    <>
      <Header>
        <div />
        <Link style={{ lineHeight: 0 }} to={'/'}>
          <Image path={'/logos/home_post_logo_dark'} />
        </Link>
        <div />
      </Header>
      <Content>
        <Text>
          <h2>{'Support'}</h2>
          <p>{'Thank you for your interest in Homepost!'}</p>
          <p>
            {'If you are interested in providing us feedback, please reach out via email: '}
            <a href={'mailto:jarret@homepostapp.com?subject=Homepost Feedback'}>{'jarret@homepostapp.com'}</a>
          </p>
          <p>{'We take feedback seriously and will make every effort to respond to you personally within one business day.'}</p>
        </Text>
      </Content>
    </>
  );
}

export default Support;
