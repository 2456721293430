import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { AppsFlyer, AFConstants, AFInit } from 'appsflyer-capacitor-plugin';

function AppsFlyerInit(): void {
  const afConfig: AFInit = {
    appID: '6473383401', // replace with your app ID.
    devKey: 'dmsjZDMgitFuew76NPpoZn', // replace with your dev key.
    isDebug: true,
    waitForATTUserAuthorization: 10, // for iOS 14 and higher
    minTimeBetweenSessions: 6, // default 5 sec
    registerOnDeepLink: true,
    registerConversionListener: true,
    registerOnAppOpenAttribution: false,
    deepLinkTimeout: 4000, // default 3000 ms
    useReceiptValidationSandbox: true, // iOS only
    useUninstallSandbox: true, // iOS only
  };

  AppsFlyer.initSDK(afConfig).then((res: any) => alert(JSON.stringify(res)));
}

function AppUrlListener(): null {
  const navigate = useNavigate();

  useEffect(() => {
    AppsFlyer.addListener(AFConstants.UDL_CALLBACK, (res) => {
      alert('UDL_CALLBACK ~~>' + JSON.stringify(res));
      if (res.status === 'FOUND') {
        const deepLinkValue = res.deepLink.deep_link_value;

        if (deepLinkValue === 'group-invitation') {
          const groupId = res.deepLink.deep_link_sub1;

          if (groupId) {
            navigate(`/group-invitation/${groupId}`);
          }
        }
      } else if (res.status === 'ERROR') {
        console.log('udl error: ' + res.error);
      }
    });

    AppsFlyerInit();
  }, []);

  useEffect(() => {
    const onAppUrlOpen = (event: URLOpenListenerEvent): void => {
      // Example url: https://beerswift.app/tabs/tab2
      // slug = /tabs/tab2
      const { url } = event;

      if (url) {
        if (url.includes('.com')) {
          const slug = url.split('.com').pop();
          if (slug) {
            navigate(slug);
          }
        } else if (url.includes('homepostapp:/')) {
          const slug = url.split('homepostapp:/').pop();
          if (slug) {
            navigate(slug);
          }
        }
      }

      // If no match, do nothing - let regular routing
      // logic take over
    };

    App.addListener('appUrlOpen', onAppUrlOpen).catch((err) => {
      console.log(err);
    });

    return () => {
      App.removeAllListeners().catch((err) => {
        console.log(err);
      });
    };
  }, [navigate]);

  return null;
}

export default AppUrlListener;
