import { useEffect, useState } from 'react';
import track from 'services/track';

function useTrackOnMount(eventName: string, eventProperties: any): void {
  const [didTrack, setDidTrack] = useState<boolean>(false);

  useEffect(() => {
    if (!!eventName && !!eventProperties && !didTrack) {
      track(eventName, eventProperties);
      setDidTrack(() => true);
    }
  }, [didTrack, eventName, eventProperties]);
}

export default useTrackOnMount;
