import { useEffect, useState, FormEvent, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import PhoneInput from 'react-phone-number-input/input';
import { useNavigate, useParams } from 'react-router-dom';
import { RiseLoader } from 'react-spinners';
import { init as initAmplitude } from '@amplitude/analytics-browser';
import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';

import BackButton from 'components/BackButton';
import TextButton from 'styled-components/TextButton';
import Content from 'styled-components/Content';
import Header from 'styled-components/Header';
import HeaderTitle from 'styled-components/HeaderTitle';
import InputWrapper from 'styled-components/InputWrapper';
import Form from 'styled-components/Form';
import Paragraph from 'styled-components/Paragraph';
import SaveButton from 'styled-components/SaveButton';

import track from 'services/track';

import { RootState } from 'store';
import { onPhoneNumberUpdate } from 'store/onboarding';

import { useGenerateAndSendPassCodeMutation, useResolvePassCodeMutation } from 'generated';

import { darkBlue, darkGray, offWhite } from 'styles/colors';
import { Helmet } from 'react-helmet';
import BlueLink from 'styled-components/BlueLink';

const { REACT_APP_AMPLITUDE_KEY = '' } = process.env;

const ContentContainer = styled.div`
  padding: 16px;
`;

const CenteredContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  margin: 24px 0;

  @media screen and (min-width: 768px) {
    max-width: calc(100% - 96px);
    margin: 24px auto;
  }
`;

const HeaderText = styled.h2`
  margin-bottom: 16px;
`;

const WrapLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
`;

const StyledPhoneInput = styled(PhoneInput)`
  font-size: 16px;
  padding: 8px;
  border-radius: 4px;
  outline: 0;
  border: 1px solid #d9d9d9;
  margin-bottom: 16px;
  width: 312px;
`;

const AndroidInput = styled.input`
  font-size: 16px;
  padding: 8px;
  border-radius: 4px;
  outline: 0;
  border: 1px solid #d9d9d9;
  margin-bottom: 12px;
  width: 100%;
  max-width: 320px;

  @media screen and (min-width: 768) {
    width: 320px;
  }
`;

const EULAParagraph = styled(Paragraph)`
  position: fixed;
  bottom: 16px;
  left: 16px;
  color: ${darkGray};
`;

async function AmplitudeInit(): Promise<void> {
  const response = await AppTrackingTransparency.requestPermission();
  if (response?.status === 'authorized') {
    initAmplitude(REACT_APP_AMPLITUDE_KEY);
    track('Login Screen Viewed', {});
  }
}

function Login(): ReactElement {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { genericInvitationId = '' } = useParams();

  const phoneNumber = useSelector((state: RootState) => state.onboarding.phoneNumber) ?? '';

  const [passCode, setPassCode] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [passCodeVerificationError, setPassCodeVerificationError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const [trackedPhoneNumberInput, setTrackedPhoneNumberInput] = useState<boolean>(false);
  const [trackedPassCodeInput, setTrackedPassCodeInput] = useState<boolean>(false);

  const [view, setView] = useState<'phone' | 'code'>('phone');

  useEffect(() => {
    if (window.cordova) {
      AmplitudeInit().catch((err) => {
        console.log('err', err.toString());
      });
    }
  }, []);

  const [generateAndSendPassCode] = useGenerateAndSendPassCodeMutation({
    onCompleted: (data) => {
      if (data.generateAndSendPassCode.error) {
        setLoading(() => false);
        setError(data.generateAndSendPassCode.error);
      } else {
        setTimeout(() => {
          setLoading(() => false);
          setView('code');
        }, 1000);

        if (data.generateAndSendPassCode.success) {
          track('Phone Confirmation Screen Viewed', {});
        }
      }
    },
    variables: {
      input: {
        phoneNumber,
      },
    },
  });

  const [resolvePassCode] = useResolvePassCodeMutation({
    onCompleted: (data) => {
      if (data?.resolvePassCode?.success) {
        track('Phone Confirmation Code Verification Success', {});
        dispatch(onPhoneNumberUpdate(''));
      }
    },
    update: (cache, { data }) => {
      if (data) {
        if (data.resolvePassCode.error) {
          setPassCodeVerificationError(data.resolvePassCode.error);
          track('Phone Confirmation Code Verification Failed', { error: data.resolvePassCode.error });

          setTimeout(() => {
            setLoading(() => false);
          }, 1000);
        } else if (data.resolvePassCode.token) {
          localStorage.setItem('auth_token', data.resolvePassCode.token);

          if (data.resolvePassCode.isNewUser) {
            if (genericInvitationId) {
              navigate(`/complete-profile?genericInvitationId=${genericInvitationId}`);
            } else {
              navigate('/complete-profile');
            }
          } else {
            navigate('/events');
          }
        }
      }
    },
    refetchQueries: ['CurrentUser'],
    variables: {
      input: {
        passCode,
        phoneNumber,
        genericInvitationId,
      },
    },
  });

  const handlePhoneChange = (value: string): void => {
    if (value?.length < 11 || (value?.length === 12 && value[0] === '+')) {
      dispatch(onPhoneNumberUpdate(value));

      if (!trackedPhoneNumberInput) {
        track('Phone Number Entered', {});
        setTrackedPhoneNumberInput(() => true);
      }
    }
  };

  const handlePhoneSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault();

    if (phoneNumber?.length === 11 || (phoneNumber?.length === 12 && phoneNumber[0] === '+')) {
      setLoading(() => true);
      track('Phone Number Submitted', {});
      await generateAndSendPassCode();
    }
  };

  const handleCodeChange = (value: string): void => {
    if (!trackedPassCodeInput) {
      track('Phone Confirmation Code Entered', {});
      setTrackedPassCodeInput(() => true);
    }

    if (value?.length === 6) {
      setPassCode(value);
    }
  };

  const handleBackClick = (): void => {
    setPassCodeVerificationError('');
    setView('phone');
  };

  const handleResendClick = async (): Promise<void> => {
    setPassCode('');
    setPassCodeVerificationError('');
    await generateAndSendPassCode();
  };

  useEffect(() => {
    if (passCode?.length === 6) {
      setLoading(true);
      resolvePassCode().catch((err) => console.log(err));
    }
  }, [passCode, resolvePassCode]);

  const showPhoneError = !loading && error;
  const showVerificationError = !loading && passCodeVerificationError;

  const showPhoneInput = view === 'phone' && !loading;
  const showCodeInput = view === 'code' && !loading && !showVerificationError;

  const submitButtonIsDisabled = phoneNumber?.length < 12 || (phoneNumber?.length === 13 && phoneNumber[0] === '+');

  return (
    <>
      <Header>
        {showCodeInput || (showCodeInput && showVerificationError) ? <BackButton onClickOverride={handleBackClick} /> : <div />}
        <HeaderTitle>{'Welcome'}</HeaderTitle>
        {showPhoneInput && (
          <SaveButton disabled={submitButtonIsDisabled} onClick={handlePhoneSubmit}>
            {'Continue'}
          </SaveButton>
        )}
      </Header>
      <Content padding={0} backgroundColor={offWhite}>
        <ContentContainer>
          {showPhoneInput && (
            <CenteredContainer>
              <HeaderText>{'Sign up or log in'}</HeaderText>
              <Paragraph>
                {`Your phone number is used to log in and make sure you're a real person. It won't be visible to others.`}
              </Paragraph>
              <Paragraph>{'After entering your number, we will send you a one time passcode to verify.'}</Paragraph>
              <Form onSubmit={handlePhoneSubmit} style={{ marginBottom: '16px' }}>
                <InputWrapper centered>
                  <StyledPhoneInput country="US" onChange={handlePhoneChange} placeholder={'Phone number'} value={phoneNumber} />
                </InputWrapper>
              </Form>
              <EULAParagraph>
                {'By signing up for Homepost, you agree to the terms of our '}
                <BlueLink to={'/eula'}>{'End User License Agreement'}</BlueLink>
              </EULAParagraph>
            </CenteredContainer>
          )}

          {showCodeInput && (
            <CenteredContainer>
              <HeaderText>{`Verify it's you`}</HeaderText>
              <Paragraph>{`Please enter the six digit code we sent you`}</Paragraph>
              <InputWrapper centered>
                <AndroidInput
                  autoFocus
                  inputMode={'numeric'}
                  onChange={(e) => handleCodeChange(e.currentTarget.value)}
                  placeholder={'Confirmation code'}
                />
              </InputWrapper>
            </CenteredContainer>
          )}

          {showVerificationError && !showPhoneInput && (
            <CenteredContainer>
              <Paragraph>{'Sorry, we were not able to verify your pass code.'}</Paragraph>
              <TextButton onClick={handleResendClick} style={{ color: darkBlue, textDecoration: 'underline' }}>
                {'Click here to resend'}
              </TextButton>
            </CenteredContainer>
          )}

          {loading && (
            <CenteredContainer>
              <WrapLoader>
                <RiseLoader />
              </WrapLoader>
            </CenteredContainer>
          )}

          {showPhoneError && (
            <CenteredContainer>
              <div>{`Sorry, we had trouble sending you a code to log in. Error: ${error}`}</div>
            </CenteredContainer>
          )}
        </ContentContainer>
      </Content>

      <Helmet>
        <meta charSet="utf-8" />
        <title>{'Sign Up or Log In'}</title>
      </Helmet>
    </>
  );
}

export default Login;
