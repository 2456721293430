import styled from 'styled-components';

import { black } from 'styles/colors';

export interface TextButtonProps {
  fontSize?: number;
  color?: string;
  bold?: boolean;
}

const TextButton = styled.button<TextButtonProps>`
  border: 0;
  margin: 0;
  background: transparent;
  font-size: ${(props) => props.fontSize ?? 16}px;
  font-weight: ${(props) => (props?.bold ? 600 : 400)};
  color: ${(props) => props.color ?? black};
  cursor: pointer;
`;

export default TextButton;
