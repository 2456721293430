import styled from 'styled-components';
import Modal from 'react-modal';

import BlueButton from 'styled-components/BlueButton';
import Body from 'styled-components/Body';

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Emoji = styled.div`
  font-size: 48px;
  margin-bottom: 8px;
`;

const HeaderText = styled.h2`
  margin-bottom: 24px;
`;

export type SimplePopoverProps = {
  bodyText: string;
  buttonBackgroundColor?: string;
  buttonText: string;
  contentLabel: string;
  emoji?: string;
  headerText: string;
  isOpen: boolean;
  onButtonClick: () => void;
  onRequestClose: () => void;
};

function SimplePopover({
  bodyText,
  buttonBackgroundColor,
  buttonText,
  contentLabel,
  emoji,
  headerText,
  isOpen,
  onButtonClick,
  onRequestClose,
}: SimplePopoverProps) {
  return (
    <Modal
      style={{
        overlay: { background: 'rgba(0, 0, 0, 0.3)' },
        content: {
          border: '0',
          borderRadius: '16px',
          bottom: 'auto',
          left: '50%',
          marginRight: '-50%',
          minHeight: '10rem',
          minWidth: '20rem',
          padding: '24px',
          right: 'auto',
          top: '50%',
          width: 'calc(100vw - 32px)',
        },
      }}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={contentLabel}
      closeTimeoutMS={800}
    >
      <ModalContent>
        {!!emoji && <Emoji>{emoji}</Emoji>}
        <HeaderText>{headerText}</HeaderText>
        <Body>{bodyText}</Body>
        <BlueButton onClick={onButtonClick} style={buttonBackgroundColor ? { background: buttonBackgroundColor } : {}} type={'button'}>
          {buttonText}
        </BlueButton>
      </ModalContent>
    </Modal>
  );
}

export default SimplePopover;
