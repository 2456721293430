import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Content from 'styled-components/Content';
import Header from 'styled-components/Header';
import BackButton from 'components/BackButton';
import HeaderTitle from 'styled-components/HeaderTitle';

import { useEventsCreatedByCurrentUserQuery } from 'generated';

import { darkBlue, gray } from 'styles/colors';

const Container = styled.div``;

const List = styled.ul`
  list-style: none;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${darkBlue};
`;

const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid ${gray};
`;

export type EventTitleProps = {
  unread?: boolean;
};

const EventTitle = styled.div<EventTitleProps>`
  white-space: nowrap;
  overflow: hidden;
  width: calc(100% - 16px);
  text-overflow: ellipsis;
  font-weight: ${(props) => (props?.unread ? '600' : '400')};
`;

function MyEvents() {
  const { loading, error, data } = useEventsCreatedByCurrentUserQuery({
    fetchPolicy: 'cache-and-network',
  });

  if (error) return <p>Error :(</p>;

  return (
    <Container>
      <Header>
        <BackButton />
        <HeaderTitle>{'My Events'}</HeaderTitle>
      </Header>
      <Content>
        <List>
          {(data?.eventsCreatedByCurrentUser || []).map((event) => (
            <StyledLink key={event?._id} to={`/events/${event?._id}`}>
              <ListItem>
                <EventTitle>{event?.name}</EventTitle>
              </ListItem>
            </StyledLink>
          ))}
        </List>
      </Content>
    </Container>
  );
}

export default MyEvents;
