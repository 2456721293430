import { FormEvent, ReactElement, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
import moment from 'moment-timezone';
import useCloudinaryWidget from 'components/useCloudinaryWidget';

import { CurrentUserContext } from 'App';

import {
  CreateImageInput,
  LocationInput,
  useConnectedUsersByCurrentUserQuery,
  useCreateEventMutation,
  useCreateImageMutation,
  useGroupsByCurrentUserQuery,
} from 'generated';

import Content from 'styled-components/Content';
import Form from 'styled-components/Form';
import Header from 'styled-components/Header';
import InputWrapper from 'styled-components/InputWrapper';
import PrimaryHeaderButton from 'styled-components/PrimaryHeaderButton';
import TextButton from 'styled-components/TextButton';

import track from 'services/track';
import { black, darkRed, gray, lightBlue, offWhite, white } from 'styles/colors';
import HeaderTitle from 'styled-components/HeaderTitle';
import PlacesAutocomplete from 'components/PlacesAutocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';

import convertLineBreaksToParagraphs from 'utils/convertLineBreaksToParagraphs';
import Image from 'components/Image';
import useTrackOnMount from 'components/useTrackOnMount';
import Avatar from 'components/Avatar';
import { resetGeolocation } from 'store/geolocation';
import { onLocationUpdate } from 'store/onboarding';
import SubText from 'styled-components/SubText';
import BlueButton from 'styled-components/BlueButton';
import { uniq, without } from 'lodash';
import BlueLink from 'styled-components/BlueLink';
import SingleActionButton from 'components/ActionButtons/SingleActionButton';
import FullScreenPopover from 'components/FullScreenPopover';
import StackedActionButtons from 'components/ActionButtons/StackedActionButtons';

const FeaturedImageWrapper = styled.div`
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 8px;

  img {
    width: 100%;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: space-between;

  > * {
    flex-basis: 100%;
  }

  input {
    width: 20px;
    height: 20px;
    flex-basis: auto;
  }
`;

const ActionButtonWrapper = styled.div`
  width: 100%;

  button {
    margin-top: 0 !important;
    margin-bottom: 16px !important;
    width: 100%;
  }
`;

function NewEvent(): ReactElement {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useContext(CurrentUserContext);
  const location = useSelector((state: RootState) => state.onboarding.location);

  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [start, setStart] = useState<Date>();
  const [error, setError] = useState<string>('');
  const [featuredImage, setFeaturedImage] = useState<CreateImageInput | undefined>(undefined);
  const [featuredImageId, setFeaturedImageId] = useState<string | undefined>(undefined);
  const [selectedGroupIds, setSelectedGroupIds] = useState<string[]>([]);
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const [visibleWithLink, setVisibleWithLink] = useState<boolean>(false);
  const [isInvitesModalOpen, setIsInvitesModalOpen] = useState<boolean>(false);

  const { data: connectedUsersData } = useConnectedUsersByCurrentUserQuery();
  const { data: groupsData } = useGroupsByCurrentUserQuery();

  useTrackOnMount('New Event Form Viewed', {
    days_on_platform: -moment(currentUser?.createdAt).diff(new Date(), 'days'),
  });

  const canPost = !!name && !!start && !!location;

  const [createImage] = useCreateImageMutation({
    onCompleted: (featuredImageData) => {
      setFeaturedImageId(featuredImageData?.createImage?.image?._id);
    },
    variables: {
      input: featuredImage ?? {},
    },
  });

  const [createEvent] = useCreateEventMutation({
    onCompleted: (data) => {
      if (data.createEvent.error) {
        setError(data.createEvent.error);
        track('Event Submitted', {});
      } else {
        dispatch(resetGeolocation());
        dispatch(onLocationUpdate(undefined));
        navigate('/events');
      }
    },
    variables: {
      input: {
        description: convertLineBreaksToParagraphs(description),
        featuredImageId,
        invitedUserIds: selectedUserIds,
        invitedGroupIds: selectedGroupIds,
        location: location as unknown as LocationInput,
        name,
        start,
        timezone: moment.tz.guess(),
        visibleWithLink,
      },
    },
  });

  const handleCreateEvent = (): void => {
    createEvent().catch((err) => console.log(err));
  };

  const handlePostClick = (): void => {
    handleCreateEvent();
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
  };

  // When the cloudinary widget changes, call the createImage mutation
  useEffect(() => {
    if (featuredImage?.cloudinaryId) {
      createImage().catch((err) => console.log(err));
    }
  }, [featuredImage, createImage]);

  const { openCloudinaryWidget } = useCloudinaryWidget({
    cropToWide: true,
    folder: `uploads/${currentUser?._id ?? 'unknown'}`,
    onChange: (value: any) => setFeaturedImage(value),
  });

  if (!currentUser) {
    return <div>{'You must be logged in to create a new event.'}</div>;
  }

  if (error) {
    return <div>{`Sorry, we had trouble creating this post. Error: ${error}`}</div>;
  }

  const handleCancelButtonClick = (): void => {
    navigate(-1);
  };

  const clearFeaturedImage = (): void => {
    setFeaturedImage(() => undefined);
    setFeaturedImageId(() => undefined);
  };

  const groups = groupsData?.groupsByCurrentUser || [];
  const connectedUsers = connectedUsersData?.connectedUsersByCurrentUser || [];

  const invitedGroups = groups.filter((group) => selectedGroupIds.includes(group._id));
  const invitedGroupUserIds = invitedGroups
    .map((group) => group.members || [])
    .reduce((acc, cur) => acc.concat(cur), [])
    .map((member) => member._id);
  const inviteCount = uniq([...invitedGroupUserIds, ...selectedUserIds]).length;

  const handleGroupToggle = (groupId: string) => {
    const group = groups.find((group) => group._id === groupId);
    const members = group?.members || [];
    const memberUserIds = members.map((member) => member._id);

    if (selectedGroupIds.includes(groupId)) {
      setSelectedGroupIds(selectedGroupIds.filter((selectedGroupId) => selectedGroupId !== groupId));
      setSelectedUserIds(without(selectedUserIds, ...memberUserIds));
    } else {
      setSelectedGroupIds([...selectedGroupIds, groupId]);
      setSelectedUserIds(uniq([...selectedUserIds, ...memberUserIds]));
    }
  };

  const handleUserToggle = (userId: string) => {
    if (selectedUserIds.includes(userId)) {
      setSelectedUserIds(selectedUserIds.filter((selectedUserId) => selectedUserId !== userId));
    } else {
      setSelectedUserIds([...selectedUserIds, userId]);
    }
  };

  const modalContent = (
    <>
      <StackedActionButtons
        actionButtons={groups.map((group) => ({
          text: `${group.name} - ${group?.members?.length} member(s)`,
          onToggle: () => handleGroupToggle(group._id),
          toggleValue: selectedGroupIds.includes(group._id),
        }))}
        title={'Groups'}
      />
      <StackedActionButtons
        actionButtons={connectedUsers.map((connectedUser) => ({
          text: `${connectedUser.firstName}`,
          onToggle: () => handleUserToggle(connectedUser._id),
          toggleValue: selectedUserIds.includes(connectedUser._id),
        }))}
        title={'Friends'}
      />
      <div style={{ height: '16px' }} />
      <BlueButton onClick={() => setIsInvitesModalOpen(false)}>{'Save and close'}</BlueButton>
    </>
  );

  return (
    <>
      <Header>
        <TextButton color={darkRed} onClick={handleCancelButtonClick}>
          {'Cancel'}
        </TextButton>
        <HeaderTitle>{'Create Event'}</HeaderTitle>
        <PrimaryHeaderButton disabled={!canPost} onClick={handlePostClick}>
          {'Post'}
        </PrimaryHeaderButton>
      </Header>
      <Content padding={16} backgroundColor={offWhite}>
        <Form onSubmit={handleSubmit} autoComplete={'off'}>
          <InputWrapper>
            <label htmlFor={'name'}>{'Name'}</label>
            <input
              autoFocus
              id={'name'}
              type={'text'}
              onChange={(e) => setName(e.currentTarget.value)}
              defaultValue={name}
              placeholder={'Add event name'}
            />
          </InputWrapper>
          <InputWrapper>
            <label htmlFor={'description'}>{'Description'}</label>
            <TextareaAutosize
              id={'description'}
              onChange={(e) => setDescription(e.currentTarget.value)}
              defaultValue={description}
              placeholder={'Add a description'}
            />
          </InputWrapper>
          <InputWrapper>
            <label htmlFor={'autocomplete'}>{'Location'}</label>
            <PlacesAutocomplete defaultValue={location?.formattedAddress} placeholder={'Add location'} />
          </InputWrapper>
          <InputWrapper>
            <label htmlFor={'date'}>{'Date & Time'}</label>
            <input
              id={'date-text'}
              value={start?.toLocaleString()}
              placeholder={'Add a date and time'}
              style={{ zIndex: '3' }}
              type={'text'}
            />
            <input
              id={'date'}
              onChange={(e) => setStart(new Date(e.currentTarget.value))}
              defaultValue={start?.toLocaleString()}
              placeholder={'Add a date and time'}
              style={{ position: 'absolute', top: '36px', zIndex: '4', opacity: '0' }}
              type={'datetime-local'}
              step={900}
            />
          </InputWrapper>
          <InputWrapper>
            <CheckboxWrapper>
              <label htmlFor={'visibleWithLink'}>{'Public link'}</label>
              <input
                id={'visibleWithLink'}
                checked={visibleWithLink}
                type={'checkbox'}
                onChange={(e) => setVisibleWithLink(e.currentTarget.checked)}
              />
            </CheckboxWrapper>
            <SubText style={{ textAlign: 'left' }}>{'Public links allow anyone to share, view, and RSVP for this event'}</SubText>
          </InputWrapper>
          <InputWrapper>
            <label>{'Invitations'}</label>
            <ActionButtonWrapper>
              <SingleActionButton
                onClick={() => setIsInvitesModalOpen(true)}
                text={inviteCount ? `${inviteCount} invitees` : 'Invite your friends'}
                showCaret
              />
            </ActionButtonWrapper>
          </InputWrapper>
          <InputWrapper>
            <label htmlFor={'featuredImage'}>{'Featured Image'}</label>
            {!featuredImage && (
              <TextButton onClick={() => openCloudinaryWidget()} style={{ color: lightBlue }}>
                {'Add an image'}
              </TextButton>
            )}
            {!!featuredImage && featuredImage.path && (
              <>
                <FeaturedImageWrapper>
                  <Image path={featuredImage.path} />
                </FeaturedImageWrapper>
                <TextButton style={{ color: darkRed }} onClick={clearFeaturedImage}>
                  {'Remove'}
                </TextButton>
              </>
            )}
          </InputWrapper>
        </Form>

        <FullScreenPopover
          contentLabel={'Choose invites'}
          content={modalContent}
          isOpen={isInvitesModalOpen}
          onRequestClose={() => setIsInvitesModalOpen(false)}
        />
      </Content>
    </>
  );
}

export default NewEvent;
