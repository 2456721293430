import { useEffect, useState } from 'react';
import track from 'services/track';

function useTrackOnData(eventName: string, eventProperties: any, data: any) {
  const [didTrack, setDidTrack] = useState<boolean>(false);

  useEffect(() => {
    if (!didTrack && !!data) {
      track(eventName, eventProperties);
      setDidTrack(() => true);
    }
  }, [data, didTrack, eventName, eventProperties]);
}

export default useTrackOnData;
