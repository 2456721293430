import styled from 'styled-components';
import { usePlatformInvitationQuery } from 'generated';
import { ReactElement } from 'react';
import { useParams, Link } from 'react-router-dom';
import Content from 'styled-components/Content';
import { white, black, darkBlue } from 'styles/colors';
import Image from 'components/Image';
import Body from 'styled-components/Body';

const StackWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Stack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  text-align: center;
`;

const ButtonWrapper = styled(Link)`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  text-align: center;

  a {
    margin-top: 12px;
  }
`;

const H1Text = styled.h1`
  margin-top: 24px;
  color: ${black};
  margin-bottom: 16px;
`;

const Button = styled.button`
  background: ${darkBlue};
  border: none;
  border-radius: 5px;
  padding: 12px 40px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */

  text-align: center;

  color: ${white};
  width: auto;
  cursor: pointer;
`;

const ImageWrapper = styled.div`
  margin-top: 80px;
  width: 222px;
  height: 222px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }
`;

function PlatformInvitation(): ReactElement {
  const { platformInvitationId = '' } = useParams();

  const { loading, error, data } = usePlatformInvitationQuery({
    variables: {
      input: {
        platformInvitationId,
      },
    },
  });

  if (loading || error || !data?.platformInvitation) {
    return <div />;
  }

  const inviterFirstName = data?.platformInvitation?.createdBy?.firstName || '';
  const inviterProfileImagePath = data?.platformInvitation?.createdBy?.profileImage?.path || '';

  return (
    <Content backgroundColor={white}>
      <StackWrapper>
        <Stack>
          <Image path={'/logos/home_post_logo_dark'} />
          <ImageWrapper>
            <Image path={inviterProfileImagePath} />
          </ImageWrapper>
          <H1Text>{`${inviterFirstName} wants to connect`}</H1Text>
          <Body>{'Spend more time doing the things you like with people you like, in real life.'}</Body>
          <ButtonWrapper to={`/login/${platformInvitationId}`}>
            <Button>{'Sign Up or Log In'}</Button>
          </ButtonWrapper>
        </Stack>
      </StackWrapper>
    </Content>
  );
}

export default PlatformInvitation;
