import Image from 'components/Image';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Content from 'styled-components/Content';
import Header from 'styled-components/Header';

const Text = styled.div`
  padding: 16px;

  * {
    margin-bottom: 16px;
  }
`;

function Privacy(): ReactElement {
  return (
    <>
      <Header>
        <div />
        <Link style={{ lineHeight: 0 }} to={'/'}>
          <Image path={'/logos/home_post_logo_dark'} />
        </Link>
        <div />
      </Header>
      <Content>
        <Text>
          <h2>{'Privacy Policy'}</h2>
          <p>{'Last updated: October 29, 2023'}</p>

          <p>
            {
              'This Privacy Policy describes how HomePost ("us", "we", or "our") collects, uses, and shares information in relation to our mobile application (the "App"). This Privacy Policy applies to all users who access or use our App.'
            }
          </p>

          <p>{'Information We Collect'}</p>

          <p>
            {
              'We collect information you provide directly to us. For example, we collect information when you create an account, post events, RSVP to events, comment on events, message other users through the App, or otherwise communicate with us through the App. The types of information we may collect include your name, email address, phone number, location, photos, comments, messages, and any other information you choose to provide.'
            }
          </p>

          <p>
            {
              'We also automatically collect certain types of information when you use our App, like device identifiers, device type, geo-location information, IP address, log files, and usage data. This information allows us to provide and improve the App.'
            }
          </p>

          <p>{'Use of Information'}</p>

          <p>
            {'We use the information we collect through the App to:'}
            {'Provide, maintain, and improve the App'}
            {'Notify you about changes to the App'}
            {'Allow you to post events, comment, RSVP, and communicate with other users'}
            {'Analyze usage of the App'}
          </p>

          <p>
            {
              'We may also use the information we obtain through the App for our marketing purposes - to provide you with information about services we believe may interest you. You can opt-out of receiving marketing communications from us by following the unsubscribe instructions in any marketing email we send you.'
            }
          </p>

          <p>{'Sharing of Information'}</p>

          <p>
            {
              'We will not share any personally identifying information that we collect through the App (like your name, email address etc.) with third parties without your consent, except as necessary to provide and improve our App. This includes sharing information with third party service providers, consultants and other persons who help us provide, analyze and improve the App.'
            }
            {
              'We may disclose your information if we believe that disclosure is reasonably necessary to comply with any applicable law, regulation, legal process or governmental request. We also reserve the right to disclose your information as necessary to enforce our Terms of Service, take precautions against liability or investigate suspicious activity.'
            }
          </p>

          <p>{'Security'}</p>

          <p>
            {
              'We take reasonable measures to help protect your personal information in an effort to prevent loss, misuse, unauthorized access, disclosure, alteration and destruction. However, no internet-based site can be 100% secure so we cannot guarantee the absolute security of your information.'
            }
          </p>

          <p>{'Changes to this Privacy Policy'}</p>

          <p>
            {
              'We may occasionally update this Privacy Policy by posting a new version on this page. Please review this page periodically for any changes. Continued use of the App after any changes to this Privacy Policy will constitute your consent to such changes.'
            }
          </p>

          <p>{'Contact Us'}</p>

          <p>{'If you have any questions about this Privacy Policy, please contact us at: hi@homepostapp.com.'}</p>
        </Text>
      </Content>
    </>
  );
}

export default Privacy;
