import { ReactElement } from 'react';
import styled from 'styled-components';
import { Contacts } from '@capacitor-community/contacts';

import BlueButton from 'styled-components/BlueButton';
import Body from 'styled-components/Body';

import Content from 'styled-components/Content';
import Header from 'styled-components/Header';
import HeaderTitle from 'styled-components/HeaderTitle';
import SaveButton from 'styled-components/SaveButton';

import { offWhite } from 'styles/colors';
import { usePotentialConnectionsByContactsListLazyQuery } from 'generated';
import { useNavigate } from 'react-router-dom';

const ContentContainer = styled.div`
  padding: 16px;
`;

const CenteredContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 24px 0;

  @media screen and (min-width: 768px) {
    max-width: calc(100% - 96px);
    margin: 24px auto;
  }
`;

const HeaderText = styled.h2`
  margin-bottom: 16px;
`;

function ExternalContacts(): ReactElement {
  const navigate = useNavigate();

  const [fetchContacts, { error, data, loading }] = usePotentialConnectionsByContactsListLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const handleContactsClick = async (): Promise<void> => {
    const projection = {
      // Specify which fields should be retrieved.
      name: true,
      phones: true,
    };

    // const result = await Contacts.getContacts({
    //   projection,
    // });

    const result = {
      contacts: [
        {
          contactId: 'xy',
          phones: [{ type: 'mobile', number: '(555) 555-0001' }],
        },
      ],
    };

    console.log('result', result);

    const phoneNumbers = result.contacts
      .map((contact) => contact.phones || [])
      .reduce((acc, cur) => acc.concat(cur), [])
      .map((phone) => phone.number || '')
      .filter((phoneNumber) => !!phoneNumber);

    console.log('phoneNumbers', phoneNumbers);

    fetchContacts({
      variables: {
        input: {
          phoneNumbers: phoneNumbers,
        },
      },
    }).catch((err: any) => {
      console.log(err);
    });
  };

  const handleSkipClick = (): void => {
    navigate('/events');
  };

  const contacts = data?.potentialConnectionsByContactsList || [];
  const showContacts = !loading && !error && !!contacts?.length;

  return (
    <>
      <Header>
        <div />
        <HeaderTitle>{'Connect with Contacts'}</HeaderTitle>
        <SaveButton disabled={false} onClick={handleSkipClick}>
          {'Skip'}
        </SaveButton>
      </Header>
      <Content padding={0} backgroundColor={offWhite}>
        {!showContacts && (
          <ContentContainer>
            <CenteredContainer>
              <HeaderText>{'Homepost works best with your friends'}</HeaderText>
              <Body>{'Share your contacts and we will find your friends who are already using Homepost'}</Body>
              <BlueButton onClick={handleContactsClick}>{'Share contacts'}</BlueButton>
            </CenteredContainer>
          </ContentContainer>
        )}

        {showContacts && <CenteredContainer>{'Contacts'}</CenteredContainer>}
      </Content>
    </>
  );
}

export default ExternalContacts;
