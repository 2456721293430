import { useCreatePlatformInvitationMutation } from "generated";

const { REACT_APP_CLIENT_URL = '' } = process.env;

function useHandleInviteClick(): Array<() => Promise<void>> {
  const [createPlatformInvitation] = useCreatePlatformInvitationMutation({
    onCompleted: (data) => {
      if (data.createPlatformInvitation.error) {
        console.log(`Error: ${data.createPlatformInvitation.error}`);
      } else if (navigator.share) {
        navigator
          .share({
            title: 'Homepost invite',
            text: 'Join me on Homepost',
            url: `${REACT_APP_CLIENT_URL}/platform-invitations/${data.createPlatformInvitation.platformInvitation?._id ?? ''}`,
          })
          .then(() => console.log('Successful share'))
          .catch((error) => console.log('Error sharing', error));
      } else {
        // Show the url? Copy and paste it?
        console.log(
          'invite',
          `${REACT_APP_CLIENT_URL}/platform-invitations/${data.createPlatformInvitation.platformInvitation?._id ?? ''}`,
        );
      }
    },
  });

  const handleInviteClick = async (): Promise<void> => {
    createPlatformInvitation().catch((error) => console.log('Error sharing', error));
  };

  return [handleInviteClick];
}

export default useHandleInviteClick;
