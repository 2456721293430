import { ReactElement, useContext, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import Avatar from 'components/Avatar';
import BackButton from 'components/BackButton';
import Content from 'styled-components/Content';
import Header from 'styled-components/Header';
import HeaderTitle from 'styled-components/HeaderTitle';
import Paragraph from 'styled-components/Paragraph';

import { black, darkGray, gray, offWhite } from 'styles/colors';

import { CurrentUserContext } from 'App';
import { useNotificationsByCurrentUserQuery, CurrentUser, useUpdateUserMutation } from 'generated';

const List = styled.ul`
  list-style: none;

  a {
    text-decoration: none;
  }
`;

const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid ${gray};
`;

const PostContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  color: ${black};
  margin-left: 8px;
  width: calc(100% - 72px);
  overflow: hidden;
`;

export interface PostTitleProps {
  unread?: boolean;
}

const PostTitle = styled.div<PostTitleProps>`
  width: calc(100% - 16px);
  font-weight: ${(props) => (props?.unread ? '600' : '400')};
  transition: font-weight 3s ease-in-out;
`;

const PostMetadata = styled.div`
  height: 40px;
`;

const PostedAt = styled.div`
  color: #798389;
`;

const NotificationsEmptyState = styled.div`
  padding: 32px 16px;
  text-align: center;
`;

function Notifications(): ReactElement {
  const currentUser = useContext<CurrentUser | null>(CurrentUserContext);
  const currentUserLastViewedAtRef = useRef(currentUser?.lastVisitedNotificationsAt);

  const { error, data, loading } = useNotificationsByCurrentUserQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [updateUser] = useUpdateUserMutation({
    refetchQueries: ['CurrentUser'],
    variables: {
      input: {
        lastVisitedNotificationsAt: new Date(),
      },
    },
  });

  useEffect(() => {
    updateUser().catch((err) => console.log(err.toString()));
  }, []);

  if (error) return <p>Error :(</p>;

  const notifications = data?.notificationsByCurrentUser ?? [];
  const showEmptyState = !loading && !notifications?.length;

  return (
    <>
      <Header>
        <HeaderTitle>{'Inbox'}</HeaderTitle>
      </Header>
      <Content backgroundColor={offWhite}>
        {showEmptyState && (
          <NotificationsEmptyState>
            <Paragraph color={darkGray}>{'You have no new notifications.'}</Paragraph>
          </NotificationsEmptyState>
        )}
        <List>
          {notifications.map((notification) => (
            <ListItem key={notification?._id}>
              <Avatar
                path={notification?.connectedUser?.profileImage?.path}
                size={40}
                text={notification?.connectedUser?.firstName?.slice(0, 1)}
              />
              <PostContent>
                <PostTitle
                  unread={
                    !currentUserLastViewedAtRef.current
                      ? true
                      : new Date(currentUserLastViewedAtRef.current) < new Date(notification?.createdAt)
                  }
                >
                  {notification?.type === 'newGroupMember' &&
                    `${notification?.connectedUser?.firstName} has just joined ${notification?.group?.name || 'a new group'}`}
                  {notification?.type === 'newGroupInvitation' && `You have been invited to ${notification?.group?.name || 'a new group'}`}
                  {notification?.type === 'newConnection' && `You are now connected to ${notification?.connectedUser?.firstName ?? ''}!`}
                  {notification?.type === 'newConnectionRequest' &&
                    `${notification?.connectedUser?.firstName ?? ''} wants to connect with you!`}
                </PostTitle>
                {['newConnection', 'newConnectionRequest', 'newGroupMember'].includes(notification?.type || '') && (
                  <Link to={`/neighbors/${notification?.connectedUser?._id ?? ''}`}>{'View their profile'}</Link>
                )}
                {notification?.type === 'newGroupInvitation' && (
                  <Link to={`/group-invitations/${notification?.group?._id || ''}`}>{'View the invitation'}</Link>
                )}
              </PostContent>
              <PostMetadata>
                <PostedAt>{moment(notification?.createdAt).fromNow()}</PostedAt>
              </PostMetadata>
            </ListItem>
          ))}
        </List>
      </Content>
    </>
  );
}

export default Notifications;
