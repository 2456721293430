import styled from 'styled-components';
import { useGroupQuery, useJoinGroupMutation } from 'generated';
import { ReactElement, useContext } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Content from 'styled-components/Content';
import { white, black, darkBlue, lightBlue } from 'styles/colors';
import Image from 'components/Image';
import Body from 'styled-components/Body';
import { CurrentUserContext } from 'App';
import BlueButton from 'styled-components/BlueButton';
import { toast } from 'react-toastify';

const StackWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Stack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  text-align: center;
`;

const ButtonWrapper = styled(Link)`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  text-align: center;

  a {
    margin-top: 12px;
  }
`;

const H1Text = styled.h1`
  margin-top: 24px;
  color: ${black};
  margin-bottom: 16px;
`;

const Button = styled.button`
  background: ${darkBlue};
  border: none;
  border-radius: 5px;
  padding: 12px 40px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */

  text-align: center;

  color: ${white};
  width: auto;
  cursor: pointer;
`;

const ImageWrapper = styled.div`
  margin-top: 80px;
  width: 222px;
  height: 222px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }
`;

function GroupInvitation(): ReactElement {
  const { groupId = '' } = useParams();
  const navigate = useNavigate();
  const currentUser = useContext(CurrentUserContext);

  const [joinGroup] = useJoinGroupMutation({
    onCompleted: (data) => {
      if (data.joinGroup?.success) {
        toast.success(`You have successfully joined the ${data.joinGroup?.group?.name} group`);
        navigate('/events');
      }
    },
    refetchQueries: () => ['CurrentUser'],
    variables: {
      input: {
        groupId,
      },
    },
  });

  const { loading, error, data } = useGroupQuery({
    variables: {
      input: {
        groupId,
      },
    },
  });

  if (loading || error || !data?.group) {
    return <div />;
  }

  const inviterFirstName = data?.group?.createdBy?.firstName || '';
  const inviterProfileImagePath = data?.group?.createdBy?.profileImage?.path || '';
  const groupName = data?.group?.name || '';

  const showLogin = !currentUser;
  const showAccept = currentUser?._id;

  const handleAcceptClick = (): void => {
    joinGroup();
  };

  return (
    <Content backgroundColor={white} style={{ height: '100%' }}>
      <StackWrapper>
        <Stack>
          <Image path={'/logos/home_post_logo_dark'} />
          <ImageWrapper>
            <Image path={inviterProfileImagePath} />
          </ImageWrapper>
          <H1Text>
            {`${inviterFirstName} has invited you to the group `}
            <span style={{ color: lightBlue }}>{groupName}</span>
          </H1Text>
          <Body>{'Spend more time doing the things you like with people you like, in real life.'}</Body>
          {showLogin && (
            <ButtonWrapper to={`/login/${groupId}`}>
              <Button>{'Log In to Accept Invite'}</Button>
            </ButtonWrapper>
          )}
          {showAccept && <BlueButton onClick={handleAcceptClick}>{'Accept Invite'}</BlueButton>}
        </Stack>
      </StackWrapper>
    </Content>
  );
}

export default GroupInvitation;
