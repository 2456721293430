import { useEffect, ReactNode } from 'react';
import { useLocation } from 'react-router';

export type ScrollToTopProps = {
  children?: ReactNode;
};

function ScrollToTop({ children }: ScrollToTopProps) {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{children}</>;
}

export default ScrollToTop;
