import styled from 'styled-components';
import { ReactElement, useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';
// import styled from 'styled-components';

import { useGroupQuery, useGroupsByCurrentUserQuery } from 'generated';

import Content from 'styled-components/Content';
import Header from 'styled-components/Header';
import BackButton from 'components/BackButton';

import useTrackOnData from 'components/useTrackOnData';
import HeaderTitle from 'styled-components/HeaderTitle';

import { black, gray, offWhite, white } from 'styles/colors';
import useShowLoading from 'components/useShowLoading';
import { CurrentUserContext } from 'App';
import Avatar from 'components/Avatar';
import BlueLink from 'styled-components/BlueLink';
import useHandleGroupInviteClick from 'components/useHandleGroupInviteClick';
import IconWrapper from 'styled-components/IconWrapper';

const List = styled.ul`
  list-style: none;
`;

const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid ${gray};
  background: ${white};
`;

const UserContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  color: ${black};
  margin-left: 8px;
  width: calc(100% - 72px);
  overflow: hidden;
`;

const UserName = styled.div``;

function Group(): ReactElement {
  const currentUser = useContext(CurrentUserContext);

  const params = useParams();
  const { groupId = '' } = params;

  // Fetch privileged groups
  const { loading, error, data } = useGroupsByCurrentUserQuery({
    fetchPolicy: 'cache-and-network',
  });

  const group = (data?.groupsByCurrentUser || []).find((group) => group?._id === groupId);

  useTrackOnData('Group Viewed', {}, {});

  useShowLoading(loading);

  const [handleGroupInviteClick] = useHandleGroupInviteClick({ groupId });

  if (error || !group) return <p>Error :(</p>;

  return (
    <>
      <Header>
        <BackButton />
        <HeaderTitle>{group.name}</HeaderTitle>
        <IconWrapper color={black} size={24} onClick={handleGroupInviteClick}>
          <span className={'material-symbols-rounded'}>{'person_add'}</span>
        </IconWrapper>
      </Header>
      <Content backgroundColor={offWhite}>
        <List>
          {(group.members || []).map((user) => (
            <ListItem key={user?._id}>
              <Avatar path={user?.profileImage?.path} size={40} text={user?.firstName?.slice(0, 1)} />
              <UserContent>
                <UserName>{user?.firstName}</UserName>
                {user._id === currentUser?._id && <BlueLink to={`/profile`}>{'View My Profile'}</BlueLink>}
                {user._id !== currentUser?._id && <BlueLink to={`/friends/${user?._id}`}>{'View Profile'}</BlueLink>}
              </UserContent>
            </ListItem>
          ))}
        </List>
      </Content>
    </>
  );
}

export default Group;
