import { ReactElement, useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';
// import styled from 'styled-components';

import { CurrentUserContext } from 'App';
import { CurrentUser, useUserQuery } from 'generated';

import Content from 'styled-components/Content';
import Header from 'styled-components/Header';
import BackButton from 'components/BackButton';
import BlockButton from 'components/BlockButton';

import useTrackOnData from 'components/useTrackOnData';
import HeaderTitle from 'styled-components/HeaderTitle';

import UserContent from './UserContent';
import { offWhite } from 'styles/colors';

interface LocationState {
  from?: string;
}

function getPreviousScreenFromLocation(locationState: LocationState): string | null {
  if (!locationState?.from) return null;

  return locationState?.from;
}

function User(): ReactElement {
  const locationState = useLocation().state as LocationState;

  const params = useParams();
  const { userId = '' } = params;

  const currentUser = useContext<CurrentUser | null>(CurrentUserContext);

  const { loading, error, data } = useUserQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        userId,
      },
    },
  });

  useTrackOnData(
    'Neighbor Profile Viewed',
    {
      previous_screen: getPreviousScreenFromLocation(locationState),
    },
    data,
  );

  const user = data?.user;

  if (loading) return <div />;

  if (!user || error) return <p>Error :(</p>;

  const isOwnProfile = currentUser?._id === user._id;

  return (
    <>
      <Header>
        <BackButton />
        {!!user._id && !isOwnProfile && <BlockButton userId={user._id} />}
        <HeaderTitle>{'View Profile'}</HeaderTitle>
      </Header>
      <Content backgroundColor={offWhite}>
        <UserContent isOwnProfile={isOwnProfile} user={user} />
      </Content>
    </>
  );
}

export default User;
